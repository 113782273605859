//For Authentication api's

//  For LOADER
export const LOADER = "LOADER";

//for signup
export const SIGN_UP_INIT = "SIGN_UP_INIT";
export const SIGN_UP_SUCCESS = "SIGN_UP_SUCCESS";
export const SIGN_UP_FAIL = "SIGN_UP_FAIL";

//for login
export const SIGN_IN_INIT = "SIGN_IN_INIT";
export const SIGN_IN_SUCCESS = "SIGN_IN_SUCCESS";
export const SIGN_IN_FAIL = "SIGN_IN_FAIL";

//for forgot password
export const FORGOT_PASSWORD_INIT = "FORGOTPASSWORD_IN_INIT";
export const FORGOT_PASSWORD_SUCCESS = "FORGOTPASSWORD_IN_SUCCESS";
export const FORGOT_PASSWORD_FAIL = "FORGOTPASSWORD_IN_FAIL";

//for opt verification
export const VERIFY_OPT_INIT = "VERIFY_IN_INIT";
export const VERIFY_OPT_SUCCESS = "VERIFY_OPT_SUCCESS";
export const VERIFY_OPT_FAIL = "VERIFY_OPT_FAIL";

export const VERIFY_OTPSIGNUP_SUCCESS = "VERIFY_OTPSIGNUP_SUCCESS";
export const VERIFY_OTPSIGNUP_FAIL = "VERIFY_OTPSIGNUP_FAIL";

export const RESEND_OTP_INIT = "RESEND_OTP_INIT";
export const RESEND_OTP_SUCCESS = "RESEND_OTP_SUCCESS";
export const RESEND_OTP_FAIL = "RESEND_OTP_FAIL";

export const RESEND_OTP_FORGOTPASSWORD_SUCCESS =
  "RESEND_OTP_FORGOTPASSWORD_SUCCESS";
export const RESEND_OTP_FORGOTPASSWORD_FAIL = "RESEND_OTP_FORGOTPASSWORD_FAIL";

// for change password
export const CHANGE_PASSWORD_INIT = "CHANGE_PASSWORD_INIT";
export const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
export const CHANGE_PASSWORD_FAIL = "CHANGE_PASSWORD_FAIL";

//for signout
export const SIGN_OUT = "SIGN_OUT";

//Refresh token
export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS";
export const REFRESH_TOKEN_FAIL = "REFRESH_TOKEN_FAIL";

//Clear State Back Button
export const CLEAR_STATE_BACK = "CLEAR_STATE_BACK";

//TodoList
export const SHOW = "SHOW";
export const SET_LOADER_FALSE = "SET_LOADER_FALSE";
export const GET_TODOLIST_LOADER_START = "GET_TODOLIST_LOADER_START";
export const GET_TODO_FAIL = "GET_TODO_FAIL";
export const GET_MAIN_LOADER_START = "GET_MAIN_LOADER_START";
export const GET_MAIN_LOADER_FAIL = "GET_MAIN_LOADER_FAIL";

//Upload
export const GET_UPLOAD_FAIL = "GET_UPLOAD_FAIL";
export const GET_UPLOAD_LOADER_START = "GET_UPLOAD_LOADER_START";
export const SET_LOADER_FALSE_UPLOAD = "SET_LOADER_FALSE_UPLOAD";
export const UPLOAD_DOCUMNET_FILE_SUCCESS = "UPLOAD_DOCUMNET_FILE_SUCCESS";
export const UPLOAD_DOCUMNET_FILE_FAIL = "UPLOAD_DOCUMNET_FILE_FAIL";
export const GET_ALL_ASSIGNEES_SUCCESS = "GET_ALL_ASSIGNEES_SUCCESS";
export const GET_ALL_ASSIGNEES_FAIL = "GET_ALL_ASSIGNEES_FAIL";

// ali
//for Gell All Settings
export const GETSETTING_INIT = "SETTINGS_INIT";
export const GETSETTING_SUCCESS = "SETTING_SUCCESS";
export const GETSETTING_FAIL = "SETTING_FAIL";

//FOR UPDATE USER PROFILE
export const UPDATEUSERPROFILE_INIT = "UPDATEUSERPROFILE_INIT";
export const UPDATEUSERPROFILE_SUCCESS = "UPDATEUSERPROFILE_SUCCESS";
export const UPDATEUSERPROFILE_FAIL = "UPDATEUSERPROFILE_FAIL";

// FOR UPDATE USER NOTIFCATION
export const UPDATEUSERNOTIFICATION_INIT = "UPDATEUSERNOTIFICATION_INIT";
export const UPDATEUSERNOTIFICATION_SUCCESS = "UPDATEUSERNOTIFICATION_SUCCESS";
export const UPDATEUSERNOTIFICATION_FAIL = "UPDATEUSERNOTIFICATION_FAIL";

//FOR UPDATE USER GENERAL SETTING
export const UPDATEUSERGENERALSETTING_INIT = "UPDATEUSERGENERALSETTING_INIT";
export const UPDATEUSERGENERALSETTING_SUCCESS =
  "UPDATEUSERGENERALSETTING_SUCCESS";
export const UPDATEUSERGENERALSETTING_FAIL = "UPDATEUSERGENERALSETTING_FAIL";

//FOR GETTING USER NOTIFICATION
export const GETUSERNOTIFICATION_INIT = "GETUSERNOTIFICATION_INIT";
export const GETUSERNOTIFICATION_SUCCESS = "GETUSERNOTIFICATION_SUCCESS";
export const GETUSERNOTIFICATION_FAIL = "GETUSERNOTIFICATION_FAIL";
// aun
//For FAQ's
export const GET_FAQS_INIT = "GET_FAQS_INIT";
export const GET_FAQS_SUCCESS = "GET_FAQS_SUCCESS";
export const GET_FAQS_FAIL = "GET_FAQS_FAIL";

// For COUNTRY CODES
export const GET_COUNTRYCODE_INIT = "GET_COUNTRYCODE_INIT";
export const GET_COUNTRYCODE_SUCCESS = "GET_COUNTRYCODE_SUCCESS";
export const GET_COUNTRYCODE_FAIL = "GET_COUNTRYCODE_FAIL";

// FOR TIME ZONES
export const GET_TIMEZONE_INIT = "GET_TIMEZONE_INIT";
export const GET_TIMEZONE_SUCCESS = "GET_TIMEZONE_SUCCESS";
export const GET_TIMEZONE_FAIL = "GET_TIMEZONE_FAIL";

//aun
//For GetMeetingByUserID
export const GET_MEETINGUSERID_INIT = "GET_MEETINGUSERID_INIT";
export const GET_MEETINGUSERID_SUCCESS = "GET_MEETINGUSERID_SUCCESS";
export const GET_MEETINGUSERID_FAIL = "GET_MEETINGUSERID_FAIL";
export const SEARCH_MEETING_RESPONSE = "SEARCH_MEETING_RESPONSE";

//aun
//Get todolist User id
export const GET_TODOLIST_INIT = " GET_TODOLIST_INIT";
export const GET_TODOLIST_SUCCESS = " GET_TODOLIST_SUCCESS";
export const GET_TODOLIST_FAIL = " GET_TODOLIST_FAIL";

// FOR ASSIGNEES LIST
export const ASSIGNESS_LIST_INIT = "ASSIGNESS_LIST_INIT";
export const ASSIGNESS_LIST_SUCCESS = "ASSIGNESS_LIST_SUCCESS";
export const ASSIGNESS_LIST_FAIL = "ASSIGNESS_LIST_FAIL";

//SCHEDULE MEETING
export const SCHEDULE_NEW_MEETING_INIT = "SCHEDULE_NEW_MEETING_INIT";
export const SCHEDULE_NEW_MEETING_FAIL = "SCHEDULE_NEW_MEETING_FAIL";

//VIEW MEETING
export const VIEW_MEETING_INIT = "VIEW_MEETING_INIT";
export const VIEW_MEETING_SUCESS = "VIEW_MEETING_SUCESS";
export const VIEW_MEETING_FAIL = "VIEW_MEETING_FAIL";

// CLEARE STATE OF ASSIGNEES LIST STATES
export const CLEARE_STATE = "CLEARE_STATE";
export const VIEW_TODO_SUCCESS = "VIEW_TODO_SUCCESS";
export const VIEW_TODO_FAIL = "VIEW_TODO_FAIL";

export const CLEAR_STATE = "CLEAR_STATE";
export const CLEAR_RESPONSE_MESSAGE = "CLEAR_RESPONSE_MESSAGE";

export const CANCEL_MEETING_INIT = "CANCEL_MEETING_INIT";
export const CANCEL_MEETING_SUCCESS = "CANCEL_MEETING_SUCCESS";
export const CANCEL_MEETING_FAIL = "CANCEL_MEETING_FAIL";

export const START_MEETING_INIT = "START_MEETING_INIT";
export const START_MEETING_SUCCESS = "START_MEETING_SUCCESS";
export const START_MEETING_FAIL = "START_MEETING_FAIL";

export const END_MEETING_INIT = "END_MEETING_INIT";
export const END_MEETING_SUCCESS = "END_MEETING_SUCCESS";
export const END_MEETING_FAIL = "END_MEETING_FAIL";

// GET CALENDAR DATA
export const GET_DATA_FOR_CALENDAR_INIT = "GET_DATA_FOR_CALENDAR_INIT";
export const GET_DATA_FOR_CALENDAR_SUCCESS = "GET_DATA_FOR_CALENDAR_SUCCESS";
export const GET_DATA_FOR_CALENDAR_FAIL = "GET_DATA_FOR_CALENDAR_FAIL";

// MAINTAIN STATES FOR ONBOARDS

export const SHOW_MODAL_STATE = "SHOW_MODAL_STATE";
export const ISDETAIL_MODAL_STATE = "ISDETAIL_MODAL_STATE";
export const ISAGENDA_MODAL_STATE = "ISAGENDA_MODAL_STATE";
export const ISATTENDEES_MODAL_STATE = "ISATTENDEES_MODAL_STATE";
export const STEPS_MODAL_STATE = "STEPS_MODAL_STATE";

// GET TODOS STATUS
export const GET_TODOSSTATUS_INIT = "GET_TODOSSTATUS_INIT";
export const GET_TODOSSTATUS_SUCCESS = "GET_TODOSSTATUS_SUCCESS";
export const GET_TODOSSTATUS_FAIL = "GET_TODOSSTATUS_FAIL";

// UPDATE TODOS STATUS
export const UPDATE_TODOSTATUS_INIT = "UPDATE_TODOSTATUS_INIT";
export const UPDATE_TODOSTATUS_SUCCESS = "UPDATE_TODOSTATUS_SUCCESS";
export const UPDATE_TODOSTATUS_FAIL = "UPDATE_TODOSTATUS_FAIL";

//Download
export const GET_DOWNLOAD_FAIL = "GET_DOWNLOAD_FAIL";
export const GET_DOWNLOAD_LOADER_START = "GET_DOWNLOAD_LOADER_START";
export const SET_LOADER_FALSE_DOWNLOAD = "SET_LOADER_FALSE_DOWNLOAD";
export const DOWNLOAD_DOCUMENT_FILE_SUCCESS = "DOWNLOAD_DOCUMENT_FILE_SUCCESS";
export const DOWNLOAD_DOCUMENT_FILE_FAIL = "DOWNLOAD_DOCUMENT_FILE_FAIL";

export const RESET_ALL_FILES_UPLOAD = "RESET_ALL_FILES_UPLOAD";

// POST ASSGINEEE COMMENTS IN TODOVIEW
export const POST_ASSIGNEEECOMMENTS_INIT = "POST_ASSIGNEEECOMMENTS_INIT";
export const POST_ASSIGNEEECOMMENTS_SUCCESS = "POST_ASSIGNEEECOMMENTS_SUCCESS";
export const POST_ASSIGNEEECOMMENTS_FAIL = "POST_ASSIGNEEECOMMENTS_FAIL";

export const SET_LOADER_TRUE = "SET_LOADER_TRUE";

export const GET_MEETINGCOUNT_SUCCESS = "GET_MEETINGCOUNT_SUCCESS";
export const GET_MEETINGCOUNT_FAIL = "GET_MEETINGCOUNT_FAIL";

export const GET_UPCOMINGEVENTS_SUCCESS = "GET_UPCOMINGEVENTS_SUCCESS";
export const GET_UPCOMINGEVENTS_FAIL = "GET_UPCOMINGEVENTS_FAIL";

export const GET_TODOCOUNT_SUCCESS = "GET_TODOCOUNT_SUCCESS";
export const GET_TODOCOUNT_FAIL = "GET_TODOCOUNT_FAIL";

export const SET_SPINNER_TRUE = "SET_SPINNER_TRUE";
export const SET_SPINNER_FALSE = "SET_SPINNER_FALSE";

export const SET_TABLESPINNER_FALSE = "SET_TABLESPINNER_FALSE";

export const HIDE = "HIDE";

//SHOW VIDEO CHAT COMPONENTS
export const SHOW_ATTACHMENTS_STATE = "SHOW_ATTACHMENTS_STATE";
export const SHOW_MINUTES_STATE = "SHOW_MINUTES_STATE";

// SHOW HID MEETING AGENDA MODAL IN VIDEO CALL
export const MEETINGATTACHMENT_MODAL = "MEETINGATTACHMENT_MODAL";

// GET MEETING AGENDAS
export const GET_MEETINGAGENDAS_INIT = "GET_MEETINGAGENDAS_INIT";
export const GET_MEETINGAGENDAS_SUCCESS = "GET_MEETINGAGENDAS_SUCCESS";
export const GET_MEETINGAGENDAS_FAIL = "GET_MEETINGAGENDAS_FAIL";

// GET MEEETING ATTACHMENTS
export const GET_ATTACHMENTSBYMEETINGID_INIT =
  "GET_ATTACHMENTSBYMEETINGID_INIT";
export const GET_ATTACHMENTSBYMEETINGID_SUCCESS =
  "GET_ATTACHMENTSBYMEETINGID_SUCCESS";
export const GET_ATTACHMENTSBYMEETINGID_FAIL =
  "GET_ATTACHMENTSBYMEETINGID_FAIL";

// GET AGENDA ATTACHMENT UPDATES
export const AGENDA_ATTACHMENTUPDATE_INIT = "AGENDA_ATTACHMENTUPDATE_INIT";
export const AGENDA_ATTACHMENTUPDATE_SUCCESS =
  "AGENDA_ATTACHMENTUPDATE_SUCCESS";
export const AGENDA_ATTACHMENTUPDATE_FAIL = "AGENDA_ATTACHMENTUPDATE_FAIL";

export const UPDATE_MINUTESOFMEETING_INIT = "UPDATE_MINUTESOFMEETING_INIT";
export const UPDATE_MINUTESOFMEETING_SUCCESS =
  "UPDATE_MINUTESOFMEETING_SUCCESS";
export const UPDATE_MINUTESOFMEETING_FAIL = "UPDATE_MINUTESOFMEETING_FAIL";

export const ADD_MINUTESOFMEETING_INIT = "UPDATE_MINUTESOFMEETING_INIT";
export const ADD_MINUTESOFMEETING_SUCCESS = "UPDATE_MINUTESOFMEETING_SUCCESS";
export const ADD_MINUTESOFMEETING_FAIL = "UPDATE_MINUTESOFMEETING_FAIL";
export const HIDEMINUTEMESSAGE = "HIDEMINUTEMESSAGE";

export const GET_REMINDERS_INIT = "GET_REMINDERS_INIT";
export const GET_REMINDERS_SUCCESS = "GET_REMINDERS_SUCCESS";
export const GET_REMINDERS_FAIL = "GET_REMINDERS_FAIL";

export const AUTH_RESPONSE_MESSAGE = "AUTH_RESPONSE_MESSAGE";
export const DOWNLOAD_RESPONSE_MESSAGE = "DOWNLOAD_RESPONSE_MESSAGE";
export const FAQS_RESPONSE_MESSAGE = "FAQS_RESPONSE_MESSAGE";
export const LISTOFASSIGNEE_RESPONSE_MESSAGE =
  "LISTOFASSIGNEE_RESPONSE_MESSAGE";
export const CALENDAR_RESPONSE_MESSAGE = "CALENDAR_RESPONSE_MESSAGE";
export const MEETINGID_RESPONSE_MESSAGE = "MEETINGID_RESPONSE_MESSAGE";
export const TODOS_STATUS_RESPONSE_MESSAGE = "TODOS_STATUS_RESPONSE_MESSAGE";
export const POST_ASSIGNEE_RESPONSE_MESSAGE = "POST_ASSIGNEE_RESPONSE_MESSAGE";
export const SETTING_RESPONSE_MESSAGE = "SETTING_RESPONSE_MESSAGE";
export const TODOLIST_RESPONSE_MESSAGE = "TODOLIST_RESPONSE_MESSAGE";
export const UPLOAD_RESPONSE_MESSAGE = "UPLOAD_RESPONSE_MESSAGE";
export const VIDEOCHAT_RESPONSE_MESSAGE = "VIDEOCHAT_RESPONSE_MESSAGE";

// socket update recentactivity
export const SET_RECENT_ACTIVITY_NOTIFICATION =
  "SET_RECENT_ACTIVITY_NOTIFICATION";

// action type for get tododata from socket
export const SETTODO_RECENT_ACTIVITY_DATA = "SETTODO_RECENT_ACTIVITY_DATA";
export const SET_TODO_STATUS_DATA = "SET_TODO_STATUS_DATA";

// socket Meetings
export const ALL_MEETINGS_SOCKET = "ALL_MEETINGS_SOCKET";

// socket meeting status

export const MEETING_STATUS_SOCKET = "MEETING_STATUS_SOCKET";

export const MEETING_STATUS_ENDED = "MEETING_STATUS_ENDED";

// For comments Socket
export const POST_COMMENTS = "POST_COMMENTS";
export const EMPTYCOMMENTSFROMMQTT = "EMPTYCOMMENTSFROMMQTT";
export const DELETE_COMMENTS = "DELETE_COMMENTS";
// Aun work on admin action types (START) //

//For Admin AddUser
export const ADMIN_ADDUSER_INIT = "ADMIN_ADDUSER_INIT";
export const ADMIN_ADDUSER_SUCCESS = "ADMIN_ADDUSER_SUCCESS";
export const ADMIN_ADDUSER_FAIL = "ADMIN_ADDUSER_FAIL";

//For Admin OrganizationUserListStatistics
export const ADMIN_USERLISTSTATIST_INIT = "ADMIN_USERLISTSTATIST_INIT";
export const ADMIN_USERLISTSTATIST_SUCCESS = "ADMIN_USERLISTSTATIST_SUCCESS";
export const ADMIN_USERLISTSTATIST_FAIL = "ADMIN_USERLISTSTATIST_FAIL";

//For Admin AllMeeting
export const ADMIN_ALLMEETING_INIT = "ADMIN_ALLMEETING_INIT";
export const ADMIN_ALLMEETING_SUCCESS = "ADMIN_ALLMEETING_SUCCESS";
export const ADMIN_ALLMEETING_FAIL = "ADMIN_ALLMEETING_FAIL";

//For Admin CancelSub
export const ADMIN_CANCELSUB_INIT = "ADMIN_CANCELSUB_INIT";
export const ADMIN_CANCELSUB_SUCCESS = "ADMIN_CANCELSUB_SUCCESS";
export const ADMIN_CANCELSUB_FAIL = "ADMIN_CANCELSUB_FAIL";

//For Admin CustomerInformation
export const ADMIN_CUSTOMERINFORMATION_INIT = "ADMIN_CUSTOMERINFORMATION_INIT";
export const ADMIN_CUSTOMERINFORMATION_SUCCESS =
  "ADMIN_CUSTOMERINFORMATION_SUCCESS";
export const ADMIN_CUSTOMERINFORMATION_FAIL = "ADMIN_CUSTOMERINFORMATION_FAIL";

// For Admin Update CustomerInformation
export const ADMIN_UPDATE_CUSTOMERINFORMATION_INIT =
  "ADMIN_UPDATE_CUSTOMERINFORMATION_INIT";
export const ADMIN_UPDATE_CUSTOMERINFORMATION_SUCCESS =
  "ADMIN_UPDATE_CUSTOMERINFORMATION_SUCCESS";
export const ADMIN_UPDATE_CUSTOMERINFORMATION_FAIL =
  "ADMIN_UPDATE_CUSTOMERINFORMATION_FAIL";

//For Admin EditUser
export const ADMIN_EDITUSER_INIT = "ADMIN_EDITUSER_INIT";
export const ADMIN_EDITUSER_SUCCESS = "ADMIN_EDITUSER_SUCCESS";
export const ADMIN_EDITUSER_FAIL = "ADMIN_EDITUSER_FAIL";

//For Admin Invoice
export const ADMIN_INVOICE_INIT = "ADMIN_INVOICE_INIT";
export const ADMIN_INVOICE_SUCCESS = "ADMIN_INVOICE_SUCCESS";
export const ADMIN_INVOICE_FAIL = "ADMIN_INVOICE_FAIL";

//For Admin Organization
export const ADMIN_ORGANIZATION_INIT = "ADMIN_ORGANIZATION_INIT";
export const ADMIN_ORGANIZATION_SUCCESS = "ADMIN_ORGANIZATION_SUCCESS";
export const ADMIN_ORGANIZATION_FAIL = "ADMIN_ORGANIZATION_FAIL";

//For Admin Email Verifiation
export const ADMIN_EMAILVARIFICATION_INIT = "ADMIN_EMAILVARIFICATION_INIT";
export const ADMIN_EMAILVARIFICATION_SUCCESS =
  "ADMIN_EMAILVARIFICATION_SUCCESS";
export const ADMIN_EMAILVARIFICATION_FAIL = "ADMIN_EMAILVARIFICATION_FAIL";

//For Admin PackageDetail
export const ADMIN_PACKAGEDETAIL_INIT = "ADMIN_PACKAGEDETAIL_INIT";
export const ADMIN_PACKAGEDETAIL_SUCCESS = "ADMIN_PACKAGEDETAIL_SUCCESS";
export const ADMIN_PACKAGEDETAIL_FAIL = "ADMIN_PACKAGEDETAIL_FAIL";

//For Admin PackageUpgrade
export const ADMIN_PACKAGEUPGRADE_INIT = "ADMIN_PACKAGEUPGRADE_INIT";
export const ADMIN_PACKAGEUPGRADE_SUCCESS = "ADMIN_PACKAGEUPGRADE_SUCCESS";
export const ADMIN_PACKAGEUPGRADE_FAIL = "ADMIN_PACKAGEUPGRADE_FAIL";

//For Admin PayOutstanding
export const ADMIN_PAYOUTSTANDING_INIT = "ADMIN_PAYOUTSTANDING_INIT";
export const ADMIN_PAYOUTSTANDING_SUCCESS = "ADMIN_PAYOUTSTANDING_SUCCESS";
export const ADMIN_PAYOUTSTANDING_FAIL = "ADMIN_PAYOUTSTANDING_FAIL";

//For Admin Summary
export const ADMIN_SUMMARY_INIT = "ADMIN_SUMMARY_INIT";
export const ADMIN_SUMMARY_SUCCESS = "ADMIN_SUMMARY_SUCCESS";
export const ADMIN_SUMMARY_FAIL = "ADMIN_SUMMARY_FAIL";

// Aun work on admin action types (END) //

// for organization Sign up
export const COUNTRYNAMES_INIT = "COUNTRYNAMES_INIT";
export const COUNTRYNAMES_SUCCESS = "COUNTRYNAMES_SUCCESS";
export const COUNTRYNAMES_FAIL = "COUNTRYNAMES_FAIL";

export const GETSUBSCRIPTIONPACAKGES_INIT = "GETSUBSCRIPTIONPACAKGES_INIT";
export const GETSUBSCRIPTIONPACAKGES_SUCCESS =
  "GETSUBSCRIPTIONPACAKGES_SUCCESS";
export const GETSUBSCRIPTIONPACAKGES_FAIL = "GETSUBSCRIPTIONPACAKGES_FAIL";

export const SIGNUPORGANIZATION_INIT = "SIGNUPORGANIZATION_INIT";
export const SIGNUPORGANIZATION_SUCCESS = "SIGNUPORGANIZATION_SUCCESS";
export const SIGNUPORGANIZATION_FAIL = "SIGNUPORGANIZATION_FAIL";

export const EMAILVALIDATION_INIT = "EMAILVALIDATION_INIT";
export const EMAILVALIDATION_SUCCESS = "EMAILVALIDATION_SUCCESS";
export const EMAILVALIDATION_FAIL = "EMAILVALIDATION_FAIL";

export const PASSWORDVALIDATION_INIT = "PASSWORDVALIDATION_INIT";
export const PASSWORDVALIDATION_SUCCESS = "PASSWORDVALIDATION_SUCCESS";
export const PASSWORDVALIDATION_FAIL = "PASSWORDVALIDATION_FAIL";

export const VERIFYOTPFOREMAIL_INIT = "VERIFYOTPFOREMAIL_INIT";
export const VERIFYOTPFOREMAIL_SUCCESS = "VERIFYOTPFOREMAIL_SUCCESS";
export const VERIFYOTPFOREMAIL_FAIL = "VERIFYOTPFOREMAIL_FAIL";

export const PASSWORDCREATION_INIT = "PASSWORDCREATION_INIT";
export const PASSWORDCREATION_SUCCESS = "PASSWORDCREATION_SUCCESS";
export const PASSWORDCREATION_FAIL = "PASSWORDCREATION_FAIL";

export const GETSELECTEDPACAKGEANDORGANIZATIONDETAILS_INIT =
  "GETSELECTEDPACAKGEANDORGANIZATIONDETAILS_INIT";
export const GETSELECTEDPACAKGEANDORGANIZATIONDETAILS_SUCCESS =
  "GETSELECTEDPACAKGEANDORGANIZATIONDETAILS_SUCCESS";
export const GETSELECTEDPACAKGEANDORGANIZATIONDETAILS_FAIL =
  "GETSELECTEDPACAKGEANDORGANIZATIONDETAILS_FAIL";

// for organization role list

export const ADMIN_ALLORGANAIZATIONROLES_INIT =
  "ADMIN_ALLORGANAIZATIONROLES_INIT";
export const ADMIN_ALLORGANAIZATIONROLES_SUCCESS =
  "ADMIN_ALLORGANAIZATIONROLES_SUCCESS";
export const ADMIN_ALLORGANAIZATIONROLES_FAIL =
  "ADMIN_ALLORGANAIZATIONROLES_FAIL";

export const GROUP_CALL_RECIPIENTS = "GROUP_CALL_RECIPIENTS";
// for user role list

export const ADMIN_USERROLELIST_INIT = "ADMIN_USERROLELIST_INIT";
export const ADMIN_USERROLELIST_SUCCESS = "ADMIN_USERROLELIST_SUCCESS";
export const ADMIN_USERROLELIST_FAIL = "ADMIN_USERROLELIST_FAIL";

// for get organization Name
export const ADMIN_GETORGANAIZATIONID_INIT = "ADMIN_GETORGANAIZATIONID_INIT";
export const ADMIN_GETORGANAIZATIONID_INIT_SUCCESS =
  "ADMIN_GETORGANAIZATIONID_INIT_SUCCESS";
export const ADMIN_GETORGANAIZATIONID_INIT_FAIL =
  "ADMIN_GETORGANAIZATIONID_INIT_FAIL";

// for get organization CLEARE MESSAGE
export const ADMIN_CLEARE_MESSAGE = "ADMIN_CLEARE_MESSAGE";

// for get All User List

export const ADMIN_ALLUSERLIST_INIT = "ADMIN_ALLUSERLIST_INIT";
export const ADMIN_ALLUSERLIST_SUCCESS = "ADMIN_ALLUSERLIST_SUCCESS";
export const ADMIN_ALLUSERLIST_FAIL = "ADMIN_ALLUSERLIST_FAIL";

// for get All User STATUS
export const ADMIN_USERSTATUSLIST_INIT = "ADMIN_USERSTATUSLIST_INIT";
export const ADMIN_USERSTATUSLIST_SUCCESS = "ADMIN_USERSTATUSLIST_SUCCESS";
export const ADMIN_USERSTATUSLIST_FAIL = "ADMIN_USERSTATUSLIST_FAIL";

// for get Edit Organization User
export const ADMIN_EDITORGANIZATIONUSER_INIT =
  "ADMIN_EDITORGANIZATIONUSER_INIT";
export const ADMIN_EDITORGANIZATIONUSER_SUCCESS =
  "ADMIN_EDITORGANIZATIONUSER_SUCCESS";
export const ADMIN_EDITORGANIZATIONUSER_FAIL =
  "ADMIN_EDITORGANIZATIONUSER_FAIL";

// for get Delete Organization User
export const ADMIN_DELETEORGANIZATIONUSER_INIT =
  "ADMIN_DELETEORGANIZATIONUSER_INIT";
export const ADMIN_DELETEORGANIZATIONUSER_SUCCESS =
  "ADMIN_DELETEORGANIZATIONUSER_SUCCESS";
export const ADMIN_DELETEORGANIZATIONUSER_FAIL =
  "ADMIN_DELETEORGANIZATIONUSER_FAIL";

export const GETPACKAGEEXPIRYDETAILS_INIT = "GETPACKAGEEXPIRYDETAILS_INIT";
export const GETPACKAGEEXPIRYDETAILS_SUCCESS =
  "GETPACKAGEEXPIRYDETAILS_SUCCESS";
export const GETPACKAGEEXPIRYDETAILS_FAIL = "GETPACKAGEEXPIRYDETAILS_FAIL";

export const ALLORGAINZATIONMEETING_INIT = "ALLORGAINZATIONMEETING_INIT";
export const ALLORGAINZATIONMEETING_SUCCESS = "ALLORGAINZATIONMEETING_SUCCESS";
export const ALLORGAINZATIONMEETING_FAIL = "ALLORGAINZATIONMEETING_FAIL";

export const UPDATEORGANIZTIONMEETING_INIT = "UPDATEORGANIZTIONMEETING_INIT";
export const UPDATEORGANIZTIONMEETING_SUCCESS =
  "UPDATEORGANIZTIONMEETING_SUCCESS";
export const UPDATEORGANIZTIONMEETING_FAIL = "UPDATEORGANIZTIONMEETING_FAIL";

export const DELETEORGANIZATIONMEETING_INIT = "DELETEORGANIZATION_INIT";
export const DELETEORGANIZATIONMEETING_SUCCESS = "DELETEORGANIZATION_SUCCESS";
export const DELETEORGANIZATIONMEETING_FAIL = "DELETEORGANIZATION_FAIL";

export const GETORGANIZATIONLEVELSETTING_INIT =
  "GETORGANIZATIONLEVELSETTING_INIT";
export const GETORGANIZATIONLEVELSETTING_SUCCESS =
  "GETORGANIZATIONLEVELSETTING_SUCCESS";
export const GETORGANIZATIONLEVELSETTING_FAIL =
  "GETORGANIZATIONLEVELSETTING_FAIL";

export const UPDATEORGANIZATIONLEVELSETTING_INIT =
  "UPDATEORGANIZATIONLEVELSETTING_INIT";
export const UPDATEORGANIZATIONLEVELSETTING_SUCCESS =
  "UPDATEORGANIZATIONLEVELSETTING_SUCCESS";
export const UPDATEORGANIZATIONLEVELSETTING_FAIL =
  "UPDATEORGANIZATIONLEVELSETTING_FAIL";

export const GETSUBSCRIBEORGANIZATIONPACKAGE_INIT =
  "GETSUBSCRIBEORGANIZATION_INIT";
export const GETSUBSCRIBEORGANIZATIONPACKAGE_SUCCESS =
  "GETSUBSCRIBEORGANIZATIONPACKAGE_SUCCESS";
export const GETSUBSCRIBEORGANIZATIONPACKAGE_FAIL =
  "GETSUBSCRIBEORGANIZATIONPACKAGE_FAIL";

export const GETUPGRADABLESUBSCRIPTIONPACAKGE_INIT =
  "GETUPGRADABLESUBSCRIPTIONPACAKGE_INIT";
export const GETUPGRADABLESUBSCRIPTIONPACAKGE_SUCCESS =
  "GETUPGRADABLESUBSCRIPTIONPACAKGE_SUCCESS";
export const GETUPGRADABLESUBSCRIPTIONPACAKGE_FAIL =
  "GETUPGRADABLESUBSCRIPTIONPACAKGE_FAIL";

export const UPGRADESUBSRIPTIONPACKAGE_INIT = "UPGRADESUBSRIPTIONPACKAGE_INIT";
export const UPGRADESUBSRIPTIONPACKAGE_SUCCESS =
  "UPGRADESUBSRIPTIONPACKAGE_SUCCESS";
export const UPGRADESUBSRIPTIONPACKAGE_FAIL = "UPGRADESUBSRIPTIONPACKAGE_FAIL";

export const CANCELSUBCRIPTIONPACKAGE_INIT = "CANCELSUBCRIPTIONPACKAGE_INIT";
export const CANCELSUBCRIPTIONPACKAGE_SUCCESS =
  "CANCELSUBCRIPTIONPACKAGE_SUCCESS";
export const CANCELSUBCRIPTIONPACKAGE_FAIL = "CANCELSUBCRIPTIONPACKAGE_FAIL";

// for get all meeting status
export const ADMIN_MEETINGSTATUS_INIT = "ADMIN_MEETINGSTATUS_INIT";
export const ADMIN_MEETINGSTATUS_SUCCESS = "ADMIN_MEETINGSTATUS_SUCCESS";
export const ADMIN_MEETINGSTATUS_FAIL = "ADMIN_MEETINGSTATUS_FAIL";

// FOR AUTH2 REDUCERS CLEARE MESSAGE
export const CLEARE_MESSAGE = "CLEARE_MESSAGE";

export const UDPATEUSERSETTING_INIT = "UDPATEUSERSETTING_INIT";
export const UDPATEUSERSETTING_SUCCESS = "UDPATEUSERSETTING_SUCCESS";
export const UDPATEUSERSETTING_FAIL = "UDPATEUSERSETTING_FAIL";

export const CHANGEPASSWORD_INIT = "CHANGEPASSWORD_INIT";
export const CHANGEPASSWORD_SUCCESS = "CHANGEPASSWORD_SUCCESS";
export const CHANGEPASSWORD_FAIL = "CHANGEPASSWORD_FAIL";

// FOR GET PACKAGE Reselect
export const RESELECTIONPACKAGE_INIT = "RESELECTIONPACKAGE_INIT";
export const RESELECTIONPACKAGE_SUCCESS = "RESELECTIONPACKAGE_SUCCESS";
export const RESELECTIONPACKAGE_FAIL = "RESELECTIONPACKAGE_FAIL";

// FOR LODER OF ADD USER ACTIONS
export const ADD_USER_LOADER = "ADD_USER_LOADER";
export const ADD_EMAIL_CHECK_FALSE = "ADD_EMAIL_CHECK_FALSE";

// FOR TODO LIST RESPONCE
export const CLEAR_RESPONCE_STATE = "CLEAR_RESPONCE_STATE";

// FOR GET TODO CLEARE MESSAGE LIST RESPONCE
export const GET_CLEAREMESSAGE_GETTODO = "GET_CLEAREMESSAGE_GETTODO";

// FOR UDPATE USERSET TINGCLEARE MESSAGE LIST RESPONCE
export const UDPATEUSERSETTING_MESSAGE_CLEARE =
  "UDPATEUSERSETTING_MESSAGE_CLEARE";

// FOR AUTHENTICATE AFA IS ENABLE OR NOT
export const CHECKINGAUTHENTICATEAFA_INIT = "CHECKINGAUTHENTICATEAFA_INIT";
export const CHECKINGAUTHENTICATEAFA_SUCCESS =
  "CHECKINGAUTHENTICATEAFA_SUCCESS";
export const CHECKINGAUTHENTICATEAFA_FAIL = "CHECKINGAUTHENTICATEAFA_FAIL";

// FOR SEND TWO FAC OTP CODE
export const SENDTWOFACOTP_INIT = "SENDTWOFACOTP_INIT";
export const SENDTWOFACOTP_SUCCESS = "SENDTWOFACOTP_SUCCESS";
export const SENDTWOFACOTP_FAIL = "SENDTWOFACOTP_FAIL";

// FOR VERIFY TWO FAC OTP CODE
export const VERIFYTWOFACOTP_INIT = "VERIFYTWOFACOTP_INIT";
export const VERIFYTWOFACOTP_SUCCESS = "VERIFYTWOFACOTP_SUCCESS";
export const VERIFYTWOFACOTP_FAIL = "VERIFYTWOFACOTP_FAIL";

// FOR CLEARE MESSAGE OF SET SUB PACK
export const CLEARE_MESSAGE_SUBSPACK = "CLEARE_MESSAGE_SUBSPACK";

// FOR CLEARE RESPONSE MESSAGE OF CHANGE PASSWORD
export const CLEARE_CHANGE_PASSWORD_MESSAGE = "CLEARE_CHANGE_PASSWORD_MESSAGE";

//PASSWORD UPDATION ON FORGOT PASSWORD

export const PASSWORD_UPDATE_INIT = "PASSWORD_UPDATE_INIT";
export const PASSWORD_UPDATE_SUCCESS = "PASSWORD_UPDATE_SUCCESS";
export const PASSWORD_UPDATE_FAIL = "PASSWORD_UPDATE_FAIL";

//Revoke

export const REVOKE_INIT = "REVOKE_INIT";
export const REVOKE_SUCCESS = "REVOKE_SUCCESS";
export const REVOKE_FAIL = "REVOKE_FAIL";

export const GET_USERS_DETAILS_INIT = "GET_USERS_DETAILS_INIT";
export const GET_USERS_DETAILS_SUCCESS = "GET_USERS_DETAILS_SUCCESS";
export const GET_USERS_DETAILS_FAIL = "GET_USERS_DETAILS_FAIL";

export const UPDATE_USER_PROFILE_INIT = "UPDATE_USER_PROFILE_INIT";
export const UPDATE_USER_PROFILE_SUCCESS = "UPDATE_USER_PROFILE_SUCCESS";
export const UPDATE_USER_PROFILE_FAIL = "UPDATE_USER_PROFILE_FAIL";

export const AUTH2_REDUCER_LOADER = "AUTH2_REDUCER_LOADER";

export const VIDEO_BOX_OPEN = "VIDEO_BOX_OPEN";
export const VIDEO_BOX_CLOSE = "VIDEO_BOX_CLOSE";
export const VIDEO_BOX_MINIMIZE = "VIDEO_BOX_MINIMIZE";
export const VIDEO_BOX_NORMAL = "VIDEO_BOX_NORMAL";
export const VIDEO_BOX_MAXIMIZE = "VIDEO_BOX_MAXIMIZE";
export const OPENING_GROUP_CALL = "OPENING_GROUP_CALL";

export const INCOMING_CALL_OPEN = "INCOMING_CALL_OPEN";

export const ADD_NOTES_INIT = "ADD_NOTES_INIT";
export const ADD_NOTES_SUCCESS = "ADD_NOTES_SUCCESS";
export const ADD_NOTES_FAIL = "ADD_NOTES_FAIL";

export const UPDATE_NOTES_INIT = "UPDATE_NOTES_INIT";
export const UPDATE_NOTES_SUCCESS = "UPDATE_NOTES_SUCCESS";
export const UPDATE_NOTES_FAIL = "UPDATE_NOTES_FAIL";

export const GET_NOTES_INIT = "GET_NOTES_INIT";
export const GET_NOTES_SUCCESS = "GET_NOTES_SUCCESS";
export const GET_NOTES_FAIL = "GET_NOTES_FAIL";

export const GET_NOTES_BY_NOTESID_INIT = "GET_NOTES_BY_NOTESID_INIT";
export const GET_NOTES_BY_NOTESID_SUCCESS = "GET_NOTES_BY_NOTESID_SUCCESS";
export const GET_NOTES_BY_NOTESID_FAIL = "GET_NOTES_BY_NOTESID_FAIL";

export const GET_NOTES_ATTACHMENTS_INIT = "GET_NOTES_ATTACHMENTS_INIT";
export const GET_NOTES_ATTACHMENTS_SUCCESS = "GET_NOTES_ATTACHMENTS_SUCCESS";
export const GET_NOTES_ATTACHMENTS_FAIL = "GET_NOTES_ATTACHMENTS_FAIL";

export const ALL_MEETINGS_MQTT = "ALL_MEETINGS_MQTT";

export const DELETE_ORGANIZATION_INIT = "DELETE_ORGANIZATION_INIT";
export const DELETE_ORGANIZATION_SUCCESS = "DELETE_ORGANIZATION_SUCCESS";
export const DELETE_ORGANIZATION_FAIL = "DELETE_ORGANIZATION_FAIL";

export const CLEAR_NOTES_RESPONSEMESSAGE = "CLEAR_NOTES_RESPONSEMESSAGE";

export const REFRESH_TOKEN_TALK_SUCCESS = "REFRESH_TOKEN_TALK_SUCCESS";
export const REFRESH_TOKEN_TALK_FAIL = "REFRESH_TOKEN_TALK_FAIL";

export const GET_USERCHATS_INIT = "GET_USERCHATS_INIT";
export const GET_USERCHATS_SUCCESS = "GET_USERCHATS_SUCCESS";
export const GET_USERCHATS_FAIL = "GET_USERCHATS_FAIL";

export const GET_OTOUSERMESSAGES_INIT = "GET_OTOUSERMESSAGES_INIT";
export const GET_OTOUSERMESSAGES_SUCCESS = "GET_OTOUSERMESSAGES_SUCCESS";
export const GET_OTOUSERMESSAGES_FAIL = "GET_OTOUSERMESSAGES_FAIL";

export const GET_OTOUSERUNDELIVEREDMESSAGES_INIT =
  "GET_OTOUSERUNDELIVEREDMESSAGES_INIT";
export const GET_OTOUSERUNDELIVEREDMESSAGES_SUCCESS =
  "GET_OTOUSERUNDELIVEREDMESSAGES_SUCCESS";
export const GET_OTOUSERUNDELIVEREDMESSAGES_FAIL =
  "GET_OTOUSERUNDELIVEREDMESSAGES_FAIL";

export const GET_GROUPMESSAGES_INIT = "GET_GROUPMESSAGES_INIT";
export const GET_GROUPMESSAGES_SUCCESS = "GET_GROUPMESSAGES_SUCCESS";
export const GET_GROUPMESSAGES_FAIL = "GET_GROUPMESSAGES_FAIL";

export const GET_BROADCASTMESSAGES_INIT = "GET_BROADCASTMESSAGES_INIT";
export const GET_BROADCASTMESSAGES_SUCCESS = "GET_BROADCASTMESSAGES_SUCCESS";
export const GET_BROADCASTMESSAGES_FAIL = "GET_BROADCASTMESSAGES_FAIL";

export const GET_ARCHIVEDDATABYUSERID_INIT = "GET_ARCHIVEDDATABYUSERID_INIT";
export const GET_ARCHIVEDDATABYUSERID_SUCCESS =
  "GET_ARCHIVEDDATABYUSERID_SUCCESS";
export const GET_ARCHIVEDDATABYUSERID_FAIL = "GET_ARCHIVEDDATABYUSERID_FAIL";

export const GET_FLAGMESSAGES_INIT = "GET_FLAGMESSAGES_INIT";
export const GET_FLAGMESSAGES_SUCCESS = "GET_FLAGMESSAGES_SUCCESS";
export const GET_FLAGMESSAGES_FAIL = "GET_FLAGMESSAGES_FAIL";

export const GET_FOLLOWMESSAGES_INIT = "GET_FOLLOWMESSAGES_INIT";
export const GET_FOLLOWMESSAGES_SUCCESS = "GET_FOLLOWMESSAGES_SUCCESS";
export const GET_FOLLOWMESSAGES_FAIL = "GET_FOLLOWMESSAGES_FAIL";

export const GET_RECENTTAGS_INIT = "GET_RECENTTAGS_INIT";
export const GET_RECENTTAGS_SUCCESS = "GET_RECENTTAGS_SUCCESS";
export const GET_RECENTTAGS_FAIL = "GET_RECENTTAGS_FAIL";

export const GET_TAGSMESSAGES_INIT = "GET_TAGSMESSAGES_INIT";
export const GET_TAGSMESSAGES_SUCCESS = "GET_TAGSMESSAGES_SUCCESS";
export const GET_TAGSMESSAGES_FAIL = "GET_TAGSMESSAGES_FAIL";

export const GET_MESSAGESENTRECEIVETIME_INIT =
  "GET_MESSAGESENTRECEIVETIME_INIT";
export const GET_MESSAGESENTRECEIVETIME_SUCCESS =
  "GET_MESSAGESENTRECEIVETIME_SUCCESS";
export const GET_MESSAGESENTRECEIVETIME_FAIL =
  "GET_MESSAGESENTRECEIVETIME_FAIL";

export const GET_RECENTFLAGCOUNT_INIT = "GET_RECENTFLAGCOUNT_INIT";
export const GET_RECENTFLAGCOUNT_SUCCESS = "GET_RECENTFLAGCOUNT_SUCCESS";
export const GET_RECENTFLAGCOUNT_FAIL = "GET_RECENTFLAGCOUNT_FAIL";

export const GET_RECENTFOLLOWDATACOUNT_INIT = "GET_RECENTFOLLOWDATACOUNT_INIT";
export const GET_RECENTFOLLOWDATACOUNT_SUCCESS =
  "GET_RECENTFOLLOWDATACOUNT_SUCCESS";
export const GET_RECENTFOLLOWDATACOUNT_FAIL = "GET_RECENTFOLLOWDATACOUNT_FAIL";

export const GET_ALLRECENTTAGSCOUNT_INIT = "GET_ALLRECENTTAGSCOUNT_INIT";
export const GET_ALLRECENTTAGSCOUNT_SUCCESS = "GET_ALLRECENTTAGSCOUNT_SUCCESS";
export const GET_ALLRECENTTAGSCOUNT_FAIL = "GET_ALLRECENTTAGSCOUNT_FAIL";

export const GET_RECENTDATAARCHIVECOUNT_INIT =
  "GET_RECENTDATAARCHIVECOUNT_INIT";
export const GET_RECENTDATAARCHIVECOUNT_SUCCESS =
  "GET_RECENTDATAARCHIVECOUNT_SUCCESS";
export const GET_RECENTDATAARCHIVECOUNT_FAIL =
  "GET_RECENTDATAARCHIVECOUNT_FAIL";

export const GET_BLOCKEDUSERSCOUNT_INIT = "GET_BLOCKEDUSERSCOUNT_INIT";
export const GET_BLOCKEDUSERSCOUNT_SUCCESS = "GET_BLOCKEDUSERSCOUNT_SUCCESS";
export const GET_BLOCKEDUSERSCOUNT_FAIL = "GET_BLOCKEDUSERSCOUNT_FAIL";

export const GET_BLOCKEDUSERS_INIT = "GET_BLOCKEDUSERS_INIT";
export const GET_BLOCKEDUSERS_SUCCESS = "GET_BLOCKEDUSERS_SUCCESS";
export const GET_BLOCKEDUSERS_FAIL = "GET_BLOCKEDUSERS_FAIL";

export const GET_ALLUSERS_INIT = "GET_ALLUSERS_INIT";
export const GET_ALLUSERS_SUCCESS = "GET_ALLUSERS_SUCCESS";
export const GET_ALLUSERS_FAIL = "GET_ALLUSERS_FAIL";

export const GET_ALLUSERSGROUPSROOMSLIST_INIT =
  "GET_ALLUSERSGROUPSROOMSLIST_INIT";
export const GET_ALLUSERSGROUPSROOMSLIST_SUCCESS =
  "GET_ALLUSERSGROUPSROOMSLIST_SUCCESS";
export const GET_ALLUSERSGROUPSROOMSLIST_FAIL =
  "GET_ALLUSERSGROUPSROOMSLIST_FAIL";

export const GET_ACTIVEUSERSBYGROUPID_INIT = "GET_ACTIVEUSERSBYGROUPID_INIT";
export const GET_ACTIVEUSERSBYGROUPID_SUCCESS =
  "GET_ACTIVEUSERSBYGROUPID_SUCCESS";
export const GET_ACTIVEUSERSBYGROUPID_FAIL = "GET_ACTIVEUSERSBYGROUPID_FAIL";

export const GET_ACTIVEUSERSBYROOMID_INIT = "GET_ACTIVEUSERSBYROOMID_INIT";
export const GET_ACTIVEUSERSBYROOMID_SUCCESS =
  "GET_ACTIVEUSERSBYROOMID_SUCCESS";
export const GET_ACTIVEUSERSBYROOMID_FAIL = "GET_ACTIVEUSERSBYROOMID_FAIL";

export const GET_ACTIVEUSERSBYBROADCASTID_INIT =
  "GET_ACTIVEUSERSBYBROADCASTID_INIT";
export const GET_ACTIVEUSERSBYBROADCASTID_SUCCESS =
  "GET_ACTIVEUSERSBYBROADCASTID_SUCCESS";
export const GET_ACTIVEUSERSBYBROADCASTID_FAIL =
  "GET_ACTIVEUSERSBYBROADCASTID_FAIL";

export const GET_COMMITTEE_BYUSERID_INIT = "GET_COMMITTEE_BYUSERID_INIT";
export const GET_COMMITTEE_BYUSERID_SUCCESS = "GET_COMMITTEE_BYUSERID_INIT";
export const GET_COMMITTEE_BYUSERID_FAIL = "GET_COMMITTEE_BYUSERID_INIT";

export const ADD_COMMITTEE_INIT = "ADD_COMMITTEE_INIT";
export const ADD_COMMITTEE_SUCCESS = "ADD_COMMITTEE_SUCCESS";
export const ADD_COMMITTEE_FAIL = "ADD_COMMITTEE_FAIL";

export const CLEAR_MESSAGE_RESPONSE_COMMITTEE =
  "CLEAR_MESSAGE_RESPONSE_COMMITTEE";

export const GET_COMMITTEE_BYCOMMITTEEID_INIT =
  "GET_COMMITTEE_BYCOMMITTEEID_INIT";
export const GET_COMMITTEE_BYCOMMITTEEID_SUCCESS =
  "GET_COMMITTEE_BYCOMMITTEEID_SUCCESS";
export const GET_COMMITTEE_BYCOMMITTEEID_FAIL =
  "GET_COMMITTEE_BYCOMMITTEEID_FAIL";

export const GET_GROUPS_BYUSERID_INIT = "GET_GROUPS_BYUSERID_INIT";
export const GET_GROUPS_BYUSERID_SUCCESS = "GET_GROUPS_BYUSERID_SUCCESS";
export const GET_GROUPS_BYUSERID_FAIL = "GET_GROUPS_BYUSERID_FAIL";

export const GET_GROUPS_BYGROUPID_INIT = "GET_GROUPS_BYGROUPID_INIT";
export const GET_GROUPS_BYGROUPID_SUCCESS = "GET_GROUPS_BYGROUPID_SUCCESS";
export const GET_GROUPS_BYGROUPID_FAIL = "GET_GROUPS_BYGROUPID_FAIL";

export const UPDATE_GROUP_INIT = "UPDATE_GROUP_INIT";
export const UPDATE_GROUP_SUCCESSS = "UPDATE_GROUP_SUCCESSS";
export const UPDATE_GROUP_FAIL = "UPDATE_GROUP_FAIL";

export const CLEAR_RESPONSE_MESSAGE_GROUPS = "CLEAR_RESPONSE_MESSAGE_GROUPS";

export const CREATE_GROUP_INIT = "CREATE_GROUP_INIT";
export const CREATE_GROUP_SUCCESS = "CREATE_GROUP_SUCCESS";
export const CREATE_GROUP_FAIL = "CREATE_GROUP_FAIL";

export const GET_GROUP_MEMBERS_ROLES_INIT = "GET_GROUP_MEMBERS_ROLES_INIT";
export const GET_GROUP_MEMBERS_ROLES_SUCCESS =
  "GET_GROUP_MEMBERS_ROLES_SUCCESS";
export const GET_GROUP_MEMBERS_ROLES_FAIL = "GET_GROUP_MEMBERS_ROLES_FAIL";

export const GET_GROUP_ORGANIZATION_TYPE_INIT =
  "GET_GROUP_ORGANIZATION_TYPE_INIT";
export const GET_GROUP_ORGANIZATION_TYPE_SUCCESS =
  "GET_GROUP_ORGANIZATION_TYPE_SUCCESS";
export const GET_GROUP_ORGANIZATION_TYPE_FAIL =
  "GET_GROUP_ORGANIZATION_TYPE_FAIL";

export const UPDATE_GROUP_STATUS_INIT = "UPDATE_GROUP_STATUS_INIT";
export const UPDATE_GROUP_STATUS_SUCCESS = "UPDATE_GROUP_STATUS_SUCCESS";
export const UPDATE_GROUP_STATUS_FAIL = "UPDATE_GROUP_STATUS_FAIL";

export const GET_ALL_COMMITTEES_BY_USERID_INIT =
  "GET_ALL_COMMITTEES_BY_USERID_INIT";
export const GET_ALL_COMMITTEES_BY_USERID_SUCCESS =
  "GET_ALL_COMMITTEES_BY_USER_ID_SUCCESS";
export const GET_ALL_COMMITTEES_BY_USERID_FAIL =
  "GET_ALL_COMMITTEES_BY_USER_ID_FAIL";

export const CREATE_COMMITTEE_INIT = "CREATE_COMMITTEE_INIT";
export const CREATE_COMMITTEE_SUCCESS = "CREATE_COMMITTEE_SUCCESS";
export const CREATE_COMMITTEE_FAIL = "CREATE_COMMITTEE_FAIL";

export const GET_ALL_COMMITTEE_TYPES_INIT = "GET_ALL_COMMITTEE_TYPES_INIT";
export const GET_ALL_COMMITTEE_TYPES_SUCCESS =
  "GET_ALL_COMMITTEE_TYPES_SUCCESS";
export const GET_ALL_COMMITTEE_TYPES_FAIL = "GET_ALL_COMMITTEE_TYPES_FAIL";

export const GET_COMMITTEE_MEMBERS_ROLES_INIT =
  "GET_COMMITTEE_MEMBERS_ROLES_INIT";
export const GET_COMMITTEE_MEMBERS_ROLES_SUCCESS =
  "GET_COMMITTEE_MEMBERS_ROLES_SUCCESS";
export const GET_COMMITTEE_MEMBERS_ROLES_FAIL =
  "GET_COMMITTEE_MEMBERS_ROLES_FAIL";

export const OTO_MESSAGESEND_INIT = "OTO_MESSAGESEND_INIT";
export const OTO_MESSAGESEND_SUCCESS = "OTO_MESSAGESEND_SUCCESS";
export const OTO_MESSAGESEND_FAIL = "OTO_MESSAGESEND_FAIL";

export const UPDATE_COMMITTEE_STATUS_INIT = "UPDATE_COMMITTEE_STATUS_INIT";
export const UPDATE_COMMITTEE_STATUS_SUCCESS =
  "UPDATE_COMMITTEE_STATUS_SUCCESS";
export const UPDATE_COMMITTEE_STATUS_FAIL = "UPDATE_COMMITTEE_STATUS_FAIL";

export const UPDATE_COMMITTEE_INIT = "UPDATE_COMMITTEE_INIT";
export const UPDATE_COMMITTEE_SUCCESS = "UPDATE_COMMITTEE_SUCCESS";
export const UPDATE_COMMITTEE_FAIL = "UPDATE_COMMITTEE_FAIL";

export const PRIVATEGROUP_MESSAGESEND_INIT = "PRIVATEGROUP_MESSAGESEND_INIT";
export const PRIVATEGROUP_MESSAGESEND_NOTIFICATION =
  "PRIVATEGROUP_MESSAGESEND_NOTIFICATION";

export const BLOCK_UNBLOCK_USER_INIT = "BLOCK_UNBLOCK_USER_INIT";
export const BLOCK_UNBLOCK_USER_NOTIFICATION =
  "BLOCK_UNBLOCK_USER_NOTIFICATION";

export const REALTIME_GROUPS_RESPONSE = "REALTIME_GROUPS_RESPONSE";
export const REALTIME_GROUPS_STATUS_RESPONSE =
  "REALTIME_GROUPS_STATUS_RESPONSE";
// export const REALTIME_GROUPS_RESPONSE = "REALTIME_GROUPS_RESPONSE"

export const DELETE_SINGLEMESSAGE_INIT = "DELETE_SINGLEMESSAGE_INIT";
export const DELETE_SINGLEMESSAGE_SUCCESS = "DELETE_SINGLEMESSAGE_SUCCESS";
export const DELETE_SINGLEMESSAGE_FAIL = "DELETE_SINGLEMESSAGE_FAIL";

export const BROADCAST_MESSAGESEND_INIT = "BROADCAST_MESSAGESEND_INIT";
export const BROADCAST_MESSAGESEND_NOTIFICATION =
  "BROADCAST_MESSAGESEND_NOTIFICATION";

export const REALTIME_COMMITTEES_RESPONSE = "REALTIME_COMMITTEES_RESPONSE";
export const REALTIME_COMMITTEES_STATUS_RESPONSE =
  "REALTIME_COMMITTEES_STATUS_RESPONSE";

export const GET_ALL_VOTING_METHOD_INIT = "GET_ALL_VOTING_METHOD_INIT";
export const GET_ALL_VOTING_METHOD_SUCCESS = "GET_ALL_VOTING_METHOD_SUCCESS";
export const GET_ALL_VOTING_METHOD_FAIL = "GET_ALL_VOTING_METHOD_FAIL";

export const GET_ALL_RESOLUTION_STATUS_INIT = "GET_ALL_RESOLUTION_STATUS_INIT";
export const GET_ALL_RESOLUTION_STATUS_SUCCESS =
  "GET_ALL_RESOLUTION_STATUS_SUCCESS";
export const GET_ALL_RESOLUTION_STATUS_FAIL = "GET_ALL_RESOLUTION_STATUS_FAIL";

export const GET_RESOLUTIONS_INIT = "GET_RESOLUTIONS_INIT";
export const GET_RESOLUTIONS_SUCCESS = "GET_RESOLUTIONS_SUCCESS";
export const GET_RESOLUTIONS_FAIL = "GET_RESOLUTIONS_FAIL";
export const SET_MQTT_CLIENT = "SET_MQTT_CLIENT";

export const MQTT_INSERT_OTO_MESSAGE = "MQTT_INSERT_OTO_MESSAGE";
export const MQTT_INSERT_PRIVATEGROUP_MESSAGE =
  "MQTT_INSERT_PRIVATEGROUP_MESSAGE";

export const SCHEDULE_RESOLUTION_INIT = "SCHEDULE_RESOLUTION_INIT";
export const SCHEDULE_RESOLUTION_SUCCESS = "SCHEDULE_RESOLUTION_SUCCESS";
export const SCHEDULE_RESOLUTION_FAIL = "SCHEDULE_RESOLUTION_FAIL";

export const ADD_UPDATE_DETAILS_RESOLUTION_INIT =
  "ADD_UPDATE_DETAILS_RESOLUTION_INIT";
export const ADD_UPDATE_DETAILS_RESOLUTION_SUCCESS =
  "ADD_UPDATE_DETAILS_RESOLUTION_SUCCESS";
export const ADD_UPDATE_DETAILS_RESOLUTION_FAIL =
  "ADD_UPDATE_DETAILS_RESOLUTION_FAIL";

export const DELETE_NOTE_INIT = "DELETE_NOTE_INIT";
export const DELETE_NOTE_SUCCESS = "DELETE_NOTE_SUCCESS";
export const DELETE_NOTE_FAIL = "DELETE_NOTE_FAIL";

export const GET_ALL_ORGANIZATION_GROUPS_INIT =
  "GET_ALL_ORGANIZATION_GROUPS_INIT";
export const GET_ALL_ORGANIZATION_GROUPS_SUCCESS =
  "GET_ALL_ORGANIZATION_GROUPS_SUCCESS";
export const GET_ALL_ORGANIZATION_GROUPS_FAIL =
  "GET_ALL_ORGANIZATION_GROUPS_FAIL";

export const COMMITTEE_GROUP_MAPPING_INIT = "COMMITTEE_GROUP_MAPPING_INIT";
export const COMMITTEE_GROUP_MAPPING_SUCCESS =
  "COMMITTEE_GROUP_MAPPING_SUCCESS";
export const COMMITTEE_GROUP_MAPPING_FAIL = "COMMITTEE_GROUP_MAPPING_FAIL";

export const DELETE_TODO_INIT = "DELETE_TODO_INIT";
export const DELETE_TODO_SUCCESS = "DELETE_TODO_SUCCESS";
export const DELETE_TODO_FAIL = "DELETE_TODO_FAIL";

export const GET_RESOLUTION_BY_RESOLUTION_ID_INIT =
  "GET_RESOLUTION_BY_RESOLUTION_ID_INIT";
export const GET_RESOLUTION_BY_RESOLUTION_ID_SUCCESS =
  "GET_RESOLUTION_BY_RESOLUTION_ID_SUCCESS";
export const GET_RESOLUTION_BY_RESOLUTION_ID_FAIL =
  "GET_RESOLUTION_BY_RESOLUTION_ID_FAIL";

export const GET_RESOLUTION_RESULTS_DETAILS_INIT =
  "GET_RESOLUTION_RESULTS_DETAILS_INIT";
export const GET_RESOLUTION_RESULTS_DETAILS_SUCCESS =
  "GET_RESOLUTION_RESULTS_DETAILS_SUCCESS";
export const GET_RESOLUTION_RESULTS_DETAILS_FAIL =
  "GET_RESOLUTION_RESULTS_DETAILS_FAIL";

export const GET_VOTESDETAILSBYID_INIT = "GET_VOTESDETAILSBYID_INIT";
export const GET_VOTESDETAILSBYID_SUCCESS = "GET_VOTESDETAILSBYID_SUCCESS";
export const GET_VOTESDETAILSBYID_FAIL = "GET_VOTESDETAILSBYID_FAIL";

export const CANCEL_RESOLUTION_INIT = "CANCEL_RESOLUTION_INIT";
export const CANCEL_RESOLUTION_SUCCESS = "CANCEL_RESOLUTION_SUCCESS";
export const CANCEL_RESOLUTION_FAIL = "CANCEL_RESOLUTION_FAIL";

export const CLOSE_RESOLUTION_INIT = "CLOSE_RESOLUTION_INIT";
export const CLOSE_RESOLUTION_SUCCESS = "CLOSE_RESOLUTION_SUCCESS";
export const CLOSE_RESOLUTION_FAIL = "CLOSE_RESOLUTION_FAIL";

export const UPDATE_VOTE_INIT = "UPDATE_VOTE_INIT";
export const UPDATE_VOTE_SUCCESS = "UPDATE_VOTE_SUCCESS";
export const UPDATE_VOTE_FAIL = "UPDATE_VOTE_FAIL";

export const UPDATE_RESOLUTION_BY_RESOLUTION_ID_INIT =
  "UPDATE_RESOLUTION_BY_RESOLUTION_ID_INIT";
export const UPDATE_RESOLUTION_BY_RESOLUTION_ID_SUCCESS =
  "UPDATE_RESOLUTION_BY_RESOLUTION_ID_SUCCESS";
export const UPDATE_RESOLUTION_BY_RESOLUTION_ID_FAIL =
  "UPDATE_RESOLUTION_BY_RESOLUTION_ID_FAIL";

export const CREATE_PRIVATEGROUP_INIT = "CREATE_PRIVATEGROUP_INIT";
export const CREATE_PRIVATEGROUP_NOTIFICATION =
  "CREATE_PRIVATEGROUP_NOTIFICATION";

export const GET_PRIVATEGROUPMEMBERS_INIT = "GET_PRIVATEGROUPMEMBERS_INIT";
export const GET_PRIVATEGROUPMEMBERS_SUCCESS =
  "GET_PRIVATEGROUPMEMBERS_SUCCESS";
export const GET_PRIVATEGROUPMEMBERS_FAIL = "GET_PRIVATEGROUPMEMBERS_FAIL";

export const STAR_UNSTAR_MESSAGE_INIT = "STAR_UNSTAR_MESSAGE_INIT";
export const STAR_UNSTAR_MESSAGE_NOTIFICATION =
  "STAR_UNSTAR_MESSAGE_NOTIFICATION";

export const RECENT_ACTIVITYDATA_MQTT = "RECENT_ACTIVITYDATA_MQTT";

export const RECENT_TODOCOUNTER = "RECENT_TODOCOUNTER";
export const RECENT_MEETINGCOUNTER = "RECENT_MEETINGCOUNTER";
export const UPCOMINGEVENTS_MQTT = "UPCOMINGEVENTS_MQTT";

export const GET_BLLINGINFORMATION_INIT = "GET_BLLINGINFORMATION_INIT";
export const GET_BLLINGINFORMATION_SUCCESS = "GET_BLLINGINFORMATION_SUCCESS";
export const GET_BLLINGINFORMATION_FAIL = "GET_BLLINGINFORMATION_FAIL";

export const PAYOUTSTANDING_INIT = "PAYOUTSTANDING_INIT";
export const PAYOUTSTANDING_SUCCESS = "PAYOUTSTANDING_SUCCESS";
export const PAYOUTSTANDING_FAIL = "PAYOUTSTANDING_FAIL";

export const INVOICEANDPAYMENTHISTORY_INIT = "INVOICEANDPAYMENTHISTORY_INIT";
export const INVOICEANDPAYMENTHISTORY_SUCCESS =
  "INVOICEANDPAYMENTHISTORY_SUCCESS";
export const INVOICEANDPAYMENTHISTORY_FAIL = "INVOICEANDPAYMENTHISTORY_FAIL";

export const GET_ACTIVECHATID = "GET_ACTIVECHATID";
export const GET_ACTIVEMESSAGEID = "GET_ACTIVEMESSAGEID";

export const CLEAR_RESPONSEMESSAGE_RESOLUTION =
  "CLEAR_RESPONSEMESSAGE_RESOLUTION";

export const SAVEFILES_DATAROOM_INIT = "SAVEFILES_DATAROOM_INIT";
export const SAVEFILES_DATAROOM_SUCCESS = "SAVEFILES_DATAROOM_SUCCESS";
export const SAVEFILES_DATAROOM_FAIL = "SAVEFILES_DATAROOM_FAIL";

export const UPLOAD_DOCUMENTS_DATAROOM_INIT = "UPLOAD_DOCUMENTS_DATAROOM_INIT";
export const UPLOAD_DOCUMENTS_DATAROOM_SUCCESS =
  "UPLOAD_DOCUMENTS_DATAROOM_SUCCESS";
export const UPLOAD_DOCUMENTS_DATAROOM_FAIL = "UPLOAD_DOCUMENTS_DATAROOM_FAIL";

export const SAVE_FOLDER_DATAROOM_INIT = "SAVE_FOLDER_DATAROOM_INIT";
export const SAVE_FOLDER_DATAROOM_SUCCESS = "SAVE_FOLDER_DATAROOM_SUCCESS";
export const SAVE_FOLDER_DATAROOM_FAIL = "SAVE_FOLDER_DATAROOM_FAIL";

export const GETMYDOCUMENTS_DATAROOM_INIT = "GETMYDOCUMENTS_DATAROOM_INIT";
export const GETMYDOCUMENTS_DATAROOM_SUCCESS =
  "GETMYDOCUMENTS_DATAROOM_SUCCESS";
export const GETMYDOCUMENTS_DATAROOM_FAIL = "GETMYDOCUMENTS_DATAROOM_FAIL";

export const GET_FOLDER_DOCUMENTS_DATAROOM_INIT =
  "GET_FOLDER_DOCUMENTS_DATAROOM_INIT";
export const GET_FOLDER_DOCUMENTS_DATAROOM_SUCCESS =
  "GET_FOLDER_DOCUMENTS_DATAROOM_SUCCESS";
export const GET_FOLDER_DOCUMENTS_DATAROOM_FAIL =
  "GET_FOLDER_DOCUMENTS_DATAROOM_FAIL";

export const CREATE_FOLDER_DATAROOM_INIT = "CREATE_FOLDER_DATAROOM_INIT";
export const CREATE_FOLDER_DATAROOM_SUCCESS = "CREATE_FOLDER_DATAROOM_SUCCESS";
export const CREATE_FOLDER_DATAROOM_FAIL = "CREATE_FOLDER_DATAROOM_FAIL";

export const GETALLDOCUMENTSANDFOLDER_DATAROOM_INIT =
  "GETALLDOCUMENTSANDFOLDER_DATAROOM_INIT";
export const GETALLDOCUMENTSANDFOLDER_DATAROOM_SUCCESS =
  "GETALLDOCUMENTSANDFOLDER_DATAROOM_SUCCESS";
export const GETALLDOCUMENTSANDFOLDER_DATAROOM_FAIL =
  "GETALLDOCUMENTSANDFOLDER_DATAROOM_FAIL";

export const SHAREFILES_DATAROOM_INIT = "SHAREFILES_DATAROOM_INIT";
export const SHAREFILES_DATAROOM_SUCCESS = "SHAREFILES_DATAROOM_SUCCESS";
export const SHAREFILES_DATAROOM_FAIL = "SHAREFILES_DATAROOM_FAIL";

export const SEARCH_VOTERRESOLUTION_INIT = "SEARCH_VOTERRESOLUTION_INIT";
export const SEARCH_VOTERRESOLUTION_SUCCESS = "SEARCH_VOTERRESOLUTION_SUCCESS";
export const SEARCH_VOTERRESOLUTION_FAIL = "SEARCH_VOTERRESOLUTION_FAIL";

export const SHAREFOLDERS_DATAROOM_INIT = "SHAREFOLDERS_DATAROOM_INIT";
export const SHAREFOLDERS_DATAROOM_SUCCESS = "SHAREFOLDERS_DATAROOM_SUCCESS";
export const SHAREFOLDERS_DATAROOM_FAIL = "SHAREFOLDERS_DATAROOM_FAIL";

export const GET_ALLSHAREDFILESANDFOLDER_DATAROOM_INIT =
  "GET_ALLSHAREDFILESANDFOLDER_DATAROOM_INIT";
export const GET_ALLSHAREDFILESANDFOLDER_DATAROOM_SUCCESS =
  "GET_ALLSHAREDFILESANDFOLDER_DATAROOM_SUCCESS";
export const GET_ALLSHAREDFILESANDFOLDER_DATAROOM_FAIL =
  "GET_ALLSHAREDFILESANDFOLDER_DATAROOM_FAIL";

export const CURRENTRESOLUTIONSTATE = "CURRENTRESOLUTIONSTATE";
export const RESOLUTIONCLOSEDORNOTCLOSED = "RESOLUTIONCLOSEDORNOTCLOSED";

export const MQTT_BLOCK_USER = "MQTT_BLOCK_USER";
export const MQTT_UNBLOCK_USER = "MQTT_UNBLOCK_USER";

export const MQTT_STAR_MESSAGE = "MQTT_STAR_MESSAGE";
export const MQTT_UNSTAR_MESSAGE = "MQTT_UNSTAR_MESSAGE";

export const MQTT_GROUP_CREATED = "MQTT_GROUP_CREATED";

export const MQTT_UNREAD_MESSAGE_COUNT = "MQTT_UNREAD_MESSAGE_COUNT";

export const DELETEFILE_DATAROOM_INIT = "DELETEFILE_DATAROOM_INIT";
export const DELETEFILE_DATAROOM_SUCCESS = "DELETEFILE_DATAROOM_SUCCESS";
export const DELETEFILE_DATAROOM_FAIL = "DELETEFILE_DATAROOM_FAIL";

export const FOLDERISEXIST_INIT = "FOLDERISEXIST_INIT";
export const FOLDERISEXIST_SUCCESS = "FOLDERISEXIST_SUCCESS";
export const FOLDERISEXIST_FAIL = "FOLDERISEXIST_FAIL";

export const FILEISEXIST_INIT = "FILEISEXIST_INIT";
export const FILEISEXIST_SUCCESS = "FILEISEXIST_SUCCESS";
export const FILEISEXIST_FAIL = "FILEISEXIST_FAIL";

export const DELETEFOLDER_DATAROOM_INIT = "DELETEFOLDER_DATAROOM_INIT";
export const DELETEFOLDER_DATAROOM_SUCCESS = "DELETEFOLDER_DATAROOM_SUCCESS";
export const DELETEFOLDER_DATAROOM_FAIL = "DELETEFOLDER_DATAROOM_FAIL";

export const UPLOAD_ATTACHMENTS = "UPLOAD_ATTACHMENTS";

export const UPDATE_PRIVATEGROUP_INIT = "UPDATE_PRIVATEGROUP_INIT";
export const UPDATE_PRIVATEGROUP_NOTIFICATION =
  "UPDATE_PRIVATEGROUP_NOTIFICATION";

export const MQTT_GROUP_UPDATED = "MQTT_GROUP_UPDATED";
export const LEAVE_GROUP_INIT = "LEAVE_GROUP_INIT";
export const LEAVE_GROUP_NOTIFICATION = "LEAVE_GROUP_NOTIFICATION";

export const RESET_LEAVE_GROUP_MESSAGE = "RESET_LEAVE_GROUP_MESSAGE";
export const RESET_GROUP_MODIFY_MESSAGE = "RESET_GROUP_MODIFY_MESSAGE";

export const CREATE_SHOUTALL_INIT = "CREATE_SHOUTALL_INIT";
export const CREATE_SHOUTALL_SUCCESS = "CREATE_SHOUTALL_SUCCESS";
export const CREATE_SHOUTALL_FAIL = "CREATE_SHOUTALL_FAIL";

export const DELETE_SHOUT_INIT = "DELETE_SHOUT_INIT";
export const DELETE_SHOUT_SUCCESS = "DELETE_SHOUT_SUCCESS";
export const DELETE_SHOUT_FAIL = "DELETE_SHOUT_FAIL";

export const RESET_SHOUTALL_CREATED_MESSAGE = "RESET_SHOUTALL_CREATED_MESSAGE";

export const MQTT_INSERT_BROADCAST_MESSAGE = "MQTT_INSERT_BROADCAST_MESSAGE";

export const UPDATE_SHOUTALL_INIT = "UPDATE_SHOUTALL_INIT";
export const UPDATE_SHOUTALL_SUCCESS = "UPDATE_SHOUTALL_SUCCESS";
export const UPDATE_SHOUTALL_FAIL = "UPDATE_SHOUTALL_FAIL";

export const INSERT_BULKMESSAGES_INIT = "INSERT_BULKMESSAGES_INIT";
export const INSERT_BULKMESSAGES_SUCCESS = "INSERT_BULKMESSAGES_SUCCESS";
export const INSERT_BULKMESSAGES_FAIL = "INSERT_BULKMESSAGES_FAIL";

export const OTO_RETRY_FLAG = "OTO_RETRY_FLAG";

export const DOWNLOAD_CHAT_INIT = "DOWNLOAD_CHAT_INIT";
export const DOWNLOAD_CHAT_SUCCESS = "DOWNLOAD_CHAT_SUCCESS";
export const DOWNLOAD_CHAT_FAIL = "DOWNLOAD_CHAT_FAIL";

export const GOOGLEVALIDTOKEN_INIT = "GOOGLEVALIDTOKEN_INIT";
export const GOOGLEVALIDTOKEN_SUCCESS = "GOOGLEVALIDTOKEN_SUCCESS";
export const GOOGLEVALIDTOKEN_FAIL = "GOOGLEVALIDTOKEN_FAIL";

export const REVOKETOKEN_INIT = "REVOKETOKEN_INIT";
export const REVOKETOKEN_SUCCESS = "REVOKETOKEN_SUCCESS";
export const REVOKETOKEN_FAIL = "REVOKETOKEN_FAIL";

export const SEARCH_USER_MEETINGS_INIT = "SEARCH_USER_MEETINGS_INIT";
export const SEARCH_USER_MEETINGS_SUCCESS = "SEARCH_USER_MEETINGS_SUCCESS";
export const SEARCH_USER_MEETINGS_FAIL = "SEARCH_USER_MEETINGS_FAIL";

export const ARCHEIVED_COMMITTES_INIT = "ARCHEIVED_COMMITTES_INIT";
export const ARCHEIVED_COMMITTES_SUCCESS = "ARCHEIVED_COMMITTES_SUCCESS";
export const ARCHEIVED_COMMITTES_FAIL = "ARCHEIVED_COMMITTES_FAIL";

export const ARCHEIVED_GROUPS_INIT = "ARCHEIVED_GROUPS_INIT";
export const ARCHEIVED_GROUPS_SUCCESS = "ARCHEIVED_GROUPS_SUCCESS";
export const ARCHEIVED_GROUPS_FAIL = "ARCHEIVED_GROUPS_FAIL";

export const SEARCH_TODOLIST_INIT = "SEARCH_TODOLIST_INIT";
export const SEARCH_TODOLIST_SUCCESS = "SEARCH_TODOLIST_SUCCESS";
export const SEARCH_TODOLIST_FAIL = "SEARCH_TODOLIST_FAIL";

export const GET_PAYMENT_METHODS_INIT = "GET_PAYMENT_METHODS_INIT";
export const GET_PAYMENT_METHODS_SUCCESS = "GET_PAYMENT_METHODS_SUCCESS";
export const GET_PAYMENT_METHODS_FAIL = "GET_PAYMENT_METHODS_FAIL";

export const SEARCH_PAYMENT_HISTORY_INIT = "SEARCH_PAYMENT_HISTORY_INIT";
export const SEARCH_PAYMENT_HISTORY_SUCCESS = "SEARCH_PAYMENT_HISTORY_SUCCESS";
export const SEARCH_PAYMENT_HISTORY_FAIL = "SEARCH_PAYMENT_HISTORY_FAIL";

export const SEARCH_POLLS_INIT = "SEARCH_POLLS_INIT";
export const SEARCH_POLLS_SUCCESS = "SEARCH_POLLS_SUCCESS";
export const SEARCH_POLLS_FAIL = "SEARCH_POLLS_FAIL";

export const SAVEPOLL_INIT = "SAVEPOLL_INIT";
export const SAVEPOLL_SUCCESS = "SAVEPOLL_SUCCESS";
export const SAVEPOLL_FAIL = "SAVEPOLL_FAIL";

export const GETALLCOMMITESANDGROUPSFORPOLLS_INIT =
  "GETALLCOMMITESANDGROUPSFORPOLLS_INIT";
export const GETALLCOMMITESANDGROUPSFORPOLLS_SUCCESS =
  "GETALLCOMMITESANDGROUPSFORPOLLS_SUCCESS";
export const GETALLCOMMITESANDGROUPSFORPOLLS_FAIL =
  "GETALLCOMMITESANDGROUPSFORPOLLS_FAIL";

export const RENAMEFOLDER_INIT = "RENAMEFOLDER_INIT";
export const RENAMEFOLDER_SUCCESS = "RENAMEFOLDER_SUCCESS";
export const RENAMEFOLDER_FAIL = "RENAMEFOLDER_FAIL";

export const CREATE_POOL_MODAL = "CREATE_POOL_MODAL";
export const LOADDER_STATE = "LOADDER_STATE";
export const EDIT_POLL_MODAL = "EDIT_POLL_MODAL";
export const GLOBAL_FLAG = "GLOBAL_FLAG";

export const CAST_VOTE_INIT = "CAST_VOTE_INIT";
export const CAST_VOTE_SUCCESS = "CAST_VOTE_SUCCESS";
export const CAST_VOTE_FAIL = "CAST_VOTE_FAIL";

export const RENAMEFILE_INIT = "RENAMEFILE_INIT";
export const RENAMEFILE_SUCCESS = "RENAMEFILE_SUCCESS";
export const RENAMEFILE_FAIL = "RENAMEFILE_FAIL";

export const GETSUBSCRIPTIONDETAIL_INIT = "GETSUBSCRIPTIONDETAIL_INIT";
export const GETSUBSCRIPTIONDETAIL_SUCCESS = "GETSUBSCRIPTIONDETAIL_SUCCESS";
export const GETSUBSCRIPTIONDETAIL_FAIL = "GETSUBSCRIPTIONDETAIL_FAIL";

export const GETSUBSCRIPTIONUPGRADEAMOUNTDETAIL_INIT =
  "GETSUBSCRIPTIONUPGRADEAMOUNTDETAIL_INIT";
export const GETSUBSCRIPTIONUPGRADEAMOUNTDETAIL_SUCCESS =
  "GETSUBSCRIPTIONUPGRADEAMOUNTDETAIL_SUCCESS";
export const GETSUBSCRIPTIONUPGRADEAMOUNTDETAIL_FAIL =
  "GETSUBSCRIPTIONUPGRADEAMOUNTDETAIL_FAIL";

export const GETSUBSCRIPTIONUPGRADEPAYMENTCOMPLETE_INIT =
  "GETSUBSCRIPTIONUPGRADEPAYMENTCOMPLETE_INIT";
export const GETSUBSCRIPTIONUPGRADEPAYMENTCOMPLETE_SUCCESS =
  "GETSUBSCRIPTIONUPGRADEPAYMENTCOMPLETE_SUCCESS";
export const GETSUBSCRIPTIONUPGRADEPAYMENTCOMPLETE_FAIL =
  "GETSUBSCRIPTIONUPGRADEPAYMENTCOMPLETE_FAIL";

export const PAYMENTCOMPLETE_INIT = "PAYMENTCOMPLETE_INIT";
export const PAYMENTCOMPLETE_SUCCESS = "PAYMENTCOMPLETE_SUCCESS";
export const PAYMENTCOMPLETE_FAIL = "PAYMENTCOMPLETE_FAIL";

export const GET_ALL_POLL_STATUS_INIT = "GET_ALL_POLL_STATUS_INIT";
export const GET_ALL_POLL_STATUS_SUCCESS = "GET_ALL_POLL_STATUS_SUCCESS";
export const GET_ALL_POLL_STATUS_FAILED = "GET_ALL_POLL_STATUS_FAILED";

export const GET_POLLS_BY_POLLID_INIT = "GET_POLLS_BY_POLLID_INIT";
export const GET_POLLS_BY_POLLID_SUCCESS = "GET_POLLS_BY_POLLID_SUCCESS";
export const GET_POLLS_BY_POLLID_FAILED = "GET_POLLS_BY_POLLID_FAILED";

export const UPDATE_POLLS_INIT = "UPDATE_POLLS_INIT";
export const UPDATE_POLLS_SUCCESS = "UPDATE_POLLS_SUCCESS";
export const UPDATE_POLLS_FAILED = "UPDATE_POLLS_FAILED";

export const VIEW_POLL_MODAL = "VIEW_POLL_MODAL";

export const VOTE_POLL_MODAL = "VOTE_POLL_MODAL";

export const VIEW_POLL_PROGRESS = "VIEW_POLL_PROGRESS";

export const VIEW_VOTES_DETAILS = "VIEW_VOTES_DETAILS";

export const VIEW_VOTES_INIT = "VIEW_VOTES_INIT";
export const VIEW_VOTES_SUCCESS = "VIEW_VOTES_SUCCESS";
export const VIEW_VOTES_FAILED = "VIEW_VOTES_FAILED";

export const DELETE_POLL_MODAL = "DELETE_POLL_MODAL";

//NEW MEETING STATES
export const NEW_MEETING_ADDUSER_MODAL = "NEW_MEETING_ADDUSER_MODAL";
export const CROSS_CONFIRMATION_MODAL = "CROSS_CONFIRMATION_MODAL ";
export const NOTIFY_ORGANIZORS_MODAL = "NOTIFY_ORGANIZORS_MODAL";

export const ADD_AGENDA_CONTRIBUTORS = "ADD_AGENDA_CONTRIBUTORS";
export const NOTIFY_AGENDA_CONTRIBUTORS = "NOTIFY_AGENDA_CONTRIBUTORS";
export const ADD_PARTICIPANTS_MODAL = "ADD_PARTICIPANTS_MODAL";
export const AGENDA_ITEM_REMOVED = "AGENDA_ITEM_REMOVED";
export const MAIN_AGENDA_ITEM_REMOVED = "MAIN_AGENDA_ITEM_REMOVED";
export const ADVANCED_PERSMISSION_MODAL = "ADVANCED_PERSMISSION_MODAL";

export const DELETE_POLL_INIT = "DELETE_POLL_INIT";
export const DELETE_POLL_SUCCESS = "DELETE_POLL_SUCCESS";
export const DELETE_POLL_FAILED = "DELETE_POLL_FAILED";

export const UPDATE_MESSAGE_ACKNOWLEDGEMENT_INIT =
  "UPDATE_MESSAGE_ACKNOWLEDGEMENT_INIT";
export const UPDATE_MESSAGE_ACKNOWLEDGEMENT_SUCCESS =
  "UPDATE_MESSAGE_ACKNOWLEDGEMENT_SUCCESS";
export const UPDATE_MESSAGE_ACKNOWLEDGEMENT_FAIL =
  "UPDATE_MESSAGE_ACKNOWLEDGEMENT_FAIL";

export const GROUP_LOADER_STATE = "GROUP_LOADER_STATE";

//CHAT FILTER ACTION TYPES
export const RECENT_CHAT_FLAG = "RECENT_CHAT_FLAG";
export const PRIVATE_CHAT_FLAG = "PRIVATE_CHAT_FLAG";
export const PRIVATE_GROUPS_CHAT_FLAG = "PRIVATE_GROUPS_CHAT_FLAG";
export const STARRED_MESSAGE_FLAG = "STARRED_MESSAGE_FLAG";
export const BLOCKED_USERS_FLAG = "BLOCKED_USERS_FLAG";
export const SHOUTALL_CHAT_FLAG = "SHOUTALL_CHAT_FLAG";
export const DELETE_CHAT_FLAG = "DELETE_CHAT_FLAG";

//Header Footer
export const HEADER_SHOW_HIDE_STATUS = "HEADER_SHOW_HIDE_STATUS";
export const FOOTER_SHOW_HIDE_STATUS = "FOOTER_SHOW_HIDE_STATUS";
export const FOOTER_ACTION_STATUS = "FOOTER_ACTION_STATUS";

//Security Encryption
export const SECURITY_ENCRYPTION_STATUS = "SECURITY_ENCRYPTION_STATUS";

//Footer Feature Reducers
export const ADD_NEW_CHAT_SCREEN = "ADD_NEW_CHAT_SCREEN";
export const CREATE_GROUP_SCREEN = "CREATE_GROUP_SCREEN";
export const CREATE_SHOUTALL_SCREEN = "CREATE_SHOUTALL_SCREEN";

export const CHATBOX_ACTIVE_FLAG = "CHATBOX_ACTIVE_FLAG";

export const GET_ACTIVE_CHAT = "GET_ACTIVE_CHAT";

export const CHAT_MESSAGE_SEARCH = "CHAT_MESSAGE_SEARCH";

export const SAVE_MODAL_FLAG = "SAVE_MODAL_FLAG";
export const PRINT_MODAL_FLAG = "PRINT_MODAL_FLAG";
export const EMAIL_MODAL_FLAG = "EMAIL_MODAL_FLAG";

export const PUSH_MESSAGE_DATA = "PUSH_MESSAGE_DATA";

export const PUSH_CHAT_DATA = "PUSH_CHAT_DATA";

export const FILE_UPLOAD_FLAG = "FILE_UPLOAD_FLAG";
export const FILE_UPLOAD_DATA = "FILE_UPLOAD_DATA";

export const ACTIVE_MESSAGE_DATA = "ACTIVE_MESSAGE_DATA";

export const GET_ALL_STARRED_MESSAGES_INIT = "GET_ALL_STARRED_MESSAGES_INIT";
export const GET_ALL_STARRED_MESSAGES_SUCCESS =
  "GET_ALL_STARRED_MESSAGES_SUCCESS";
export const GET_ALL_STARRED_MESSAGES_FAIL = "GET_ALL_STARRED_MESSAGES_FAIL";

export const CHATS_SEARCH_FLAG = "CHATS_SEARCH_FLAG";

export const MQTT_MESSAGE_DELETED = "MQTT_MESSAGE_DELETED";
export const DOWNLOAD_CHAT_EMPTY = "DOWNLOAD_CHAT_EMPTY";

export const GET_ALL_MESSAGES_INIT = "GET_ALL_MESSAGES_INIT";
export const GET_ALL_MESSAGES_SUCCESS = "GET_ALL_MESSAGES_SUCCESS";
export const GET_ALL_MESSAGES_FAIL = "GET_ALL_MESSAGES_FAIL";

export const RESET_CLOSE_CHAT_FLAGS = "RESET_CLOSE_CHAT_FLAGS";

export const EMAIL_CHAT_INIT = "EMAIL_CHAT_INIT";
export const EMAIL_CHAT_SUCCESS = "EMAIL_CHAT_SUCCESS";
export const EMAIL_CHAT_FAIL = "EMAIL_CHAT_FAIL";
export const MQTT_MESSAGE_STATUS_UPDATE = "MQTT_MESSAGE_STATUS_UPDATE";
export const ALL_POLLINGS_SOCKET = "ALL_POLLINGS_SOCKET";

export const CREATE_RESOLUTION_MODAL = "CREATE_RESOLUTION_MODAL";
export const UPDATE_RESOLUTION_MODAL = "UPDATE_RESOLUTION_MODAL";
export const VIEW_RESOLUTION_MODAL = "VIEW_RESOLUTION_MODAL";

//Video Action Types
export const CONTACT_VIDEO_FLAG = "CONTACT_VIDEO_FLAG";
export const RECENT_VIDEO_FLAG = "RECENT_VIDEO_FLAG";
export const VIDEO_CHAT_SEARCH_FLAG = "VIDEO_CHAT_SEARCH_FLAG";
export const VIDEO_CHAT_FLAG = "VIDEO_CHAT_FLAG";

export const VIDEO_CALL_OTO_FLAG = "VIDEO_CALL_OTO_FLAG";
export const VIDEO_CALL_GROUP_FLAG = "VIDEO_CALL_GROUP_FLAG";
export const VIDEO_CALL_NORMAL_SCREEN_FLAG = "VIDEO_CALL_NORMAL_SCREEN_FLAG";
export const VIDEO_CALL_MINIMIZE_SCREEN_FLAG =
  "VIDEO_CALL_MINIMIZE_SCREEN_FLAG";
export const VIDEO_CALL_MAXIMIZE_SCREEN_FLAG =
  "VIDEO_CALL_MAXIMIZE_SCREEN_FLAG";
export const END_VIDEO_CALL_FLAG = "END_VIDEO_CALL_FLAG";
export const VIDEO_CALL_NORMAL_HEADER_FLAG = "VIDEO_CALL_NORMAL_HEADER_FLAG";
export const VIDEO_CALL_MINIMIZE_HEADER_FLAG =
  "VIDEO_CALL_MINIMIZE_HEADER_FLAG";
export const VIDEO_CALL_MAXIMIZE_HEADER_FLAG =
  "VIDEO_CALL_MAXIMIZE_HEADER_FLAG";
export const VIDEO_INCOMING_CALL_MAX = "VIDEO_INCOMING_CALL_MAX";
export const CHAT_ENABLE_NORMAL_FLAG = "CHAT_ENABLE_NORMAL_FLAG";
export const AGENDA_ENABLE_NORMAL_FLAG = "AGENDA_ENABLE_NORMAL_FLAG";
export const MINUTES_MEETING_ENABLE_NORMAL_FLAG =
  "MINUTES_MEETING_ENABLE_NORMAL_FLAG";
export const VIDEO_OUTGOING_CALL_MAX = "VIDEO_OUTGOING_CALL_MAX";
export const VIDEO_MULTIPLE_CALL_MAX = "VIDEO_MULTIPLE_CALL_MAX";
export const VIDEO_MAX_CHAT_OPEN = "VIDEO_MAX_CHAT_OPEN";
export const VIDEO_MAX_AGENDA_OPEN = "VIDEO_MAX_AGENDA_OPEN";
export const VIDEO_MAX_MINUTES_OPEN = "VIDEO_MAX_MINUTES_OPEN";
export const MINIMIZE_VIDEO_PANEL = "MINIMIZE_VIDEO_PANEL";
export const NORMALIZE_VIDEO_PANEL = "NORMALIZE_VIDEO_PANEL";
export const MAXIMIZE_VIDEO_PANEL = "MAXIMIZE_VIDEO_PANEL";

export const ADVANCE_PERMISSION_CONFIRMATION =
  "ADVANCE_PERMISSION_CONFIRMATION";

export const CREATE_FOLDER_SUCCESS = "CREATE_FOLDER_SUCCESS";
export const CREATE_FOLDER_EMPTY = "CREATE_FOLDER_EMPTY";

export const SAVEFILESANDFOLDERS_INIT = "SAVEFILESANDFOLDERS_INIT";
export const SAVEFILESANDFOLDERS_SUCCESS = "SAVEFILESANDFOLDERS_SUCCESS";
export const SAVEFILESANDFOLDERS_FAIL = "SAVEFILESANDFOLDERS_FAIL";

export const VOTE_AGENDA_MODAL = "VOTE_AGENDA_MODAL";

export const VOTE_MODAL_CONFIRMATION = "VOTE_MODAL_CONFIRMATION";

export const IMPORT_PREVIOUS_AGENDA = "IMPORT_PREVIOUS_AGENDA";

export const DELETE_TODO_COMMENT_INIT = "DELETE_TODO_COMMENT_INIT";
export const DELETE_TODO_COMMENT_SUCCESS = "DELETE_TODO_COMMENT_SUCCESS";
export const DELETE_TODO_COMMENT_FAIL = "DELETE_TODO_COMMENT_FAIL";

export const DATAROOM_TABLE_SCROLL_BAR = "DATAROOM_TABLE_SCROLL_BAR";

export const INCOMING_VIDEO_FLAG = "INCOMING_VIDEO_FLAG";

export const GET_ALL_LANGUAGES_INITIAL = "GET_ALL_LANGUAGES_INITIAL";
export const GET_ALL_LANGUAGES_SUCCESS = "GET_ALL_LANGUAGES_SUCCESS";
export const GET_ALL_LANGUAGES_FAIL = "GET_ALL_LANGUAGES_FAIL";

export const SET_SELECTED_LANGUAGE_INITIAL = "SET_SELECTED_LANGUAGE_INITIAL";
export const SET_SELECTED_LANGUAGE_SUCCESS = "SET_SELECTED_LANGUAGE_SUCCESS";
export const SET_SELECTED_LANGUAGE_FAIL = "SET_SELECTED_LANGUAGE_FAIL";

//Video Call Action Types
export const GET_ALL_VIDEOCALL_USERS_INITIAL =
  "GET_ALL_VIDEOCALL_USERS_INITIAL";
export const GET_ALL_VIDEOCALL_USERS_SUCCESS =
  "GET_ALL_VIDEOCALL_USERS_SUCCESS";
export const GET_ALL_VIDEOCALL_USERS_FAIL = "GET_ALL_VIDEOCALL_USERS_FAIL";

export const INITIATE_VIDEO_CALL_INITIAL = "INITIATE_VIDEO_CALL_INITIAL";
export const INITIATE_VIDEO_CALL_SUCCESS = "INITIATE_VIDEO_CALL_SUCCESS";
export const INITIATE_VIDEO_CALL_FAIL = "INITIATE_VIDEO_CALL_FAIL";

export const INCOMING_VIDEO_CALL_MQTT = "INCOMING_VIDEO_CALL_MQTT";

export const DATAROOM_DATA_BEHAVIOUR = "DATAROOM_DATA_BEHAVIOUR";

export const IMPORT_PREVIOUS_MINUTES = "IMPORT_PREVIOUS_MINUTES";

export const AFTER_IMPORT_STATE = "AFTER_IMPORT_STATE";

export const NEW_RESOLUTION_CREATED_MQTT = "NEW_RESOLUTION_CREATED_MQTT";

export const CLOSED_RESOLUTION_MQTT = "CLOSED_RESOLUTION_MQTT";

export const CANCELLED_RESOLUTION_MQTT = "CANCELLED_RESOLUTION_MQTT";

export const VIDEO_CALL_RESPONSE_INITIAL = "VIDEO_CALL_RESPONSE_INITIAL";
export const VIDEO_CALL_RESPONSE_SUCCESS = "VIDEO_CALL_RESPONSE_SUCCESS";
export const VIDEO_CALL_RESPONSE_FAIL = "VIDEO_CALL_RESPONSE_FAIL";

export const GET_VIDEO_RECIPENT_DATA = "GET_VIDEO_RECIPENT_DATA";

export const INCOMING_VIDEO_CALL_ACCEPTED_MQTT =
  "INCOMING_VIDEO_CALL_ACCEPTED_MQTT";

export const GET_SELECTED_LANGUAGE_INITIAL = "GET_SELECTED_LANGUAGE_INITIAL";
export const GET_SELECTED_LANGUAGE_SUCCESS = "GET_SELECTED_LANGUAGE_SUCCESS";
export const GET_SELECTED_LANGUAGE_FAIL = "GET_SELECTED_LANGUAGE_FAIL";

export const ENABLE_PROPOSED_MEETING_DATE = "ENABLE_PROPOSED_MEETING_DATE";

export const PROPOSED_MEETING_SAVED_MODAL = "PROPOSED_MEETING_SAVED_MODAL";

export const GET_ALL_RECENTCALLS_INITIAL = "GET_ALL_RECENTCALLS_INITIAL";
export const GET_ALL_RECENTCALLS_SUCCESS = "GET_ALL_RECENTCALLS_SUCCESS";
export const GET_ALL_RECENTCALLS_FAIL = "GET_ALL_RECENTCALLS_FAIL";

export const UNSAVE_MINUTES_FILEUPLOAD = "UNSAVE_MINUTES_FILEUPLOAD";
export const UPDATE_PROFILE_PICTURE_INIT = "UPDATE_PROFILE_PICTURE_INIT";
export const UPDATE_PROFILE_PICTURE_SUCCESS = "UPDATE_PROFILE_PICTURE_SUCCESS";
export const UPDATE_PROFILE_PICTURE_FAIL = "UPDATE_PROFILE_PICTURE_FAIL";

export const ISFOLDER = "ISFOLDER";
export const FOLDER_UPLOAD_DATA = "FOLDER_UPLOAD_DATA";

export const CALL_REQUEST_RECEIVED_INITIAL = "CALL_REQUEST_RECEIVED_INITIAL";
export const CALL_REQUEST_RECEIVED_SUCCESS = "CALL_REQUEST_RECEIVED_SUCCESS";
export const CALL_REQUEST_RECEIVED_FAIL = "CALL_REQUEST_RECEIVED_FAIL";
export const CALL_REQUEST_RECEIVED_MQTT = "CALL_REQUEST_RECEIVED_MQTT";

export const UNSAVED_CREATE_FROM_SCRATCH = "UNSAVED_CREATE_FROM_SCRATCH";
export const UNSAVED_BUTTON_CREATE_SCRATCH = "UNSAVED_BUTTON_CREATE_SCRATCH";
export const UNSAVED_IMPORT_AGENDA = "UNSAVED_IMPORT_AGENDA";
export const GET_MISSED_CALL_COUNT_INITIAL = "GET_MISSED_CALL_COUNT_INITIAL";
export const GET_MISSED_CALL_COUNT_SUCCESS = "GET_MISSED_CALL_COUNT_SUCCESS";
export const GET_MISSED_CALL_COUNT_FAIL = "GET_MISSED_CALL_COUNT_FAIL";

export const SCROLL_BEHAVIOR_SPINNER = "SCROLL_BEHAVIOR_SPINNER";

export const SEARCHDOCUMENTSANDFOLDERSAPI_DATAROOM_INIT =
  "SEARCHDOCUMENTSANDFOLDERSAPI_DATAROOM_INIT";
export const SEARCHDOCUMENTSANDFOLDERSAPI_DATAROOM_SUCCESS =
  "SEARCHDOCUMENTSANDFOLDERSAPI_DATAROOM_SUCCESS";
export const SEARCHDOCUMENTSANDFOLDERSAPI_DATAROOM_FAIL =
  "SEARCHDOCUMENTSANDFOLDERSAPI_DATAROOM_FAIL";

export const ISFILEISEXIST = "ISFILEISEXIST";

export const LEAVE_CALL_MODAL = "LEAVE_CALL_MODAL";

export const LEAVE_CALL_ACTION = "LEAVE_CALL_ACTION";

export const MISSED_CALL_COUNT_MQTT = "MISSED_CALL_COUNT_MQTT";

export const REMOVE_FOLDER_UPLOAD_DATA = "REMOVE_FOLDER_UPLOAD_DATA";

export const UNSAVED_MODAL_ACTIONS = "UNSAVED_MODAL_ACTIONS";

export const REMOVED_TABLE_MODAL = "REMOVED_TABLE_MODALS";
export const UNSAVED_POLLS_MEETING = "UNSAVED_POLLS_MEETING";
export const DELETE_POLL_MEETING = "DELETE_POLL_MEETING";
export const EDIT_FLOW_DELETE_POLL_MEETING = "EDIT_FLOW_DELETE_POLL_MEETING";

export const DELETE_TODO_COMMENT_ID = "DELETE_TODO_COMMENT_ID";

export const UNSAVED_EDIT_POLL_MEETING = "UNSAVED_EDIT_POLL_MEETING";

export const PARTICIPANT_POPUP_FLAG = "PARTICIPANT_POPUP_FLAG";

// for annotations
export const GETANNOTATIONSOFTODOATTACHEMENT_FAIL =
  "GETANNOTATIONSOFTODOATTACHEMENT_FAIL";
export const GETANNOTATIONSOFTODOATTACHEMENT_INIT =
  "GETANNOTATIONSOFTODOATTACHEMENT_INIT";
export const GETANNOTATIONSOFTODOATTACHEMENT_SUCCESS =
  "GETANNOTATIONSOFTODOATTACHEMENT_SUCCESS";
export const GETANNOTATIONSOFTODOATTACHEMENT_MESSAGE_CLEARE =
  "GETANNOTATIONSOFTODOATTACHEMENT_MESSAGE_CLEARE";

export const ADDANNOTATIONSONTODOATTACHEMENT_INIT =
  "ADDANNOTATIONSONTODOATTACHEMENT_INIT";
export const ADDANNOTATIONSONTODOATTACHEMENT_SUCCESS =
  "ADDANNOTATIONSONTODOATTACHEMENT_SUCCESS";
export const ADDANNOTATIONSONTODOATTACHEMENT_FAIL =
  "ADDANNOTATIONSONTODOATTACHEMENT_FAIL";

export const GETEVENTSTYPES_INIT = "GETEVENTSTYPES_INIT";
export const GETEVENTSTYPES_SUCCESS = "GETEVENTSTYPES_SUCCESS";
export const GETEVENTSTYPES_FAIL = "GETEVENTSTYPES_FAIL";

export const GETEVENTSDETAILS_INIT = "GETEVENTSDETAILS_INIT";
export const GETEVENTSDETAILS_SUCCESS = "GETEVENTSDETAILS_SUCCESS";
export const GETEVENTSDETAILS_FAIL = "GETEVENTSDETAILS_FAIL";

export const LOADER_CREATEMEETING_DASHBOARD = "LOADER_CREATEMEETING_DASHBOARD";

export const MINIMIZE_PARTICIPANT_POPUP_FLAG =
  "MINIMIZE_PARTICIPANT_POPUP_FLAG";

export const VIDEO_CHAT_MESSAGES_FLAG = "VIDEO_CHAT_MESSAGES_FLAG";

export const DELETE_MEETING_MODAL = "DELETE_MEETING_MODAL";

export const GETANNOTATIONSOFNOTESATTACHEMENT_INIT =
  "GETANNOTATIONSOFNOTESATTACHEMENT_INIT";
export const GETANNOTATIONSOFNOTESATTACHEMENT_SUCCESS =
  "GETANNOTATIONSOFNOTESATTACHEMENT_SUCCESS";
export const GETANNOTATIONSOFNOTESATTACHEMENT_FAIL =
  "GETANNOTATIONSOFNOTESATTACHEMENT_FAIL";

export const ADDANNOTATIONSOFNOTESATTACHEMENT_INIT =
  "ADDANNOTATIONSOFNOTESATTACHEMENT_INIT";
export const ADDANNOTATIONSOFNOTESATTACHEMENT_SUCCESS =
  "ADDANNOTATIONSOFNOTESATTACHEMENT_SUCCESS";
export const ADDANNOTATIONSOFNOTESATTACHEMENT_FAIL =
  "ADDANNOTATIONSOFNOTESATTACHEMENT_FAIL";

export const GETANNOTATIONSOFRESOLUTIONATTACHEMENT_INIT =
  "GETANNOTATIONSOFRESOLUTIONATTACHEMENT_INIT";
export const GETANNOTATIONSOFRESOLUTIONATTACHEMENT_SUCCESS =
  "GETANNOTATIONSOFRESOLUTIONATTACHEMENT_SUCCESS";
export const GETANNOTATIONSOFRESOLUTIONATTACHEMENT_FAIL =
  "GETANNOTATIONSOFRESOLUTIONATTACHEMENT_FAIL";

export const ADDANNOTATIONSOFRESOLUTIONATTACHEMENT_INIT =
  "ADDANNOTATIONSOFRESOLUTIONATTACHEMENT_INIT";
export const ADDANNOTATIONSOFRESOLUTIONATTACHEMENT_SUCCESS =
  "ADDANNOTATIONSOFRESOLUTIONATTACHEMENT_SUCCESS";
export const ADDANNOTATIONSOFRESOLUTIONATTACHEMENT_FAIL =
  "ADDANNOTATIONSOFRESOLUTIONATTACHEMENT_FAIL";

export const GETANNOTATIONSOFDATAROOMATTACHEMENT_INIT =
  "GETANNOTATIONSOFDATAROOMATTACHEMENT_INIT";
export const GETANNOTATIONSOFDATAROOMATTACHEMENT_SUCCESS =
  "GETANNOTATIONSOFDATAROOMATTACHEMENT_SUCCESS";
export const GETANNOTATIONSOFDATAROOMATTACHEMENT_FAIL =
  "GETANNOTATIONSOFDATAROOMATTACHEMENT_FAIL";

export const ADDANNOTATIONSOFDATAROOMATTACHEMENT_INIT =
  "ADDANNOTATIONSOFDATAROOMATTACHEMENT_INIT";
export const ADDANNOTATIONSOFDATAROOMATTACHEMENT_SUCCESS =
  "ADDANNOTATIONSOFDATAROOMATTACHEMENT_SUCCESS";
export const ADDANNOTATIONSOFDATAROOMATTACHEMENT_FAIL =
  "ADDANNOTATIONSOFDATAROOMATTACHEMENT_FAIL";

export const CLEAR_RESPONSE_MESSAGE_WEBVIEWER =
  "CLEAR_RESPONSE_MESSAGE_WEBVIEWER";

export const VIEW_VOTE_AGENDA = "VIEW_VOTE_AGENDA";
export const CAST_VOTE_AGENDA = "CAST_VOTE_AGENDA";
export const END_MEETING_MODAL = "END_MEETING_MODAL";
export const END_MEETING_FOR_ALL = "END_MEETING_FOR_ALL";
export const SCEDULE_PROPOSED_MEETING = "SCEDULE_PROPOSED_MEETING";

export const MQTT_GROUP_LEFT = "MQTT_GROUP_LEFT";

export const DELETE_MULTIPLE_MESSAGES_INIT = "DELETE_MULTIPLE_MESSAGES_INIT";
export const DELETE_MULTIPLE_MESSAGES_SUCCESS =
  "DELETE_MULTIPLE_MESSAGES_SUCCESS";
export const DELETE_MULTIPLE_MESSAGES_FAIL = "DELETE_MULTIPLE_MESSAGES_FAIL";

export const GETALLCOMMITTEESUSERSANDGROUPS_INIT =
  "GETALLCOMMITTEESUSERSANDGROUPS_INIT";
export const GETALLCOMMITTEESUSERSANDGROUPS_SUCCESS =
  "GETALLCOMMITTEESUSERSANDGROUPS_SUCCESS";
export const GETALLCOMMITTEESUSERSANDGROUPS_FAIL =
  "GETALLCOMMITTEESUSERSANDGROUPS_FAIL";

export const SAVE_MEETINGORGANIZERS_INIT = "SAVE_MEETINGORGANIZERS_INIT";
export const SAVE_MEETINGORGANIZERS_SUCCESS = "SAVE_MEETINGORGANIZERS_SUCCESS";
export const SAVE_MEETINGORGANIZERS_FAIL = "SAVE_MEETINGORGANIZERS_FAIL";

export const GET_MEETING_ORGANIZERS = "GET_MEETING_ORGANIZERS";
export const SELECTED_MEETING_ORGANIZERS = "SELECTED_MEETING_ORGANIZERS";

export const CLEAR_RESPONSEMESSAGE_MO = "CLEAR_RESPONSEMESSAGE_MO";
export const GET_RECENT_DOCUMENTS_INIT = "GET_RECENT_DOCUMENTS_INIT";
export const GET_RECENT_DOCUMENTS_SUCCESS = "GET_RECENT_DOCUMENTS_SUCCESS";
export const GET_RECENT_DOCUMENTS_FAIL = "GET_RECENT_DOCUMENTS_FAIL";

export const GET_ALL_MEETING_TYPES_NEW_INIT = "GET_ALL_MEETING_TYPES_NEW_INIT";
export const GET_ALL_MEETING_TYPES_NEW_SUCCESS =
  "GET_ALL_MEETING_TYPES_NEW_SUCCESS";
export const GET_ALL_MEETING_TYPES_NEW_FAILED =
  "GET_ALL_MEETING_TYPES_NEW_FAILED";

export const SAVE_MEETING_DETAILS_INIT = "SAVE_MEETING_DETAILS_INIT";
export const SAVE_MEETING_DETAILS_SUCCESS = "SAVE_MEETING_DETAILS_SUCCESS";
export const SAVE_MEETING_DETAILS_FAILED = "SAVE_MEETING_DETAILS_FAILED";

export const GET_ALL_REMINDER_FREQUENCY_INIT =
  "GET_ALL_REMINDER_FREQUENCY_INIT";
export const GET_ALL_REMINDER_FREQUENCY_SUCCESS =
  "GET_ALL_REMINDER_FREQUENCY_SUCCESS";
export const GET_ALL_REMINDER_FREQUENCY_FAILED =
  "GET_ALL_REMINDER_FREQUENCY_FAILED";

export const GET_ALL_RECURRING_INIT = "GET_ALL_RECURRING_INIT";
export const GET_ALL_RECURRING_SUCCESS = "GET_ALL_RECURRING_SUCCESS";
export const GET_ALL_RECURRING_FAILED = "GET_ALL_RECURRING_FAILED";
export const GET_SEARCH_NEW_MEETINGS_INIT = "GET_SEARCH_NEW_MEETINGS_INIT";
export const GET_SEARCH_NEW_MEETINGS_SUCCESS =
  "GET_SEARCH_NEW_MEETINGS_SUCCESS";
export const GET_SEARCH_NEW_MEETINGS_FAIL = "GET_SEARCH_NEW_MEETINGS_FAIL";

export const CLEAR_NEWMEETINGSTATE = "CLEAR_NEWMEETINGSTATE";

export const UPDATE_ORGANIZERSMEETING_INIT = "UPDATE_ORGANIZERSMEETING_INIT";
export const UPDATE_ORGANIZERSMEETING_SUCCESS =
  "UPDATE_ORGANIZERSMEETING_SUCCESS";
export const UPDATE_ORGANIZERSMEETING_FAIL = "UPDATE_ORGANIZERSMEETING_FAIL";

export const CANCEL_BUTTON_MODAL_MEETING_DETIALS_TAB =
  "CANCEL_BUTTON_MODAL_MEETING_DETIALS_TAB";

export const CANCEL_BUTTON_MODAL_ORGANIZER = "CANCEL_BUTTON_MODAL_ORGANIZER";

export const CANCEL_AGENDA_CONTRIBUTOR = "CANCEL_AGENDA_CONTRIBUTOR";

export const CANCEL_PARTICIPANTS = "CANCEL_PARTICIPANTS";

export const CANCEL_AGENDA = "CANCEL_AGENDA";

export const CANCEL_MEETING_MATERIAL = "CANCEL_MEETING_MATERIAL";
export const CANCEL_ACTIONS = "CANCEL_ACTIONS";
export const CANCEL_POLLS = "CANCEL_POLLS";

//Participants Integration

export const ADD_MORE_PARTICIPANTS_MODAL_INIT =
  "ADD_MORE_PARTICIPANTS_MODAL_INIT";
export const ADD_MORE_PARTICIPANTS_MODAL_SUCCESS =
  "ADD_MORE_PARTICIPANTS_MODAL_SUCCESS";
export const ADD_MORE_PARTICIPANTS_MODAL_FAILED =
  "ADD_MORE_PARTICIPANTS_MODAL_FAILED";

export const UPLOAD_DOCUMENT_FROM_DASHBOARD = "UPLOAD_DOCUMENT_FROM_DASHBOARD";

export const GET_ALL_ROLES_PARTICIPANTS_INIT =
  "GET_ALL_ROLES_PARTICIPANTS_INIT";

export const GET_ALL_ROLES_PARTICIPANTS_SUCCESS =
  "GET_ALL_ROLES_PARTICIPANTS_SUCCESS";

export const GET_ALL_ROLES_PARTICIPANTS_FAILED =
  "GET_ALL_ROLES_PARTICIPANTS_FAILED";

export const SAVE_AGENDACONTRIBUTORS_INIT = "SAVE_AGENDACONTRIBUTORS_INIT";
export const SAVE_AGENDACONTRIBUTORS_SUCCESS =
  "SAVE_AGENDACONTRIBUTORS_SUCCESS";
export const SAVE_AGENDACONTRIBUTORS_FAIL = "SAVE_AGENDACONTRIBUTORS_FAIL";
export const GET_MEETING_URL_INIT = "GET_MEETING_URL_INIT";
export const GET_MEETING_URL_SUCCESS = "GET_MEETING_URL_SUCCESS";
export const GET_MEETING_URL_FAILED = "GET_MEETING_URL_FAILED";

export const GET_MEETING_URL_SPINNER = "GET_MEETING_URL_SPINNER";

export const SAVE_MEETING_PARTICIPANTS_INIT = "SAVE_MEETING_PARTICIPANTS_INIT";
export const SAVE_MEETING_PARTICIPANTS_SUCCESS =
  "SAVE_MEETING_PARTICIPANTS_SUCCESS";
export const SAVE_MEETING_PARTICIPANTS_FAILED =
  "SAVE_MEETING_PARTICIPANTS_FAILED";

export const GET_ALL_AGENDACONTRIBUTOR_INIT = "GET_ALL_AGENDACONTRIBUTOR_INIT";
export const GET_ALL_AGENDACONTRIBUTOR_SUCCESS =
  "GET_ALL_AGENDACONTRIBUTOR_SUCCESS";
export const GET_ALL_AGENDACONTRIBUTOR_FAIL = "GET_ALL_AGENDACONTRIBUTOR_FAIL";

export const GET_ALL_SAVED_PARTICIPATNS_INIT =
  "GET_ALL_SAVED_PARTICIPATNS_INIT";
export const GET_ALL_SAVED_PARTICIPATNS_SUCCESS =
  "GET_ALL_SAVED_PARTICIPATNS_SUCCESS";
export const GET_ALL_SAVED_PARTICIPATNS_FAILED =
  "GET_ALL_SAVED_PARTICIPATNS_FAILED";

export const GETALLMEETINGORGANIZERS_INIT = "GETALLMEETINGORGANIZERS_INIT";
export const GETALLMEETINGORGANIZERS_SUCCESS =
  "GETALLMEETINGORGANIZERS_SUCCESS";
export const GETALLMEETINGORGANIZERS_FAIL = "GETALLMEETINGORGANIZERS_FAIL";

export const GETUSERSAGAINSTSHAREDFILE_INIT = "GETUSERSAGAINSTSHAREDFILE_INIT";
export const GETUSERSAGAINSTSHAREDFILE_SUCCESS =
  "GETUSERSAGAINSTSHAREDFILE_SUCCESS";
export const GETUSERSAGAINSTSHAREDFILE_FAIL = "GETUSERSAGAINSTSHAREDFILE_FAIL";

export const GETUSERSAGAINSTSHAREDFOLDER_INIT =
  "GETUSERSAGAINSTSHAREDFOLDER_INIT";
export const GETUSERSAGAINSTSHAREDFOLDER_SUCCESS =
  "GETUSERSAGAINSTSHAREDFOLDER_SUCCESS";
export const GETUSERSAGAINSTSHAREDFOLDER_FAIL =
  "GETUSERSAGAINSTSHAREDFOLDER_FAIL";

export const CREATEFILELINK_INIT = "CREATEFILELINK_INIT";
export const CREATEFILELINK_SUCCESS = "CREATEFILELINK_SUCCESS";
export const CREATEFILELINK_FAIL = "CREATEFILELINK_FAIL";

export const CREATEFOLDERLINK_INIT = "CREATEFOLDERLINK_INIT";
export const CREATEFOLDERLINK_SUCCESS = "CREATEFOLDERLINK_SUCCESS";
export const CREATEFOLDERLINK_FAIL = "CREATEFOLDERLINK_FAIL";

export const CHECKLINKFILE_INIT = "CHECKLINKFILE_INIT";
export const CHECKLINKFILE_SUCCESS = "CHECKLINKFILE_SUCCESS";
export const CHECKLINKFILE_FAIL = "CHECKLINKFILE_FAIL";

export const REQUESTACCESS_INIT = "REQUESTACCESS_INIT";
export const REQUESTACCESS_SUCCESS = "REQUESTACCESS_SUCCESS";
export const REQUESTACCESS_FAIL = "REQUESTACCESS_FAIL";

export const UPDATEGENERALACCESS_INIT = "UPDATEGENERALACCESS_INIT";
export const UPDATEGENERALACCESS_SUCCESS = "UPDATEGENERALACCESS_SUCCESS";
export const UPDATEGENERALACCESS_FAIL = "UPDATEGENERALACCESS_FAIL";

export const UPDATEFOLDERGENERALACCESS_INIT = "UPDATEFOLDERGENERALACCESS_INIT";
export const UPDATEFOLDERGENERALACCESS_SUCCESS =
  "UPDATEFOLDERGENERALACCESS_SUCCESS";
export const UPDATEFOLDERGENERALACCESS_FAIL = "UPDATEFOLDERGENERALACCESS_FAIL";
export const SEND_NOTIFICATION_INIT = "SEND_NOTIFICATION_INIT";
export const SEND_NOTIFICATION_SUCCESS = "SEND_NOTIFICATION_SUCCESS";
export const SEND_NOTIFICATION_FAILED = "SEND_NOTIFICATION_FAILED";

export const GET_ALL_MEETING_DETAILS_BY_MEETINGID_INIT =
  "GET_ALL_MEETING_DETAILS_BY_MEETINGID_INIT";

export const GET_ALL_MEETING_DETAILS_BY_MEETINGID_SUCCESS =
  "GET_ALL_MEETING_DETAILS_BY_MEETINGID_SUCCESS";

export const GET_ALL_MEETING_DETAILS_BY_MEETINGID_FAILED =
  "GET_ALL_MEETING_DETAILS_BY_MEETINGID_FAILED";

export const SEND_NOTIFICATION_ORGANIZORS_MODAL =
  "SEND_NOTIFICATION_ORGANIZORS_MODAL";

export const SAVE_MEETING_FLAG = "SAVE_MEETING_FLAG";

export const EDIT_MEETING_FLAG = "EDIT_MEETING_FLAG";

export const NOTIFICATION_SEND_DATA = "NOTIFICATION_SEND_DATA";
export const CLEAR_MEETING_DETAILS = "CLEAR_MEETING_DETAILS";

export const GET_POLLS_BY_MEETING_ID_INIT = "GET_POLLS_BY_MEETING_ID_INIT";
export const GET_POLLS_BY_MEETING_ID_SUCCESS =
  "GET_POLLS_BY_MEETING_ID_SUCCESS";
export const GET_POLLS_BY_MEETING_ID_FAILED = "GET_POLLS_BY_MEETING_ID_FAILED";

export const SEND_NOTIFICATION_ORGANIZER_INIT =
  "SEND_NOTIFICATION_ORGANIZER_INIT";
export const SEND_NOTIFICATION_ORGANIZER_SUCCESS =
  "SEND_NOTIFICATION_ORGANIZER_SUCCESS";
export const SEND_NOTIFICATION_ORGANIZER_FAIL =
  "SEND_NOTIFICATION_ORGANIZER_FAIL";

export const NOTIFICATION_UPDATE_DATA = "NOTIFICATION_UPDATE_DATA";
export const GET_ALL_MEETING_USER_INIT = "GET_ALL_MEETING_USER_INIT";
export const GET_ALL_MEETING_USER_SUCCESS = "GET_ALL_MEETING_USER_SUCCESS";
export const GET_ALL_MEETING_USER_FAILED = "GET_ALL_MEETING_USER_FAILED";

export const DATAROOM_CLEAR_MESSAGE = "DATAROOM_CLEAR_MESSAGE";
export const SET_MEETING_POLLS_INIT = "SET_MEETING_POLLS_INIT";
export const SET_MEETING_POLLS_SUCCESS = "SET_MEETING_POLLS_SUCCESS";
export const SET_MEETING_POLLS_FAILED = "SET_MEETING_POLLS_FAILED";

export const SET_MEETING_PROPOSED_DATE_INIT = "SET_MEETING_PROPOSED_DATE_INIT";
export const SET_MEETING_PROPOSED_DATE_SUCCESS =
  "SET_MEETING_PROPOSED_DATE_SUCCESS";
export const SET_MEETING_PROPOSED_DATE_FAILED =
  "SET_MEETING_PROPOSED_DATE_FAILED";

//Aun get All Attendace meeting
export const GET_ALL_ATTENDANCE_MEETING_INIT =
  "GET_ALL_ATTENDANCE_MEETING_INIT";
export const GET_ALL_ATTENDANCE_MEETING_SUCCESS =
  "GET_ALL_ATTENDANCE_MEETING_SUCCESS";
export const GET_ALL_ATTENDANCE_MEETING_FAIL =
  "GET_ALL_ATTENDANCE_MEETING_FAIL";

//Aun save Meeting Attendance
export const SAVE_MEETING_ATTENDANCE_INIT = "SAVE_MEETING_ATTENDANCE_INIT";
export const SAVE_MEETING_ATTENDANCE_SUCCESS =
  "SAVE_MEETING_ATTENDANCE_SUCCESS";
export const SAVE_MEETING_ATTENDANCE_FAIL = "SAVE_MEETING_ATTENDANCE_FAIL";

// Aun Report For Meeting Attendance Download
export const DOWNLOAD_ATTENDANCE_REPORT_INIT =
  "DOWNLOAD_ATTENDANCE_REPORT_INIT";
export const ACTIVE_CHAT_BOX_GS = "ACTIVE_CHAT_BOX_GS";

export const LAST_MESSAGE_DELETION = "LAST_MESSAGE_DELETION";
export const GET_ALL_PRPOSED_DATES_INIT = "GET_ALL_PRPOSED_DATES_INIT";
export const GET_ALL_PRPOSED_DATES_SUCCESS = "GET_ALL_PRPOSED_DATES_SUCCESS";
export const GET_ALL_PRPOSED_DATES_FAILED = "GET_ALL_PRPOSED_DATES_FAILED";
export const CLEARE_ALL_PROPOSED_MEETING_DATES =
  "CLEARE_ALL_PROPOSED_MEETING_DATES";
export const UPDATERESOLUTIONDATAROOMMAP_INIT =
  "UPDATERESOLUTIONDATAROOMMAP_INIT";
export const UPDATERESOLUTIONDATAROOMMAP_SUCCESS =
  "UPDATERESOLUTIONDATAROOMMAP_SUCCESS";
export const UPDATERESOLUTIONDATAROOMMAP_FAIL =
  "UPDATERESOLUTIONDATAROOMMAP_FAIL";

export const SAVERESOLUTIONDOCUMENTS_INIT = "SAVERESOLUTIONDOCUMENTS_INIT";
export const SAVERESOLUTIONDOCUMENTS_SUCCESS =
  "SAVERESOLUTIONDOCUMENTS_SUCCESS";

export const SAVERESOLUTIONDOCUMENTS_FAIL = "SAVERESOLUTIONDOCUMENTS_FAIL";
export const SET_MEETING_RESPONSE_INIT = "SET_MEETING_RESPONSE_INIT";
export const SET_MEETING_RESPONSE_SUCCESS = "SET_MEETING_RESPONSE_SUCCESS";
export const SET_MEETING_RESPONSE_FAILED = "SET_MEETING_RESPONSE_FAILED";

export const GET_AGENDAVOTINGDETAILS_INIT = "GET_AGENDAVOTINGDETAILS_INIT";
export const GET_AGENDAVOTINGDETAILS_SUCCESS =
  "GET_AGENDAVOTINGDETAILS_SUCCESS";
export const GET_AGENDAVOTINGDETAILS_FAIL = "GET_AGENDAVOTINGDETAILS_FAIL";

export const GET_ALLVOTINGRESULTDISPLAY_INIT =
  "GET_ALLVOTINGRESULTDISPLAY_INIT";
export const GET_ALLVOTINGRESULTDISPLAY_SUCCESS =
  "GET_ALLVOTINGRESULTDISPLAY_SUCCESS";
export const GET_ALLVOTINGRESULTDISPLAY_FAIL =
  "GET_ALLVOTINGRESULTDISPLAY_FAIL";

export const SAVE_AGENDA_VOTING_INIT = "SAVE_AGENDA_VOTING_INIT";
export const SAVE_AGENDA_VOTING_SUCCESS = "SAVE_AGENDA_VOTING_SUCCESS";
export const SAVE_AGENDA_VOTING_FAIL = "SAVE_AGENDA_VOTING_FAIL";

export const CLEAR_RESPONSEMESSAGE_AGENDAMEETING =
  "CLEAR_RESPONSEMESSAGE_AGENDAMEETING";
export const CREATEUPDATECOMMITTEEDATAROOM_INIT =
  "CREATEUPDATECOMMITTEEDATAROOM_INIT";
export const CREATEUPDATECOMMITTEEDATAROOM_SUCCESS =
  "CREATEUPDATECOMMITTEEDATAROOM_SUCCESS";
export const CREATEUPDATECOMMITTEEDATAROOM_FAIL =
  "CREATEUPDATECOMMITTEEDATAROOM_FAIL";

export const SAVECOMMMITTEEDOCUMENTS_INIT = "SAVECOMMMITTEEDOCUMENTS_INIT";
export const SAVECOMMMITTEEDOCUMENTS_SUCCESS =
  "SAVECOMMMITTEEDOCUMENTS_SUCCESS";
export const SAVECOMMMITTEEDOCUMENTS_FAIL = "SAVECOMMMITTEEDOCUMENTS_FAIL";

export const RETERIVECOMMITTEEDOCUMENTS_INIT =
  "RETERIVECOMMITTEEDOCUMENTS_INIT";
export const RETERIVECOMMITTEEDOCUMENTS_SUCCESS =
  "RETERIVECOMMITTEEDOCUMENTS_SUCCESS";
export const RETERIVECOMMITTEEDOCUMENTS_FAIL =
  "RETERIVECOMMITTEEDOCUMENTS_FAIL";

export const COMMIITTEEID = "COMMIITTEEID";

//Aun work Aun meeting Material
export const GET_ALL_MEETING_MATERIAL_INIT = "GET_ALL_MEETING_MATERIAL_INIT";
export const GET_ALL_MEETING_MATERIAL_SUCCESS =
  "GET_ALL_MEETING_MATERIAL_SUCCESS";
export const GET_ALL_MEETING_MATERIAL_FAIL = "GET_ALL_MEETING_MATERIAL_FAIL";

export const CREAT_UPDATE_GROUP_ROADMAP_INIT =
  "CREAT_UPDATE_GROUP_ROADMAP_INIT";
export const CREAT_UPDATE_GROUP_ROADMAP_SUCCESS =
  "CREAT_UPDATE_GROUP_ROADMAP_SUCCESS";
export const CREAT_UPDATE_GROUP_ROADMAP_FAILED =
  "CREAT_UPDATE_GROUP_ROADMAP_FAILED";

export const SAVE_GROUPS_DOCUMENTS_INIT = "SAVE_GROUPS_DOCUMENTS_INIT";
export const SAVE_GROUPS_DOCUMENTS_SUCCESS = "SAVE_GROUPS_DOCUMENTS_SUCCESS";
export const SAVE_GROUPS_DOCUMENTS_FAILED = "SAVE_GROUPS_DOCUMENTS_FAILED";

export const RETREIVE_GROUP_DOCUMENTS_INIT = "RETREIVE_GROUP_DOCUMENTS_INIT";
export const RETREIVE_GROUP_DOCUMENTS_SUCCESS =
  "RETREIVE_GROUP_DOCUMENTS_SUCCESS";
export const RETREIVE_GROUP_DOCUMENTS_FAILED =
  "RETREIVE_GROUP_DOCUMENTS_FAILED";

export const UPDATE_MEETING_AGENDA_LOCK_STATUS_INIT =
  "UPDATE_MEETING_AGENDA_LOCK_STATUS_INIT";
export const UPDATE_MEETING_AGENDA_LOCK_STATUS_SUCCESS =
  "UPDATE_MEETING_AGENDA_LOCK_STATUS_SUCCESS";
export const UPDATE_MEETING_AGENDA_LOCK_STATUS_FAILED =
  "UPDATE_MEETING_AGENDA_LOCK_STATUS_FAILED";

export const GET_ALL_AGENDA_RIGHTS_INIT = "GET_ALL_AGENDA_RIGHTS_INIT";
export const GET_ALL_AGENDA_RIGHTS_SUCCESS = "GET_ALL_AGENDA_RIGHTS_SUCCESS";
export const GET_ALL_AGENDA_RIGHTS_FAILED = "GET_ALL_AGENDA_RIGHTS_FAILED";

export const SAVE_USER_ATTACHMENT_PERMISSION_INIT =
  "SAVE_USER_ATTACHMENT_PERMISSION_INIT";

export const SAVE_USER_ATTACHMENT_PERMISSION_SUCCESS =
  "SAVE_USER_ATTACHMENT_PERMISSION_SUCCESS";

export const SAVE_USER_ATTACHMENT_PERMISSION_FAILED =
  "SAVE_USER_ATTACHMENT_PERMISSION_FAILED";
export const COMMITTEEID_VIEWDETAILS = "COMMITTEEID_VIEWDETAILS";

export const GETPOLLSBYCOMMITEEID_INIT = "GETPOLLSBYCOMMITEEID_INIT";
export const GETPOLLSBYCOMMITEEID_SUCCESS = "GETPOLLSBYCOMMITEEID_SUCCESS";
export const GETPOLLSBYCOMMITEEID_FAIL = "GETPOLLSBYCOMMITEEID_FAIL";

export const GET_AGENDAVOTINGINFO_INIT = "GET_AGENDAVOTINGINFO_INIT";
export const GET_AGENDAVOTINGINFO_SUCCESS = "GET_AGENDAVOTINGINFO_SUCCESS";
export const GET_AGENDAVOTINGINFO_FAIL = "GET_AGENDAVOTINGINFO_FAIL";

export const CASTEVOTEFORAGENDA_INIT = "CASTEVOTEFORAGENDA_INIT";
export const CASTEVOTEFORAGENDA_SUCCESS = "CASTEVOTEFORAGENDA_SUCCESS";
export const CASTEVOTEFORAGENDA_FAIL = "CASTEVOTEFORAGENDA_FAIL";

export const VIEW_AGENDAVOTINGRESULTS_INIT = "VIEW_AGENDAVOTINGRESULTS_INIT";
export const VIEW_AGENDAVOTINGRESULTS_SUCCESS =
  "VIEW_AGENDAVOTINGRESULTS_SUCCESS";
export const VIEW_AGENDAVOTINGRESULTS_FAIL = "VIEW_AGENDAVOTINGRESULTS_FAIL";
export const GET_POLLS_BY_GROUPID_INIT = "GET_POLLS_BY_GROUPID_INIT";
export const GET_POLLS_BY_GROUPID_SUCCESS = "GET_POLLS_BY_GROUPID_SUCCESS";
export const GET_POLLS_BY_GROUPID_FAIL = "GET_POLLS_BY_GROUPID_FAIL";

export const SET_GROUP_POLLS_INIT = "SET_GROUP_POLLS_INIT";
export const SET_GROUP_POLLS_SUCCESS = "SET_GROUP_POLLS_SUCCESS";
export const SET_GROUP_POLLS_FAIL = "SET_GROUP_POLLS_FAIL";

export const SETCOMMITTEEPOLL_INIT = "SETCOMMITTEEPOLL_INIT";
export const SETCOMMITTEEPOLL_SUCCESS = "SETCOMMITTEEPOLL_SUCCESS";
export const SETCOMMITTEEPOLL_FAIL = "SETCOMMITTEEPOLL_FAIL";

export const GET_GENERAL_MINTES_INIT = "GET_GENERAL_MINTES_INIT";
export const GET_GENERAL_MINTES_SUCCESS = "GET_GENERAL_MINTES_SUCCESS";
export const GET_GENERAL_MINTES_FAILED = "GET_GENERAL_MINTES_FAILED";

export const GET_ADD_GENERAL_MINUTES_INIT = "GET_ADD_GENERAL_MINUTES_INIT";
export const GET_ADD_GENERAL_MINUTES_SUCCESS =
  "GET_ADD_GENERAL_MINUTES_SUCCESS";
export const GET_ADD_GENERAL_MINUTES_FAILED = "GET_ADD_GENERAL_MINUTES_FAILED";

export const SAVE_GENERAL_MIN_DOCUMENTS_INIT =
  "SAVE_GENERAL_MIN_DOCUMENTS_INIT";
export const SAVE_GENERAL_MIN_DOCUMENTS_SUCCESS =
  "SAVE_GENERAL_MIN_DOCUMENTS_SUCCESS";
export const SAVE_GENERAL_MIN_DOCUMENTS_FAILED =
  "SAVE_GENERAL_MIN_DOCUMENTS_FAILED";

export const RETRIEVE_DOCUMENT_GENERAL_MINUTES_INIT =
  "RETRIEVE_DOCUMENT_GENERAL_MINUTES_INIT";

export const RETRIEVE_DOCUMENT_GENERAL_MINUTES_SUCCESS =
  "RETRIEVE_DOCUMENT_GENERAL_MINUTES_SUCCESS";

export const RETRIEVE_DOCUMENT_GENERAL_MINUTES_FAILED =
  "RETRIEVE_DOCUMENT_GENERAL_MINUTES_FAILED";

export const GENERAL_DOCUMENT_FOR_MEETING_INIT =
  "GENERAL_DOCUMENT_FOR_MEETING_INIT";
export const GENERAL_DOCUMENT_FOR_MEETING_SUCCESS =
  "GENERAL_DOCUMENT_FOR_MEETING_SUCCESS";
export const GENERAL_DOCUMENT_FOR_MEETING_FAILED =
  "GENERAL_DOCUMENT_FOR_MEETING_FAILED";
export const GET_TASK_BY_GROUPID_INIT = "GET_TASK_BY_GROUPID_INIT";
export const GET_TASK_BY_GROUPID_SUCCESS = "GET_TASK_BY_GROUPID_SUCCESS";
export const GET_TASK_BY_GROUPID_FAIL = "GET_TASK_BY_GROUPID_FAIL";

export const SET_TASK_GROUP_INIT = "SET_TASK_GROUP_INIT";
export const SET_TASK_GROUP_SUCCESS = "SET_TASK_GROUP_SUCCESS";
export const SET_TASK_GROUP_FAIL = "SET_TASK_GROUP_FAIL";

export const GET_TASK_BY_COMMITTEE_INIT = "GET_TASK_BY_COMMITTEE_INIT";
export const GET_TASK_BY_COMMITTEE_SUCCESS = "GET_TASK_BY_COMMITTEE_SUCCESS";
export const GET_TASK_BY_COMMITTEE_FAIL = "GET_TASK_BY_COMMITTEE_FAIL";

export const SET_TASK_COMMITTEE_INIT = "SET_TASK_COMMITTEE_INIT";
export const SET_TASK_COMMITTEE_SUCCESS = "SET_TASK_COMMITTEE_SUCCESS";
export const SET_TASK_COMMITTEE_FAIL = "SET_TASK_COMMITTEE_FAIL";

export const DELETE_GENERAL_MINUTES_METHOD_INIT =
  "DELETE_GENERAL_MINUTES_METHOD_INIT";

export const DELETE_GENERAL_MINUTES_METHOD_SUCCESS =
  "DELETE_GENERAL_MINUTES_METHOD_SUCCESS";

export const DELETE_GENERAL_MINUTES_METHOD_FAILED =
  "DELETE_GENERAL_MINUTES_METHOD_FAILED";

export const UPDATE_GENERAL_MINUTES_INIT = "UPDATE_GENERAL_MEETING_INIT";
export const UPDATE_GENERAL_MINUTES_SUCCESS = "UPDATE_GENERAL_MINUTES_SUCCESS";
export const UPDATE_GENERAL_MINUTES_FAILED = "UPDATE_GENERAL_MINUTES_FAILED";

export const AGENDA_WISE_MINUTES_INIT = "AGENDA_WISE_MINUTES_INIT";
export const AGENDA_WISE_MINUTES_SUCCESS = "AGENDA_WISE_MINUTES_SUCCESS";
export const AGENDA_WISE_MINUTES_FAILED = "AGENDA_WISE_MINUTES_FAILED";

export const DELETE_AGENDA_WISE_MINUTES_INIT =
  "DELETE_AGENDA_WISE_MINUTES_INIT";

export const DELETE_AGENDA_WISE_MINUTES_SUCCESS =
  "DELETE_AGENDA_WISE_MINUTES_SUCCESS";

export const DELETE_AGENDA_WISE_MINUTES_FAILED =
  "DELETE_AGENDA_WISE_MINUTES_FAILED";

export const UPDATE_AGENDA_WISE_MINUTES_INIT =
  "UPDATE_AGENDA_WISE_MINUTES_INIT";

export const UPDATE_AGENDA_WISE_MINUTES_SUCCESS =
  "UPDATE_AGENDA_WISE_MINUTES_SUCCESS";

export const UPDATE_AGENDA_WISE_MINUTES_FAILED =
  "UPDATE_AGENDA_WISE_MINUTES_FAILED";

export const GET_ALL_AGENDA_WISE_MINUTES_INIT =
  "GET_ALL_AGENDA_WISE_MINUTES_INIT";

export const GET_ALL_AGENDA_WISE_MINUTES_SUCCESS =
  "GET_ALL_AGENDA_WISE_MINUTES_SUCCESS";

export const GET_ALL_AGENDA_WISE_MINUTES_FAILED =
  "GET_ALL_AGENDA_WISE_MINUTES_FAILED";
export const DELETEMEETINGPOLLS_INIT = "DELETEMEETINGPOLLS_INIT";
export const DELETEMEETINGPOLLS_SUCCESS = "DELETEMEETINGPOLLS_SUCCESS";
export const DELETEMEETINGPOLLS_FAIL = "DELETEMEETINGPOLLS_FAIL";

export const DELETECOMMITTEEPOLLS_INIT = "DELETECOMMITTEEPOLLS_INIT";
export const DELETECOMMITTEEPOLLS_SUCCESS = "DELETECOMMITTEEPOLLS_SUCCESS";
export const DELETECOMMITTEEPOLLS_FAIL = "DELETECOMMITTEEPOLLS_FAIL";

export const DELETEGROUPPOLLS_INIT = "DELETEGROUPPOLLS_INIT";
export const DELETEGROUPPOLLS_SUCCESS = "DELETEGROUPPOLLS_SUCCESS";
export const DELETEGROUPPOLLS_FAIL = "DELETEGROUPPOLLS_FAIL";

export const DELETEGROUPTASK_INIT = "DELETEGROUPTASK_INIT";
export const DELETEGROUPTASK_SUCCESS = "DELETEGROUPTASK_SUCCESS";
export const DELETEGROUPTASK_FAIL = "DELETEGROUPTASK_FAIL";

export const DELETECOMMITTEETASK_INIT = "DELETECOMMITTEETASK_INIT";
export const DELETECOMMITTEETASK_SUCCESS = "DELETECOMMITTEETASK_SUCCESS";
export const DELETECOMMITTEETASK_FAIL = "DELETECOMMITTEETASK_FAIL";
export const GET_USER_WISE_PROPOSED_INIT = "GET_USER_WISE_PROPOSED_INIT";
export const GET_USER_WISE_PROPOSED_SUCCESS = "GET_USER_WISE_PROPOSED_SUCCESS";
export const GET_USER_WISE_PROPOSED_FAIL = "GET_USER_WISE_PROPOSED_FAIL";

export const GET_ADVANCEMEETINGAGENDABYMEETINGID_INIT =
  "GET_ADVANCEMEETINGAGENDABYMEETINGID_INIT";
export const GET_ADVANCEMEETINGAGENDABYMEETINGID_SUCCESS =
  "GET_ADVANCEMEETINGAGENDABYMEETINGID_SUCCESS";
export const GET_ADVANCEMEETINGAGENDABYMEETINGID_FAIL =
  "GET_ADVANCEMEETINGAGENDABYMEETINGID_FAIL";

export const SAVE_DOCUMENTS_AGENDA_WISE_INIT =
  "SAVE_DOCUMENTS_AGENDA_WISE_INIT";

export const SAVE_DOCUMENTS_AGENDA_WISE_SUCCESS =
  "SAVE_DOCUMENTS_AGENDA_WISE_SUCCESS";

export const SAVE_DOCUMENTS_AGENDA_WISE_FAILED =
  "SAVE_DOCUMENTS_AGENDA_WISE_FAILED";

export const RETRIVE_AGENDA_WISE_DOCUMENTS_INIT =
  "RETRIVE_AGENDA_WISE_DOCUMENTS_INIT";

export const RETRIVE_AGENDA_WISE_DOCUMENTS_SUCCESS =
  "RETRIVE_AGENDA_WISE_DOCUMENTS_SUCCESS";

export const RETRIVE_AGENDA_WISE_DOCUMENTS_FAILED =
  "RETRIVE_AGENDA_WISE_DOCUMENTS_FAILED";

export const DELETE_GENERAL_MINUTE_DCOUMENTS_INIT =
  "DELETE_GENERAL_MINUTE_DCOUMENTS_INIT";

export const DELETE_GENERAL_MINUTE_DCOUMENTS_SUCCESS =
  "DELETE_GENERAL_MINUTE_DCOUMENTS_SUCCESS";

export const DELETE_GENERAL_MINUTE_DCOUMENTS_FAILED =
  "DELETE_GENERAL_MINUTE_DCOUMENTS_FAILED";

export const CREATEUPDATEMEETINGDATAROOMMAP_INIT =
  "CREATEUPDATEMEETINGDATAROOMMAP_INIT";
export const CREATEUPDATEMEETINGDATAROOMMAP_SUCCESS =
  "CREATEUPDATEMEETINGDATAROOMMAP_SUCCESS";
export const CREATEUPDATEMEETINGDATAROOMMAP_FAIL =
  "CREATEUPDATEMEETINGDATAROOMMAP_FAIL";
export const SETMEETINGBYCOMMITTEEID_INIT = "SETMEETINGBYCOMMITTEEID_INIT";
export const SETMEETINGBYCOMMITTEEID_SUCCESS =
  "SETMEETINGBYCOMMITTEEID_SUCCESS";
export const SETMEETINGBYCOMMITTEEID_FAIL = "SETMEETINGBYCOMMITTEEID_FAIL";

export const DELETE_AGENDA_WISE_DOCUMENT_DELETE_INIT =
  "DELETE_AGENDA_WISE_DOCUMENT_DELETE_INIT";

export const DELETE_AGENDA_WISE_DOCUMENT_DELETE_SUCCESS =
  "DELETE_AGENDA_WISE_DOCUMENT_DELETE_SUCCESS";

export const DELETE_AGENDA_WISE_DOCUMENT_DELETE_FAILED =
  "DELETE_AGENDA_WISE_DOCUMENT_DELETE_FAILED";

export const CREATE_UPDATE_MEETING_DATA_ROOM_MAPPED_INIT =
  "CREATE_UPDATE_MEETING_DATA_ROOM_MAPPED_INIT";

export const CREATE_UPDATE_MEETING_DATA_ROOM_MAPPED_SUCCESS =
  "CREATE_UPDATE_MEETING_DATA_ROOM_MAPPED_SUCCESS";

export const CREATE_UPDATE_MEETING_DATA_ROOM_MAPPED_FAILED =
  "CREATE_UPDATE_MEETING_DATA_ROOM_MAPPED_FAILED";

export const GETMEETINGBYCOMMITTEEID_INIT = "GETMEETINGBYCOMMITTEEID_INIT";
export const GETMEETINGBYCOMMITTEEID_SUCCESS =
  "GETMEETINGBYCOMMITTEEID_SUCCESS";
export const GETMEETINGBYCOMMITTEEID_FAIL = "GETMEETINGBYCOMMITTEEID_FAIL";

export const SETMEETINGBYGROUPID_INIT = "SETMEETINGBYGROUPID_INIT";
export const SETMEETINGBYGROUPID_SUCCESS = "SETMEETINGBYGROUPID_SUCCESS";
export const SETMEETINGBYGROUPID_FAIL = "SETMEETINGBYGROUPID_FAIL";

export const GETMEETINGBYGROUPID_INIT = "GETMEETINGBYGROUPID_INIT";
export const GETMEETINGBYGROUPID_SUCCESS = "GETMEETINGBYGROUPID_SUCCESS";
export const GETMEETINGBYGROUPID_FAIL = "GETMEETINGBYGROUPID_FAIL";

export const SCHEDULE_MEETING_ON_SELECT_DATE_INIT =
  "SCHEDULE_MEETING_ON_SELECT_DATE_INIT";
export const SCHEDULE_MEETING_ON_SELECT_DATE_SUCCESS =
  "SCHEDULE_MEETING_ON_SELECT_DATE_SUCCESS";
export const SCHEDULE_MEETING_ON_SELECT_DATE_FAIL =
  "SCHEDULE_MEETING_ON_SELECT_DATE_FAIL";

export const UPDATE_MEETING_USERS_INIT = "UPDATE_MEETING_USERS_INIT";
export const UPDATE_MEETING_USERS_SUCCESS = "UPDATE_MEETING_USERS_SUCCESS";
export const UPDATE_MEETING_USERS_FAILED = "UPDATE_MEETING_USERS_FAILED";

export const SAVEUPDATE_ADVANCEMEETINGAGENDA_INIT =
  "SAVEUPDATE_ADVANCEMEETINGAGENDA_INIT";
export const SAVEUPDATE_ADVANCEMEETINGAGENDA_SUCCESS =
  "SAVEUPDATE_ADVANCEMEETINGAGENDA_SUCCESS";
export const SAVEUPDATE_ADVANCEMEETINGAGENDA_FAIL =
  "SAVEUPDATE_ADVANCEMEETINGAGENDA_FAIL";

export const SAVEFILES_AGENDA_INIT = "SAVEFILES_AGENDA_INIT";
export const SAVEFILES_AGENDA_SUCCESS = "SAVEFILES_AGENDA_SUCCESS";
export const SAVEFILES_AGENDA_FAIL = "SAVEFILES_AGENDA_FAIL";

export const UPLOAD_DOCUMENTS_AGENDA_INIT = "UPLOAD_DOCUMENTS_AGENDA_INIT";
export const UPLOAD_DOCUMENTS_AGENDA_SUCCESS =
  "UPLOAD_DOCUMENTS_AGENDA_SUCCESS";
export const UPLOAD_DOCUMENTS_AGENDA_FAIL = "UPLOAD_DOCUMENTS_AGENDA_FAIL";

export const UPLOAD_GROUPS_DOCUMENTS_INIT = "UPLOAD_GROUPS_DOCUMENTS_INIT";
export const UPLOAD_GROUPS_DOCUMENTS_SUCCESS =
  "UPLOAD_GROUPS_DOCUMENTS_SUCCESS";
export const UPLOAD_GROUPS_DOCUMENTS_FAIL = "UPLOAD_GROUPS_DOCUMENTS_FAIL";

export const SAVE_GROUP_FILES_DOCUMENTS_INIT =
  "SAVE_GROUP_FILES_DOCUMENTS_INIT";
export const SAVE_GROUP_FILES_DOCUMENTS_SUCCESS =
  "SAVE_GROUP_FILES_DOCUMENTS_SUCCESS";
export const SAVE_GROUP_FILES_DOCUMENTS_FAIL =
  "SAVE_GROUP_FILES_DOCUMENTS_FAIL";

export const UPLOAD_COMMITTEESS_DOCUMENTS_INIT =
  "UPLOAD_COMMITTEESS_DOCUMENTS_INIT";
export const UPLOAD_COMMITTEESS_DOCUMENTS_SUCCESS =
  "UPLOAD_COMMITTEESS_DOCUMENTS_SUCCESS";
export const UPLOAD_COMMITTEESS_DOCUMENTS_FAIL =
  "UPLOAD_COMMITTEESS_DOCUMENTS_FAIL";

export const SAVE_COMMITTEE_FILES_DOCUMENTS_INIT =
  "SAVE_COMMITTEE_FILES_DOCUMENTS_INIT";
export const SAVE_COMMITTEE_FILES_DOCUMENTS_SUCCESS =
  "SAVE_COMMITTEE_FILES_DOCUMENTS_SUCCESS";
export const SAVE_COMMITTEE_FILES_DOCUMENTS_FAIL =
  "SAVE_COMMITTEE_FILES_DOCUMENTS_FAIL";
export const UNSAVE_VIEW_MINTUES_MODAL = "UNSAVE_VIEW_MINTUES_MODAL";

export const UNSAVED_VIEW_POLLS_MODAL = "UNSAVED_VIEW_POLLS_MODAL";
export const NEXT_CONFIRMATION_MODAL = "NEXT_CONFIRMATION_MODAL";

export const PREVIOUS_MODAL = "PREVIOUS_MODAL";
export const CLEARE_ALL_MEETING_STATE = "CLEARE_ALL_MEETING_STATE";

export const ATTENDANCE_DOWNLOAD_LOADER_FAIL =
  "ATTENDANCE_DOWNLOAD_LOADER_FAIL";

export const START_END_AGENDAVOTING_INIT = "START_END_AGENDAVOTING_INIT";
export const START_END_AGENDAVOTING_SUCCESS = "START_END_AGENDAVOTING_SUCCESS";
export const START_END_AGENDAVOTING_FAIL = "START_END_AGENDAVOTING_FAIL";

export const GET_CURRENT_AGENDA_DETAILS = "GET_CURRENT_AGENDA_DETAILS";
export const NEWMEETING_RESPONSEMESSAGE = "NEWMEETING_RESPONSEMESSAGE";
export const CLEAR_MEETING_MESSAGES = "CLEAR_MEETING_MESSAGES";

export const CLEAR_POLLS_MESSAGES = "CLEAR_POLLS_MESSAGES";

export const ATTENDENCE_ASSURANCE_MODAL = "ATTENDENCE_ASSURANCE_MODAL";

export const SAVE_DOCUMENTS_AGENDA_INIT = "SAVE_DOCUMENTS_AGENDA_INIT";
export const SAVE_DOCUMENTS_AGENDA_SUCCESS = "SAVE_DOCUMENTS_AGENDA_SUCCESS";
export const SAVE_DOCUMENTS_AGENDA_FAIL = "SAVE_DOCUMENTS_AGENDA_FAIL";
export const GET_MEETING_TASKS_ACTION_INIT = "GET_MEETING_TASKS_ACTION_INIT";
export const GET_MEETING_TASKS_ACTION_SUCCESS =
  "GET_MEETING_TASKS_ACTION_SUCCESS";
export const GET_MEETING_TASKS_ACTION_FAIL = "GET_MEETING_TASKS_ACTION_FAIL";

export const DATA_ROOM_FILE_DOWNLOAD = "DATA_ROOM_FILE_DOWNLOAD";
export const DATA_ROOM_FILE_DOWNLOAD_LOADER_FALSED =
  "DATA_ROOM_FILE_DOWNLOAD_LOADER_FALSED";

export const DATA_ROOM_FILE_DOWNLOAD_FAILED = "DATA_ROOM_FILE_DOWNLOAD_FAILED";

export const DATA_ROOM_FOLDER_DOWNLOAD = "DATA_ROOM_FOLDER_DOWNLOAD";
export const DATA_ROOM_FOLDER_DOWNLOAD_LOADER_FALSE =
  "DATA_ROOM_FOLDER_DOWNLOAD_LOADER_FALSE";

export const DATA_ROOM_FOLDER_DOWNLOAD_FAILED =
  "DATA_ROOM_FOLDER_DOWNLOAD_FAILED";
export const CREATEUPDATETASKDATAROOMMAP_INIT =
  "CREATEUPDATETASKDATAROOMMAP_INIT";
export const CREATEUPDATETASKDATAROOMMAP_SUCCESS =
  "CREATEUPDATETASKDATAROOMMAP_SUCCESS";
export const CREATEUPDATETASKDATAROOMMAP_FAIL =
  "CREATEUPDATETASKDATAROOMMAP_FAIL";

export const UPLOAD_DOCUMENTS_TASKS_INIT = "UPLOAD_DOCUMENTS_TASKS_INIT";
export const UPLOAD_DOCUMENTS_TASKS_SUCCESS = "UPLOAD_DOCUMENTS_TASKS_SUCCESS";
export const UPLOAD_DOCUMENTS_TASKS_FAIL = "UPLOAD_DOCUMENTS_TASKS_FAIL";

export const SAVEFILES_TASKS_INIT = "SAVEFILES_TASKS_INIT";
export const SAVEFILES_TASKS_SUCCESS = "SAVEFILES_TASKS_SUCCESS";
export const SAVEFILES_TASKS_FAIL = "SAVEFILES_TASKS_FAIL";

export const SAVE_TASK_DOCUMENTS_INIT = "SAVE_TASK_DOCUMENTS_INIT";
export const SAVE_TASK_DOCUMENTS_SUCCESS = "SAVE_TASK_DOCUMENTS_SUCCESS";
export const SAVE_TASK_DOCUMENTS_FAIL = "SAVE_TASK_DOCUMENTS_FAIL";

export const SAVETASKDOCUMENTSANDASSIGNEES_INIT =
  "SAVETASKDOCUMENTSANDASSIGNEES_INIT";
export const SAVETASKDOCUMENTSANDASSIGNEES_SUCCESS =
  "SAVETASKDOCUMENTSANDASSIGNEES_SUCCESS";
export const SAVETASKDOCUMENTSANDASSIGNEES_FAIL =
  "SAVETASKDOCUMENTSANDASSIGNEES_FAIL";

export const UPLOAD_DOCUMENT_ACTION_INIT = "UPLOAD_DOCUMENT_ACTION_INIT";
export const UPLOAD_DOCUMENT_ACTION_SUCCESS = "UPLOAD_DOCUMENT_ACTION_SUCCESS";
export const UPLOAD_DOCUMENT_ACTION_FAIL = "UPLOAD_DOCUMENT_ACTION_FAIL";

export const MAP_TASK_MEETING_AGENDA_INIT = "MAP_TASK_MEETING_AGENDA_INIT";
export const MAP_TASK_MEETING_AGENDA_SUCCESS =
  "MAP_TASK_MEETING_AGENDA_SUCCESS";
export const MAP_TASK_MEETING_AGENDA_FAIL = "MAP_TASK_MEETING_AGENDA_FAIL";

export const REMOVE_TASK_MEETING_MAP_INIT = "REMOVE_TASK_MEETING_MAP_INIT";
export const REMOVE_TASK_MEETING_MAP_SUCCESS =
  "REMOVE_TASK_MEETING_MAP_SUCCESS";
export const REMOVE_TASK_MEETING_MAP_FAIL = "REMOVE_TASK_MEETING_MAP_FAIL";

export const GET_MEETING_URL_CLIPBOARD = "GET_MEETING_URL_CLIPBOARD";
export const GET_ALL_AGENDAWISE_DOCUMENT_INIT =
  "GET_ALL_AGENDAWISE_DOCUMENT_INIT";
export const GET_ALL_AGENDAWISE_DOCUMENT_SUCCESS =
  "GET_ALL_AGENDAWISE_DOCUMENT_SUCCESS";
export const GET_ALL_AGENDAWISE_DOCUMENT_FAILED =
  "GET_ALL_AGENDAWISE_DOCUMENT_FAILED";

export const GET_ALL_AGENDAWISE_AGENDA_INIT = "GET_ALL_AGENDAWISE_AGENDA_INIT";
export const GET_ALL_AGENDAWISE_AGENDA_SUCCESS =
  "GET_ALL_AGENDAWISE_AGENDA_SUCCESS";
export const GET_ALL_AGENDAWISE_AGENDA_FAILED =
  "GET_ALL_AGENDAWISE_AGENDA_FAILED";

export const ATTENDANCE_REDUCER_STATE_CLEAR = "ATTENDANCE_REDUCER_STATE_CLEAR";
export const UPLOAD_DOCUMENT_INIT = "UPLOAD_DOCUMENT_INIT";
export const UPLOAD_DOCUMENT_SUCCESS = "UPLOAD_DOCUMENT_SUCCESS";
export const UPLOAD_DOCUMENT_FAILED = "UPLOAD_DOCUMENT_FAILED";

export const SAVED_FILES_INIT = "SAVED_FILES_INIT";
export const SAVED_FILES_SUCCESS = "SAVED_FILES_SUCCESS";
export const SAVED_FILES_FAILED = "SAVED_FILES_FAILED";

export const CLEARE_MINUTS_DATA = "CLEARE_MINUTS_DATA";

export const CLEAR_MEETING_AGENDA_REDUCER = "CLEAR_MEETING_AGENDA_REDUCER";

export const SET_LOADER_FALSE_AGENDA = "SET_LOADER_FALSE_AGENDA";
export const PREVIOUS_TAB_AGENDA = "PREVIOUS_TAB_AGENDA";
export const NEXT_TAB_AGENDA = "NEXT_TAB_AGENDA";

export const GET_ALLMEETINGFORAGENDAIMPORT_INIT =
  "GET_ALLMEETINGFORAGENDAIMPORT_INIT";
export const GET_ALLMEETINGFORAGENDAIMPORT_SUCCESS =
  "GET_ALLMEETINGFORAGENDAIMPORT_SUCCESS";
export const GET_ALLMEETINGFORAGENDAIMPORT_FAIL =
  "GET_ALLMEETINGFORAGENDAIMPORT_FAIL";

export const GET_AGENDAWITHMEETINGIDFORIMPORT_INIT =
  "GET_AGENDAWITHMEETINGIDFORIMPORT_INIT";
export const GET_AGENDAWITHMEETINGIDFORIMPORT_SUCCESS =
  "GET_AGENDAWITHMEETINGIDFORIMPORT_SUCCESS";
export const GET_AGENDAWITHMEETINGIDFORIMPORT_FAIL =
  "GET_AGENDAWITHMEETINGIDFORIMPORT_FAIL";
export const INVITE_TO_COLLABORATE_INIT = "INVITE_TO_COLLABORATE_INIT";
export const INVITE_TO_COLLABORATE_SUCCESS = "INVITE_TO_COLLABORATE_SUCCESS";
export const INVITE_TO_COLLABORATE_FAILED = "INVITE_TO_COLLABORATE_FAILED";
export const MQTT_MEETING_STATUS_PROPOSED = "MQTT_MEETING_STATUS_PROPOSED";
export const MQTT_MEETING_STATUS_PUBLISHED = "MQTT_MEETING_STATUS_PUBLISHED";

export const UPLOAD_RESOLUTION_DOCUMENTS_INIT =
  "UPLOAD_RESOLUTION_DOCUMENTS_INIT";
export const UPLOAD_RESOLUTION_DOCUMENTS_SUCCESS =
  "UPLOAD_RESOLUTION_DOCUMENTS_SUCCESS";
export const UPLOAD_RESOLUTION_DOCUMENTS_FAIL =
  "UPLOAD_RESOLUTION_DOCUMENTS_FAIL";

export const SAVE_RESOLUTION_DOCUMENTS_INIT = "SAVE_RESOLUTION_DOCUMENTS_INIT";
export const SAVE_RESOLUTION_DOCUMENTS_SUCCESS =
  "SAVE_RESOLUTION_DOCUMENTS_SUCCESS";
export const SAVE_RESOLUTION_DOCUMENTS_FAIL = "SAVE_RESOLUTION_DOCUMENTS_FAIL";

export const GETFILESANDFOLDERS_DETAILS_INIT =
  "GETFILESANDFOLDERS_DETAILS_INIT";
export const GETFILESANDFOLDERS_DETAILS_SUCCESS =
  "GETFILESANDFOLDERS_DETAILS_SUCCESS";
export const GETFILESANDFOLDERS_DETAILS_FAIL =
  "GETFILESANDFOLDERS_DETAILS_FAIL";

export const UPDATEANDOPENBYANDDESCRIPTION_INIT =
  "UPDATEANDOPENBYANDDESCRIPTION_INIT";
export const UPDATEANDOPENBYANDDESCRIPTION_SUCCESS =
  "UPDATEANDOPENBYANDDESCRIPTION_SUCCESS";
export const UPDATEANDOPENBYANDDESCRIPTION_FAIL =
  "UPDATEANDOPENBYANDDESCRIPTION_FAIL";

export const GETDATAANALYTICS_INIT = "GETDATAANALYTICS_INIT";
export const GETDATAANALYTICS_SUCCESS = "GETDATAANALYTICS_SUCCESS";
export const GETDATAANALYTICS_FAIL = "GETDATAANALYTICS_FAIL";

export const GETDATAANALYTICSCOUNT_INIT = "GETDATAANALYTICSCOUNT_INIT";
export const GETDATAANALYTICSCOUNT_SUCCESS = "GETDATAANALYTICSCOUNT_SUCCESS";
export const GETDATAANALYTICSCOUNT_FAIL = "GETDATAANALYTICSCOUNT_FAIL";

export const FILE_DETAIL_MODAL = "FILE_DETAIL_MODAL";

export const CLEAR_DATAROOM2_RESPONSE_MESSAGE =
  "CLEAR_DATAROOM2_RESPONSE_MESSAGE";

export const VALIDATE_EMPTY_STRING_INIT = "VALIDATE_EMPTY_STRING_INIT";
export const VALIDATE_EMPTY_STRING_SUCCESS = "VALIDATE_EMPTY_STRING_SUCCESS";
export const VALIDATE_EMPTY_STRING_FAILED = "VALIDATE_EMPTY_STRING_FAILED";
export const MQTT_MEETING_AGENDA_VOTING_STARTED =
  "MQTT_MEETING_AGENDA_VOTING_STARTED";

export const MQTT_MEETING_AGENDA_VOTING_ENDED =
  "MQTT_MEETING_AGENDA_VOTING_ENDED";

export const MQTT_MEETING_AGENDA_UPDATED = "MQTT_MEETING_AGENDA_UPDATED";

export const VALIDATE_EMPTY_STRING_DATAROOM_INIT =
  "VALIDATE_EMPTY_STRING_DATAROOM_INIT";
export const VALIDATE_EMPTY_STRING_DATAROOM_SUCCESS =
  "VALIDATE_EMPTY_STRING_DATAROOM_SUCCESS";
export const VALIDATE_EMPTY_STRING_DATAROOM_FAILED =
  "VALIDATE_EMPTY_STRING_DATAROOM_FAILED";

export const DASHBOARD_CALENDAR_DATA = "DASHBOARD_CALENDAR_DATA";

export const CREATE_GROUP_PAGE_FLAG = "CREATE_GROUP_PAGE_FLAG";
export const UPDATE_GROUP_PAGE_FLAG = "UPDATE_GROUP_PAGE_FLAG";
export const VIEW_GROUP_PAGE_FLAG = "VIEW_GROUP_PAGE_FLAG";

export const CREATE_COMMITTEE_PAGE_FLAG = "CREATE_COMMITTEE_PAGE_FLAG";
export const UPDATE_COMMITTEE_PAGE_FLAG = "UPDATE_COMMITTEE_PAGE_FLAG";
export const VIEW_COMMITTEE_PAGE_FLAG = "VIEW_COMMITTEE_PAGE_FLAG";

export const RESULT_RESOLUTION_FLAG = "RESULT_RESOLUTION_FLAG";
export const VOTE_RESOLUTION_FLAG = "VOTE_RESOLUTION_FLAG";
export const VIEW_ATTACHMENT_FLAG = "VIEW_ATTACHMENT_FLAG";

export const SCHEDULE_NEW_MEETING_PAGE_FLAG = "SCHEDULE_NEW_MEETING_PAGE_FLAG";
export const VIEW_PROPOSED_DATE_MEETING_PAGE_FLAG =
  "VIEW_PROPOSED_DATE_MEETING_PAGE_FLAG";
export const VIEW_ADVANCE_MEETING_PUBLISH_PAGE_FLAG =
  "VIEW_ADVANCE_MEETING_PUBLISH_PAGE_FLAG";
export const VIEW_ADVANCE_MEETING_UNPUBLISH_PAGE_FLAG =
  "VIEW_ADVANCE_MEETING_UNPUBLISH_PAGE_FLAG";
export const VIEW_PROPOSE_PRGANIZER_MEETING_PAGE_FLAG =
  "VIEW_PROPOSE_PRGANIZER_MEETING_PAGE_FLAG";
export const PROPOSE_NEW_MEETING_PAGE_FLAG = "PROPOSE_NEW_MEETING_PAGE_FLAG";

export const RETRY_FLAG_STATE = "RETRY_FLAG_STATE";

export const MQTT_MEETING_STATUS_NOTCONDUCTED =
  "MQTT_MEETING_STATUS_NOTCONDUCTED";
export const MICROSOFT_VALIDATE_TOKEN_INIT = "MICROSOFT_VALIDATE_TOKEN_INIT";
export const MICROSOFT_VALIDATE_TOKEN_SUCCESS =
  "MICROSOFT_VALIDATE_TOKEN_SUCCESS";
export const MICROSOFT_VALIDATE_TOKEN_FAIL = "MICROSOFT_VALIDATE_TOKEN_FAIL";
export const TASK_FOLDER_MAPPING_ID = "TASK_FOLDER_MAPPING_ID";

// when Organizer hit the view button in unpublished meeting action types
export const GET_USER_PROPOSED_DATES_INIT = "GET_USER_PROPOSED_DATES_INIT";
export const GET_USER_PROPOSED_DATES_SUCCESS =
  "GET_USER_PROPOSED_DATES_SUCCESS";
export const GET_USER_PROPOSED_DATES_FAIL = "GET_USER_PROPOSED_DATES_FAIL";

export const DOWNLOAD_MESSAGE = "DOWNLOAD_MESSAGE";
//upload document loader on notes should not be false after uploading the documents
export const UPLOAD_DOCUMENT_LOADER = "UPLOAD_DOCUMENT_LOADER";

export const SIDEBAR_POPUP_ADVANCE_MEETING = "SIDEBAR_POPUP_ADVANCE_MEETING";

export const VIEW_MEETING_FLAG = "VIEW_MEETING_FLAG";

export const MEETING_DETAILS_GLOBAL_FLAG = "MEETING_DETAILS_GLOBAL_FLAG";
export const ORGANIZERS_GLOBAL_FLAG = "ORGANIZERS_GLOBAL_FLAG";
export const AGENDA_CONTRIBUTORS_GLOBAL_FLAG =
  "AGENDA_CONTRIBUTORS_GLOBAL_FLAG";
export const PARTICIPANTS_GLOBAL_FLAG = "PARTICIPANTS_GLOBAL_FLAG";
export const AGENDA_GLOBAL_FLAG = "AGENDA_GLOBAL_FLAG";
export const MEETING_MATERIAL_GLOBAL_FLAG = "MEETING_MATERIAL_GLOBAL_FLAG";
export const MINUTES_GLOBAL_FLAG = "MINUTES_GLOBAL_FLAG";
export const PROPOSED_MEETING_DATES_GLOBAL_FLAG =
  "PROPOSED_MEETING_DATES_GLOBAL_FLAG";
export const ACTIONS_GLOBAL_FLAG = "ACTIONS_GLOBAL_FLAG";
export const POLLS_GLOBAL_FLAG = "POLLS_GLOBAL_FLAG";
export const ATTENDANCE_GLOBAL_FLAG = "ATTENDANCE_GLOBAL_FLAG";
export const UPLOAD_GLOBAL_FLAG = "UPLOAD_GLOBAL_FLAG";
export const GET_USER_LOGIN_HISTORY_INIT = "GET_USER_LOGIN_HISTORY_INIT";
export const GET_USER_LOGIN_HISTORY_SUCCESS = "GET_USER_LOGIN_HISTORY_SUCCESS";
export const GET_USER_LOGIN_HISTORY_FAIL = "GET_USER_LOGIN_HISTORY_FAIL";

export const GET_ALL_AGENDACONTRIBUTOR_ISPUBLISHED_SUCCESS =
  "GET_ALL_AGENDACONTRIBUTOR_ISPUBLISHED_SUCCESS";

export const GET_ALL_SAVED_PARTICIPATNS_ISPUBLISHED_SUCCESS =
  "GET_ALL_SAVED_PARTICIPATNS_ISPUBLISHED_SUCCESS";

export const GET_ALL_MEETING_MATERIAL_ISPUBLISHED_SUCCESS =
  "GET_ALL_MEETING_MATERIAL_ISPUBLISHED_SUCCESS";

export const USER_LOGOUT_INIT = "USER_LOGOUT_INIT";
export const USER_LOGOUT_SUCCESS = "USER_LOGOUT_SUCCESS";
export const USER_LOGOUT_FAILED = "USER_LOGOUT_FAILED";

export const CREATESIGNATUREFLOW_INIT = "CREATESIGNATUREFLOW_INIT";
export const CREATESIGNATUREFLOW_SUCCESS = "CREATESIGNATUREFLOW_SUCCESS";
export const CREATESIGNATUREFLOW_FAIL = "CREATESIGNATUREFLOW_FAIL";
// for end meeting status change api
export const END_MEETING_STATUS_INIT = "END_MEETING_STATUS_INIT";
export const END_MEETING_STATUS_SUCCESS = "END_MEETING_STATUS_SUCCESS";
export const END_MEETING_STATUS_FAIL = "END_MEETING_STATUS_FAIL";

// clear state of Response message of attendace
export const CLEAR_ATTENDANCE_RESPONSEMESSAGE =
  "CLEAR_ATTENDANCE_RESPONSEMESSAGE";

export const SAVE_WORKFLOW_INT = "SAVE_WORKFLOW_INT";
export const SAVE_WORKFLOW_SUCCESS = "SAVE_WORKFLOW_SUCCESS";
export const SAVE_WORKFLOW_FAIL = "SAVE_WORKFLOW_FAIL";

export const GETWORKFLOWBYFILEID_INIT = "GETWORKFLOWBYFILEID_INIT";
export const GETWORKFLOWBYFILEID_SUCCESS = "GETWORKFLOWBYFILEID_SUCCESS";
export const GETWORKFLOWBYFILEID_FAIL = "GETWORKFLOWBYFILEID_FAIL";

export const ADD_UPDATE_FIELD_VALUE_INIT = "ADD_UPDATE_FIELD_VALUE_INIT";
export const ADD_UPDATE_FIELD_VALUE_SUCCESS = "ADD_UPDATE_FIELD_VALUE_SUCCESS";
export const ADD_UPDATE_FIELD_VALUE_FAIL = "ADD_UPDATE_FIELD_VALUE_FAIL";

export const SAVE_SIGNATURE_DOCUMENT_INIT = "SAVE_SIGNATURE_DOCUMENT_INIT";
export const SAVE_SIGNATURE_DOCUMENT_SUCCESS =
  "SAVE_SIGNATURE_DOCUMENT_SUCCESS";
export const SAVE_SIGNATURE_DOCUMENT_FAIL = "SAVE_SIGNATURE_DOCUMENT_FAIL";

export const GET_ALL_FIELDS_BY_WORKDFLOW_ID_INIT =
  "GET_ALL_FIELDS_BY_WORKDFLOW_ID_INIT";

export const GET_ALL_FIELDS_BY_WORKDFLOW_ID_SUCCESS =
  "GET_ALL_FIELDS_BY_WORKDFLOW_ID_SUCCESS";

export const GET_ALL_FIELDS_BY_WORKDFLOW_ID_FAIL =
  "GET_ALL_FIELDS_BY_WORKDFLOW_ID_FAIL";

export const SEND_DOCUMENT_INIT = "SEND_DOCUMENT_INIT";
export const SEND_DOCUMENT_SUCCESS = "SEND_DOCUMENT_SUCCESS";
export const SEND_DOCUMENT_FAIL = "SEND_DOCUMENT_FAIL";

// User Management
export const UPGRADE_NOW_MODAL = "UPGRADE_NOW_MODAL";
export const REQUEST_EXTENTION_MODAL = "REQUEST_EXTENTION_MODAL";
export const CREATE_ADDITIONAL_MODALS = "CREATE_ADDITIONAL_MODALS";
export const DELETE_USER_MODAL = "DELETE_USER_MODAL";
export const EDIT_USER_MODAL = "EDIT_USER_MODAL";
export const SUCCESSFULLY_UPDATED_MODAL = "SUCCESSFULLY_UPDATED_MODAL";

export const GET_ANNOTATION_FILE_SIGNATUREFLOW_INIT =
  "GET_ANNOTATION_FILE_SIGNATUREFLOW_INIT";
export const GET_ANNOTATION_FILE_SIGNATUREFLOW_SUCCESS =
  "GET_ANNOTATION_FILE_SIGNATUREFLOW_SUCCESS";
export const GET_ANNOTATION_FILE_SIGNATUREFLOW_FAIL =
  "GET_ANNOTATION_FILE_SIGNATUREFLOW_FAIL";

export const ADD_ANNOTATION_FILE_SIGNATUREFLOW_INIT =
  "ADD_ANNOTATION_FILE_SIGNATUREFLOW_INIT";
export const ADD_ANNOTATION_FILE_SIGNATUREFLOW_SUCCESS =
  "ADD_ANNOTATION_FILE_SIGNATUREFLOW_SUCCESS";
export const ADD_ANNOTATION_FILE_SIGNATUREFLOW_FAIL =
  "ADD_ANNOTATION_FILE_SIGNATUREFLOW_FAIL";

export const PRINT_AGENDA = "PRINT_AGENDA";
export const EXPORT_AGENDA = "EXPORT_AGENDA";
export const AGENDA_VIEW_FLAG = "AGENDA_VIEW_FLAG";

export const GET_GETMEETINGPARTICIPANTSAGENDA_INIT =
  "GET_GETMEETINGPARTICIPANTSAGENDA_INIT";
export const GET_GETMEETINGPARTICIPANTSAGENDA_SUCCESS =
  "GET_GETMEETINGPARTICIPANTSAGENDA_SUCCESS";
export const GET_GETMEETINGPARTICIPANTSAGENDA_FAIL =
  "GET_GETMEETINGPARTICIPANTSAGENDA_FAIL";

export const SEND_AGENDAPDFASEMAIL_INIT = "SEND_AGENDAPDFASEMAIL_INIT";
export const SEND_AGENDAPDFASEMAIL_SUCCESS = "SEND_AGENDAPDFASEMAIL_SUCCESS";
export const SEND_AGENDAPDFASEMAIL_FAIL = "SEND_AGENDAPDFASEMAIL_FAIL";

export const PRINT_AGENDA_MEETING_INIT = "PRINT_AGENDA_MEETING_INIT";
export const PRINT_AGENDA_MEETING_SUCCESS = "PRINT_AGENDA_MEETING_SUCCESS";
export const PRINT_AGENDA_MEETING_FAIL = "PRINT_AGENDA_MEETING_FAIL";

export const THANK_FOR_PAYMENT_MODAL = "THANK_FOR_PAYMENT_MODAL";
export const FAILED_PAYMENT_PROCESS = "FAILED_PAYMENT_PROCESS";
export const CANCEL_SUBSCRIPTION_MODAL = "CANCEL_SUBSCRIPTION_MODAL";
export const REASON_FOR_LEAVING_MODAL = "REASON_FOR_LEAVING_MODAL";

export const MQTT_MEETING_AC_ADDED = "MQTT_MEETING_AC_ADDED";
export const MQTT_MEETING_AC_REMOVED = "MQTT_MEETING_AC_REMOVED";
export const MQTT_MEETING_ORG_ADDED = "MQTT_MEETING_ORG_ADDED";
export const MQTT_MEETING_ORG_REMOVED = "MQTT_MEETING_ORG_REMOVED";
export const MQTT_MEETING_PAR_ADDED = "MQTT_MEETING_PAR_ADDED";
export const MQTT_MEETING_PAR_REMOVED = "MQTT_MEETING_PAR_REMOVED";

export const SAVE_ORGANIZATIONAND_SELECTEDPAKGE_USERMANAGEMENT_INIT =
  "SAVE_ORGANIZATIONAND_SELECTEDPAKGE_USERMANAGEMENT_INIT";

export const SAVE_ORGANIZATIONAND_SELECTEDPAKGE_USERMANAGEMENT_SUCCESS =
  "SAVE_ORGANIZATIONAND_SELECTEDPAKGE_USERMANAGEMENT_SUCCESS";

export const SAVE_ORGANIZATIONAND_SELECTEDPAKGE_USERMANAGEMENT_FAIL =
  "SAVE_ORGANIZATIONAND_SELECTEDPAKGE_USERMANAGEMENT_FAIL";

// export const GET_ORGANIZATION_SUBSCRIPTION_EXPIRYDETAILS_INIT =
//   "GET_ORGANIZATION_SUBSCRIPTION_EXPIRYDETAILS_INIT";

// export const GET_ORGANIZATION_SUBSCRIPTION_EXPIRYDETAILS_SUCCESS =
//   "GET_ORGANIZATION_SUBSCRIPTION_EXPIRYDETAILS_SUCCESS";

// export const GET_ORGANIZATION_SUBSCRIPTION_EXPIRYDETAILS_FAILS =
//   "GET_ORGANIZATION_SUBSCRIPTION_EXPIRYDETAILS_FAILS";

export const EXTEND_ORGANIZATION_TRIAL_INIT = "EXTEND_ORGANIZATION_TRIAL_INIT";
export const EXTEND_ORGANIZATION_TRIAL_SUCCESS =
  "EXTEND_ORGANIZATION_TRIAL_SUCCESS";
export const EXTEND_ORGANIZATION_TRIAL_FAIL = "EXTEND_ORGANIZATION_TRIAL_FAIL";

export const ADD_ORGANIZATION_USERS_INIT = "ADD_ORGANIZATION_USERS_INIT";
export const ADD_ORGANIZATION_USERS_SUCCESS = "ADD_ORGANIZATION_USERS_SUCCESS";
export const ADD_ORGANIZATION_USERS_FAIL = "ADD_ORGANIZATION_USERS_FAIL";

export const EDIT_ORGANIZATION_USERS_INIT = "EDIT_ORGANIZATION_USERS_INIT";
export const EDIT_ORGANIZATION_USERS_SUCCESS =
  "EDIT_ORGANIZATION_USERS_SUCCESS";
export const EDIT_ORGANIZATION_USERS_FAIL = "EDIT_ORGANIZATION_USERS_FAIL";

export const ALL_ORGANIZAION_USERS_INIT = "ALL_ORGANIZAION_USERS_INIT";
export const ALL_ORGANIZAION_USERS_SUCCESS = "ALL_ORGANIZAION_USERS_SUCCESS";
export const ALL_ORGANIZAION_USERS_FAIL = "ALL_ORGANIZAION_USERS_FAIL";

export const ORGANIZATION_PAKAGEDETAILS_AND_USERSTATS_INIT =
  "ORGANIZATION_PAKAGEDETAILS_AND_USERSTATS_INIT";

export const ORGANIZATION_PAKAGEDETAILS_AND_USERSTATS_SUCCESS =
  "ORGANIZATION_PAKAGEDETAILS_AND_USERSTATS_SUCCESS";

export const ORGANIZATION_PAKAGEDETAILS_AND_USERSTATS_FAIL =
  "ORGANIZATION_PAKAGEDETAILS_AND_USERSTATS_FAIL";

export const GET_ORGANZIATION_SELECTEDPAKAGE_BY_ORGANZATIONID_INIT =
  "GET_ORGANZIATION_SELECTEDPAKAGE_BY_ORGANZATIONID_INIT";

export const GET_ORGANZIATION_SELECTEDPAKAGE_BY_ORGANZATIONID_SUCCESS =
  "GET_ORGANZIATION_SELECTEDPAKAGE_BY_ORGANZATIONID_SUCCESS";

export const GET_ORGANZIATION_SELECTEDPAKAGE_BY_ORGANZATIONID_FAIL =
  "GET_ORGANZIATION_SELECTEDPAKAGE_BY_ORGANZATIONID_FAIL";

export const JOIN_MEETING_INIT = "JOIN_MEETING_INIT";
export const JOIN_MEETING_SUCCESS = "JOIN_MEETING_SUCCESS";
export const JOIN_MEETING_FAIL = "JOIN_MEETING_FAIL";

export const LEAVE_MEETING_INIT = "LEAVE_MEETING_INIT";
export const LEAVE_MEETING_SUCCESS_QUICK = "LEAVE_MEETING_SUCCESS_QUICK";
export const LEAVE_MEETING_SUCCESS_ADVANCED = "LEAVE_MEETING_SUCCESS_ADVANCED";
export const LEAVE_MEETING_FAIL = "LEAVE_MEETING_FAIL";

export const CURRENT_MEETING_STATUS = "CURRENT_MEETING_STATUS";

export const ROUTING_ON_PAGES_USERMANAGEMENT =
  "ROUTING_ON_PAGES_USERMANAGEMENT";

export const ROUTES_FOR_SIGNUP_FLOW_UM = "ROUTES_FOR_SIGNUP_FLOW_UM";

export const GET_ALL_ORGANIZATION_SELECTED_PAKAGES_INIT =
  "GET_ALL_ORGANIZATION_SELECTED_PAKAGES_INIT";

export const GET_ALL_ORGANIZATION_SELECTED_PAKAGES_SUCCESS =
  "GET_ALL_ORGANIZATION_SELECTED_PAKAGES_SUCCESS";

export const GET_ALL_ORGANIZATION_SELECTED_PAKAGES_FAIL =
  "GET_ALL_ORGANIZATION_SELECTED_PAKAGES_FAIL";
export const CLEAR_CALENDAR_STATE = "CLEAR_CALENDAR_STATE";
export const CALENDAR_LOADER = "CALENDAR_LOADER";

export const TODO_CREATE_GROUP = "TODO_CREATE_GROUP";
export const TODO_CREATE_COMMITTEE = "TODO_CREATE_COMMITTEE";
export const TODO_CREATE_ADVANCED_MEETING = "TODO_CREATE_ADVANCED_MEETING";

export const GET_ALL_USER_TYPES_PAKAGES_INIT =
  "GET_ALL_USER_TYPES_PAKAGES_INIT";
export const GET_ALL_USER_TYPES_PAKAGES_SUCCESS =
  "GET_ALL_USER_TYPES_PAKAGES_SUCCESS";
export const GET_ALL_USER_TYPES_PAKAGES_FAIL =
  "GET_ALL_USER_TYPES_PAKAGES_FAIL";

//For Add user graph Api action type
export const USERADMIN_LIST_OF_STATS_GRAPH_INIT =
  "USERADMIN_LIST_OF_STATS_GRAPH_INIT";
export const USERADMIN_LIST_OF_STATS_GRAPH_SUCCESS =
  "USERADMIN_LIST_OF_STATS_GRAPH_SUCCESS";
export const USERADMIN_LIST_OF_STATS_GRAPH_FAIL =
  "USERADMIN_LIST_OF_STATS_GRAPH_FAIL";

export const POLL_CREATE_GROUP = "POLL_CREATE_GROUP";
export const POLL_CREATE_COMMITTEE = "POLL_CREATE_COMMITTEE";
export const POLL_CREATE_ADVANCED_MEETING = "POLL_CREATE_ADVANCED_MEETING";

export const GOOGLE_CREATE_EVENT = "GOOGLE_CREATE_EVENT";
export const GOOGLE_UPDATE_EVENT = "GOOGLE_UPDATE_EVENT";
export const GOOGLE_DELETE_EVENT = "GOOGLE_DELETE_EVENT";

export const MICROSOFT_CREATE_EVENT = "MICROSOFT_CREATE_EVENT";
export const MICROSOFT_UPDATE_EVENT = "MICROSOFT_UPDATE_EVENT";
export const MICROSOFT_DELETE_EVENT = "MICROSOFT_DELETE_EVENT";

export const DELETE_SHARED_FILE_INIT = "DELETE_SHARED_FILE_INIT";
export const DELETE_SHARED_FILE_SUCCESS = "DELETE_SHARED_FILE_SUCCESS";
export const DELETE_SHARED_FILE_FAIL = "DELETE_SHARED_FILE_FAIL";

export const RESEND_FORGOT_PASSWORD_CODE_INIT =
  "RESEND_FORGOT_PASSWORD_CODE_INIT";

export const RESEND_FORGOT_PASSWORD_CODE_SUCCESS =
  "RESEND_FORGOT_PASSWORD_CODE_SUCCESS";

export const RESEND_FORGOT_PASSWORD_CODE_FAIL =
  "RESEND_FORGOT_PASSWORD_CODE_FAIL";

export const MEETING_CREATE_GROUP = "MEETING_CREATE_GROUP";
export const MEETING_CREATE_COMMITTEE = "MEETING_CREATE_COMMITTEE";

export const DELETE_ORGANIZATION_USERS_INIT = "DELETE_ORGANIZATION_USERS_INIT";
export const DELETE_ORGANIZATION_USERS_SUCCESS =
  "DELETE_ORGANIZATION_USERS_SUCCESS";
export const DELETE_ORGANIZATION_USERS_FAIL = "DELETE_ORGANIZATION_USERS_FAIL";
export const DELETE_SHARED_FOLDER_INIT = "DELETE_SHARED_FOLDER_INIT";
export const DELETE_SHARED_FOLDER_SUCCESS = "DELETE_SHARED_FOLDER_SUCCESS";
export const DELETE_SHARED_FOLDER_FAIL = "DELETE_SHARED_FOLDER_FAIL";

export const PENDING_APPROVAL_PAGE = "PENDING_APPROVAL_PAGE";
export const REVIEW_MINUTES_PAGE = "REVIEW_MINUTES_PAGE";
// for payment Initiate in stepper three
export const PAYMENT_INITIATE_INIT = "PAYMENT_INITIATE_INIT";
export const PAYMENT_INITIATE_SUCCESS = "PAYMENT_INITIATE_SUCCESS";
export const PAYMENT_INITIATE_FAIL = "PAYMENT_INITIATE_FAIL";

// For cancel Subscription Reason Api
export const CANCEL_SUB_REASONS_INIT = "CANCEL_SUB_REASONS_INIT";
export const CANCEL_SUB_REASONS_SUCCESS = "CANCEL_SUB_REASONS_SUCCESS";
export const CANCEL_SUB_REASONS_FAIL = "CANCEL_SUB_REASONS_FAIL";

// For CancelOrganizationsSubscription Api submit
export const CANCEL_ORGANIZATION_SUB_INIT = "CANCEL_ORGANIZATION_SUB_INIT";
export const CANCEL_ORGANIZATION_SUB_SUCCESS =
  "CANCEL_ORGANIZATION_SUB_SUCCESS";
export const CANCEL_ORGANIZATION_SUB_FAIL = "CANCEL_ORGANIZATION_SUB_FAIL";

// global state for payment Process in which IFrame open
export const OPEN_PAYMENT_PROCESS_MODAL = "OPEN_PAYMENT_PROCESS_MODAL";

// Api for upgrade button on package details which is not ready yet but structure should be ready when Api will implemented ready to go

// export const PACKAGE_UPGRADE_DETAIL_INIT = "PACKAGE_UPGRADE_DETAIL_INIT";
// export const PACKAGE_UPGRADE_DETAIL_SUCCESS = "PACKAGE_UPGRADE_DETAIL_SUCCESS";
// export const PACKAGE_UPGRADE_DETAIL_FAIL = "PACKAGE_UPGRADE_DETAIL_FAIL";

export const GET_ALL_AGENDACONTRIBUTOR_ALLOWRSVP =
  "GET_ALL_AGENDACONTRIBUTOR_ALLOWRSVP";

export const GET_ALL_SAVED_PARTICIPATNS_ALLOWRSVP =
  "GET_ALL_SAVED_PARTICIPATNS_ALLOWRSVP";

export const REJECT_COMMENT_MODAL = "REJECT_COMMENT_MODAL";

export const EDIT_COMMENT_MODAL = "EDIT_COMMENT_MODAL";
export const CLEAR_MESSEGES_USER_MANAGEMENT = "CLEAR_MESSEGES_USER_MANAGEMENT";
export const REVOKE_TOKEN_MICROSOFT_INIT = "REVOKE_TOKEN_MICROSOFT_INIT";
export const REVOKE_TOKEN_MICROSOFT_SUCCESS = "REVOKE_TOKEN_MICROSOFT_SUCCESS";
export const REVOKE_TOKEN_MICROSOFT_FAIL = "REVOKE_TOKEN_MICROSOFT_FAIL";

export const REMOVE_GROUP_MEMBER = "REMOVE_GROUP_MEMBER";
export const REMOVE_COMMITTEE_MEMBER = "REMOVE_COMMITTEE_MEMBER";

export const REQUEST_ORGANIZATION_TRIAL_EXTEND_INIT =
  "REQUEST_ORGANIZATION_TRIAL_EXTEND_INIT";
export const REQUEST_ORGANIZATION_TRIAL_EXTEND_SUCCESS =
  "REQUEST_ORGANIZATION_TRIAL_EXTEND_SUCCESS";
export const REQUEST_ORGANIZATION_TRIAL_EXTEND_FAIL =
  "REQUEST_ORGANIZATION_TRIAL_EXTEND_FAIL";

export const TODOLIST_LOADER = "TODOLIST_LOADER";
export const ROUTING_ON_SIGNUP_USERMANAGEMENT =
  "ROUTING_ON_SIGNUP_USERMANAGEMENT";
export const DELETE_POLLS_MQTT = "DELETE_POLLS_MQTT";

export const PAYMENT_STATUS_INIT = "PAYMENT_STATUS_INIT";
export const PAYMENT_STATUS_SUCCESS = "PAYMENT_STATUS_SUCCESS";
export const PAYMENT_STATUS_FAILED = "PAYMENT_STATUS_FAILED";

export const VALIDATE_ENCRYPTEDSTRING_EMAIL_RELATED_INIT =
  "VALIDATE_ENCRYPTEDSTRING_EMAIL_RELATED_INIT";
export const VALIDATE_ENCRYPTEDSTRING_EMAIL_RELATED_SUCCESS =
  "VALIDATE_ENCRYPTEDSTRING_EMAIL_RELATED_SUCCESS";
export const VALIDATE_ENCRYPTEDSTRING_EMAIL_RELATED_FAIL =
  "VALIDATE_ENCRYPTEDSTRING_EMAIL_RELATED_FAIL";

export const EMAIL_ROUTE_ID = "EMAIL_ROUTE_ID";

export const VALIDATE_ENCRYPTEDSTRING_EMAIL_RELATED_POLLS_INIT =
  "VALIDATE_ENCRYPTEDSTRING_EMAIL_RELATED_POLLS_INIT";
export const VALIDATE_ENCRYPTEDSTRING_EMAIL_RELATED_POLLS_SUCCESS =
  "VALIDATE_ENCRYPTEDSTRING_EMAIL_RELATED_POLLS_SUCCESS";
export const VALIDATE_ENCRYPTEDSTRING_EMAIL_RELATED_POLLS_FAIL =
  "VALIDATE_ENCRYPTEDSTRING_EMAIL_RELATED_POLLS_FAIL";

export const GET_IMAGE_DATA = "GET_IMAGE_DATA";

export const DELETE_COMMENT_MEETING_MODAL = "DELETE_COMMENT_MEETING_MODAL";
export const BOARD_DECK_MODAL = "BOARD_DECK_MODAL";

export const BOARD_DECK_SHARE_MODAL = "BOARD_DECK_SHARE_MODAL";

export const BOARD_DECK_EMAIL_MODAL = "BOARD_DECK_EMAIL_MODAL";

export const CHANGE_PACKAGE_SELECTED_INIT = "CHANGE_PACKAGE_SELECTED_INIT";
export const CHANGE_PACKAGE_SELECTED_SUCCESS =
  "CHANGE_PACKAGE_SELECTED_SUCCESS";
export const CHANGE_PACKAGE_SELECTED_FAIL = "CHANGE_PACKAGE_SELECTED_FAIL";

export const CANCELFREETRAILANDUPDGRADEORGANIZATION_INIT =
  "CANCELFREETRAILANDUPDGRADEORGANIZATION_INIT";
export const CANCELFREETRAILANDUPDGRADEORGANIZATION_SUCCESS =
  "CANCELFREETRAILANDUPDGRADEORGANIZATION_SUCCESS";
export const CANCELFREETRAILANDUPDGRADEORGANIZATION_FAIL =
  "CANCELFREETRAILANDUPDGRADEORGANIZATION_FAIL";

export const INTERNET_DISCONNECT_MODAL = "INTERNET_DISCONNECT_MODAL";

export const GETALLSIGNATUREFLOWDOCUMENTSFORCREATOR_INIT =
  "GETALLSIGNATUREFLOWDOCUMENTSFORCREATOR_INIT";
export const GETALLSIGNATUREFLOWDOCUMENTSFORCREATOR_SUCCESS =
  "GETALLSIGNATUREFLOWDOCUMENTSFORCREATOR_SUCCESS";
export const GETALLSIGNATUREFLOWDOCUMENTSFORCREATOR_ISFAIL =
  "GETALLSIGNATUREFLOWDOCUMENTSFORCREATOR_ISFAIL";

export const GETINVOICEHTMLBYORGANIZATION_INIT =
  "GETINVOICEHTMLBYORGANIZATION_INIT";
export const GETINVOICEHTMLBYORGANIZATION_SUCCESS =
  "GETINVOICEHTMLBYORGANIZATION_SUCCESS";
export const GETINVOICEHTMLBYORGANIZATION_FAIL =
  "GETINVOICEHTMLBYORGANIZATION_FAIL";

export const EXPORT_USERLOGINHISTORY_INIT = "EXPORT_USERLOGINHISTORY_INIT";
export const EXPORT_USERLOGINHISTORY_SUCCESS =
  "EXPORT_USERLOGINHISTORY_SUCCESS";
export const EXPORT_USERLOGINHISTORY_FAIL = "EXPORT_USERLOGINHISTORY_FAIL";

export const PENDING_APPROVAL_GRAPH_DATA = "PENDING_APPROVAL_GRAPH_DATA";
export const GETALLPENDINGAPPROVALSTATS_INIT =
  "GETALLPENDINGAPPROVALSTATS_INIT";
export const GETALLPENDINGAPPROVALSTATS_SUCCESS =
  "GETALLPENDINGAPPROVALSTATS_SUCCESS";
export const GETALLPENDINGAPPROVALSTATS_FAIL =
  "GETALLPENDINGAPPROVALSTATS_FAIL";

export const GETALLPENDINGAPPROVALSIGNATURES_INIT =
  "GETALLPENDINGAPPROVALSIGNATURES_INIT";
export const GETALLPENDINGAPPROVALSIGNATURES_SUCCESS =
  "GETALLPENDINGAPPROVALSIGNATURES_SUCCESS";
export const GETALLPENDINGAPPROVALSIGNATURES_FAIL =
  "GETALLPENDINGAPPROVALSIGNATURES_FAIL";

export const GET_LISTOFDEFAULTREJECTIONCOMMENTS_INIT =
  "GET_LISTOFDEFAULTREJECTIONCOMMENTS_INIT";
export const GET_LISTOFDEFAULTREJECTIONCOMMENTS_SUCCESS =
  "GET_LISTOFDEFAULTREJECTIONCOMMENTS_SUCCESS";
export const GET_LISTOFDEFAULTREJECTIONCOMMENTS_FAIL =
  "GET_LISTOFDEFAULTREJECTIONCOMMENTS_FAIL";

export const GET_PENDINGAPPROVALSCOUNT_INIT = "GET_PENDINGAPPROVALSCOUNT_INIT";
export const GET_PENDINGAPPROVALSCOUNT_SUCCESS =
  "GET_PENDINGAPPROVALSCOUNT_SUCCESS";
export const GET_PENDINGAPPROVALSCOUNT_FAIL = "GET_PENDINGAPPROVALSCOUNT_FAIL";

export const GET_MINUTEREVIEWSTATSFORORGANIZERBYMEETINGID_INIT =
  "GET_MINUTEREVIEWSTATSFORORGANIZERBYMEETINGID_INIT";
export const GET_MINUTEREVIEWSTATSFORORGANIZERBYMEETINGID_SUCCESS =
  "GET_MINUTEREVIEWSTATSFORORGANIZERBYMEETINGID_SUCCESS";
export const GET_MINUTEREVIEWSTATSFORORGANIZERBYMEETINGID_FAIL =
  "GET_MINUTEREVIEWSTATSFORORGANIZERBYMEETINGID_FAIL";

export const GET_ALLORGANIZATIONUSERSFORREVIEW_INIT =
  "GET_ALLORGANIZATIONUSERSFORREVIEW_INIT";
export const GET_ALLORGANIZATIONUSERSFORREVIEW_SUCCESS =
  "GET_ALLORGANIZATIONUSERSFORREVIEW_SUCCESS";
export const GET_ALLORGANIZATIONUSERSFORREVIEW_FAIL =
  "GET_ALLORGANIZATIONUSERSFORREVIEW_FAIL";

export const GET_MINUTESFORREVIEWERBYMEETINGID_INIT =
  "GET_MINUTESFORREVIEWERBYMEETINGID_INIT";
export const GET_MINUTESFORREVIEWERBYMEETINGID_SUCCESS =
  "GET_MINUTESFORREVIEWERBYMEETINGID_SUCCESS";
export const GET_MINUTESFORREVIEWERBYMEETINGID_FAIL =
  "GET_MINUTESFORREVIEWERBYMEETINGID_FAIL";

export const GET_MINUTEREVIEWPENDINGAPPROVALSSTATSBYREVIEWERID_INIT =
  "GET_MINUTEREVIEWPENDINGAPPROVALSSTATSBYREVIEWERID_INIT";
export const GET_MINUTEREVIEWPENDINGAPPROVALSSTATSBYREVIEWERID_SUCCESS =
  "GET_MINUTEREVIEWPENDINGAPPROVALSSTATSBYREVIEWERID_SUCCESS";
export const GET_MINUTEREVIEWPENDINGAPPROVALSSTATSBYREVIEWERID_FAIL =
  "GET_MINUTEREVIEWPENDINGAPPROVALSSTATSBYREVIEWERID_FAIL";

export const GET_MINUTEREVIEWPENDINGAPPROVALSBYREVIEWERID_INIT =
  "GET_MINUTEREVIEWPENDINGAPPROVALSBYREVIEWERID_INIT";
export const GET_MINUTEREVIEWPENDINGAPPROVALSBYREVIEWERID_SUCCESS =
  "GET_MINUTEREVIEWPENDINGAPPROVALSBYREVIEWERID_SUCCESS";
export const GET_MINUTEREVIEWPENDINGAPPROVALSBYREVIEWERID_FAIL =
  "GET_MINUTEREVIEWPENDINGAPPROVALSBYREVIEWERID_FAIL";

export const CURRENT_MEETING_MINUTE_REVIEW = "CURRENT_MEETING_MINUTE_REVIEW";

export const SAVE_MINUTESREVIEWFLOW_INIT = "SAVE_MINUTESREVIEWFLOW_INIT";
export const SAVE_MINUTESREVIEWFLOW_SUCCESS = "SAVE_MINUTESREVIEWFLOW_SUCCESS";
export const SAVE_MINUTESREVIEWFLOW_FAIL = "SAVE_MINUTESREVIEWFLOW_FAIL";
export const DELETE_MINUTE_DATA = "DELETE_MINUTE_DATA";

export const DELETE_COMMENT_AGENDA_MODAL = "DELETE_COMMENT_AGENDA_MODAL";
export const DELETE_COMMENT_GENERAL_MODAL = "DELETE_COMMENT_GENERAL_MODAL";
export const DELETE_COMMENT_MODAL = "DELETE_COMMENT_MODAL";
export const EDIT_MINUTE_DATA = "EDIT_MINUTE_DATA";
export const UPDATE_MINUTE_FLAG = "UPDATE_MINUTE_FLAG";

export const GETMINUTEVERSIONHISTORYWITHCOMMENTS_INIT =
  "GETMINUTEVERSIONHISTORYWITHCOMMENTS_INIT";
export const GETMINUTEVERSIONHISTORYWITHCOMMENTS_SUCCESS =
  "GETMINUTEVERSIONHISTORYWITHCOMMENTS_SUCCESS";
export const GETMINUTEVERSIONHISTORYWITHCOMMENTS_FAIL =
  "GETMINUTEVERSIONHISTORYWITHCOMMENTS_FAIL";

export const GETMINUTEREVIEWDETAILSFORORGANIZATIONBYMINUTEID_INIT =
  "GETMINUTEREVIEWDETAILSFORORGANIZATIONBYMINUTEID_INIT";
export const GETMINUTEREVIEWDETAILSFORORGANIZATIONBYMINUTEID_SUCCESS =
  "GETMINUTEREVIEWDETAILSFORORGANIZATIONBYMINUTEID_SUCCESS";
export const GETMINUTEREVIEWDETAILSFORORGANIZATIONBYMINUTEID_FAIL =
  "GETMINUTEREVIEWDETAILSFORORGANIZATIONBYMINUTEID_FAIL";

export const GET_MINUTEREVIEWFLOWBYMEETINGID_INIT =
  "GET_MINUTEREVIEWFLOWBYMEETINGID_INIT";
export const GET_MINUTEREVIEWFLOWBYMEETINGID_SUCCESS =
  "GET_MINUTEREVIEWFLOWBYMEETINGID_SUCCESS";
export const GET_MINUTEREVIEWFLOWBYMEETINGID_FAIL =
  "GET_MINUTEREVIEWFLOWBYMEETINGID_FAIL";
export const CLEAR_MINUTES_MESSAGES = "CLEAR_MINUTES_MESSAGES";
export const DOWNGRADE_ORGANIZATION_SUBSCRIPTION_INIT =
  "DOWNGRADE_ORGANIZATION_SUBSCRIPTION_INIT";

export const DOWNGRADE_ORGANIZATION_SUBSCRIPTION_SUCCESS =
  "DOWNGRADE_ORGANIZATION_SUBSCRIPTION_SUCCESS";

export const DOWNGRADE_ORGANIZATION_SUBSCRIPTION_FAILED =
  "DOWNGRADE_ORGANIZATION_SUBSCRIPTION_FAILED";

export const GET_ORGANIZATION_WALLET_INIT = "GET_ORGANIZATION_WALLET_INIT";
export const GET_ORGANIZATION_WALLET_SUCCESS =
  "GET_ORGANIZATION_WALLET_SUCCESS";
export const GET_ORGANIZATION_WALLET_FAILED = "GET_ORGANIZATION_WALLET_FAILED";

export const BOARD_DECK_SEND_EMAIL_INIT = "BOARD_DECK_SEND_EMAIL_INIT";
export const BOARD_DECK_SEND_EMAIL_SUCCESS = "BOARD_DECK_SEND_EMAIL_SUCCESS";
export const BOARD_DECK_SEND_EMAIL_FAILED = "BOARD_DECK_SEND_EMAIL_FAILED";

export const DOWNLOAD__BOARDDECKPDF_INIT = "DOWNLOAD_AGENDA_PDF_INIT";
export const DOWNLOAD_BOARDDECKPDF_SUCCESS = "DOWNLOAD_AGENDA_PDF_SUCCESS";
export const DOWNLOAD_BOARDDECKPDF_FAILED = "DOWNLOAD_AGENDA_PDF_FAILED";
export const DOWNLOAD_BOARDDECKPDF_LOADER_FALSE =
  "DOWNLOAD_BOARDDECKPDF_LOADER_FALSE";

export const CLEAR_RESPONSEMESSAGE_WORKFLOWREDUCER =
  "CLEAR_RESPONSEMESSAGE_WORKFLOWREDUCER";

export const GETPENDINGAPPROVALSTATUSFORSIGNATUREFLOW_INIT =
  "GETPENDINGAPPROVALSTATUSFORSIGNATUREFLOW_INIT";
export const GETPENDINGAPPROVALSTATUSFORSIGNATUREFLOW_SUCCESS =
  "GETPENDINGAPPROVALSTATUSFORSIGNATUREFLOW_SUCCESS";
export const GETPENDINGAPPROVALSTATUSFORSIGNATUREFLOW_FAIL =
  "GETPENDINGAPPROVALSTATUSFORSIGNATUREFLOW_FAIL";

export const DECLINE_REASON_INIT = "DECLINE_REASON_INIT";
export const DECLINE_REASON_SUCCESS = "DECLINE_REASON_SUCCESS";
export const DECLINE_REASON_FAIL = "DECLINE_REASON_FAIL";

export const DELETE_SIGNATURE_DOCUMENT_INIT = "DELETE_SIGNATURE_DOCUMENT_INIT";
export const DELETE_SIGNATURE_DOCUMENT_SUCCESS =
  "DELETE_SIGNATURE_DOCUMENT_SUCCESS";
export const DELETE_SIGNATURE_DOCUMENT_FAIL = "DELETE_SIGNATURE_DOCUMENT_FAIL";

export const GETALLSIGNATORIESSTATUS_INIT = "GETALLSIGNATURESSTATUS_INIT";
export const GETALLSIGNATORIESSTATUS_SUCCESS = "GETALLSIGNATURESSTATUS_SUCCESS";
export const GETALLSIGNATORIESSTATUS_FAIL = "GETALLSIGNATURESSTATUS_FAIL";

export const ACCEPT_COMMENT_MODAL = "ACCEPT_COMMENT_MODAL";

export const ACCEPT_REJECT_MINUTESREVIEW_INIT =
  "ACCEPT_REJECT_MINUTESREVIEW_INIT";
export const ACCEPT_REJECT_MINUTESREVIEW_SUCCESS =
  "ACCEPT_REJECT_MINUTESREVIEW_SUCCESS";
export const ACCEPT_REJECT_MINUTESREVIEW_FAIL =
  "ACCEPT_REJECT_MINUTESREVIEW_FAIL";

export const REJECT_MINUTE = "REJECT_MINUTE";
export const PUBLISHEDMEETINGMINUTES_INIT = "PUBLISHEDMEETINGMINUTES_INIT";
export const PUBLISHEDMEETINGMINUTES_SUCCESS =
  "PUBLISHEDMEETINGMINUTES_SUCCESS";
export const PUBLISHEDMEETINGMINUTES_FAIL = "PUBLISHEDMEETINGMINUTES_FAIL";

export const GETPUBLISHEDMEETINGMINUTES_INIT =
  "GETPUBLISHEDMEETINGMINUTES_INIT";
export const GETPUBLISHEDMEETINGMINUTES_SUCCESS =
  "GETPUBLISHEDMEETINGMINUTES_SUCCESS";
export const GETPUBLISHEDMEETINGMINUTES_FAIL =
  "GETPUBLISHEDMEETINGMINUTES_FAIL";

export const DOWNLOADINVOICE_INIT = "DOWNLOADINVOICE_INIT";
export const DOWNLOADINVOICE_SUCCESS = "DOWNLOADINVOICE_SUCCESS";
export const DOWNLOADINVOICE_FAIL = "DOWNLOADINVOICE_FAIL";
export const UPDATEACTORBUNDLESTATUS_INIT = "UPDATEACTORBUNDLESTATUS_INIT";
export const UPDATEACTORBUNDLESTATUS_SUCCESS =
  "UPDATEACTORBUNDLESTATUS_SUCCESS";
export const UPDATEACTORBUNDLESTATUS_FAIL = "UPDATEACTORBUNDLESTATUS_FAIL";

export const CURRENT_USER_PICTURE = "CURRENT_USER_PICTURE";

export const PUBLISH_MEETINGMINUTES_INIT = "PUBLISH_MEETINGMINUTES_INIT";
export const PUBLISH_MEETINGMINUTES_SUCCESS = "PUBLISH_MEETINGMINUTES_SUCCESS";
export const PUBLISH_MEETINGMINUTES_FAIL = "PUBLISH_MEETINGMINUTES_FAIL";

export const GET_DATAFORRESENDMINUTEREVIEW_INIT =
  "GET_DATAFORRESENDMINUTEREVIEW_INIT";
export const GET_DATAFORRESENDMINUTEREVIEW_SUCCESS =
  "GET_DATAFORRESENDMINUTEREVIEW_SUCCESS";
export const GET_DATAFORRESENDMINUTEREVIEW_FAIL =
  "GET_DATAFORRESENDMINUTEREVIEW_FAIL";

export const VALIDATE_VIDEO_URL_INIT = "VALIDATE_VIDEO_URL_INIT";
export const VALIDATE_VIDEO_URL_SUCCESS = "VALIDATE_VIDEO_URL_SUCCESS";
export const VALIDATE_VIDEO_URL_FAILED = "VALIDATE_VIDEO_URL_FAILED";

export const RESEND_UPDATEDMINUTEFORREVIEW_INIT =
  "RESEND_UPDATEDMINUTEFORREVIEW_INIT";
export const RESEND_UPDATEDMINUTEFORREVIEW_SUCCESS =
  "RESEND_UPDATEDMINUTEFORREVIEW_SUCCESS";
export const RESEND_UPDATEDMINUTEFORREVIEW_FAIL =
  "RESEND_UPDATEDMINUTEFORREVIEW_FAIL";

export const GETMINUTESANDSIGNATUREAPPROVALTHISWEEK_INIT =
  "GETMINUTESANDSIGNATUREAPPROVALTHISWEEK_INIT";
export const GETMINUTESANDSIGNATUREAPPROVALTHISWEEK_SUCCESS =
  "GETMINUTESANDSIGNATUREAPPROVALTHISWEEK_SUCCESS";
export const GETMINUTESANDSIGNATUREAPPROVALTHISWEEK_FAIL =
  "GETMINUTESANDSIGNATUREAPPROVALTHISWEEK_FAIL";

export const DATAROOM_FILE_SHARED_MQTT = "DATAROOM_FILE_SHARED_MQTT";
export const DATAROOM_FOLDER_SHARED_MQTT = "DATAROOM_FOLDER_SHARED_MQTT";
export const DATAROOM_FOLDER_REMOVE_MQTT = "DATAROOM_FOLDER_REMOVE_MQTT";
export const DATAROOM_FILE_REMOVE_MQTT = "DATAROOM_FILE_REMOVE_MQTT";

export const GETDASHBOARDMEETINGDATA_INIT = "GETDASHBOARDMEETINGDATA_INIT";
export const GETDASHBOARDMEETINGDATA_SUCCESS =
  "GETDASHBOARDMEETINGDATA_SUCCESS";
export const GETDASHBOARDMEETINGDATA_FAIL = "GETDASHBOARDMEETINGDATA_FAIL";

export const GETDASHBOARDTASKDATA_INIT = "GETDASHBOARDTASKDATA_INIT";
export const GETDASHBOARDTASKDATA_SUCCESS = "GETDASHBOARDTASKDATA_SUCCESS";
export const GETDASHBOARDTASKDATA_FAIL = "GETDASHBOARDTASKDATA_FAIL";

export const GETDASHBOARDPENDINGAPPROVALDATA_INIT =
  "GETDASHBOARDPENDINGAPPROVALDATA_INIT";
export const GETDASHBOARDPENDINGAPPROVALDATA_SUCCESS =
  "GETDASHBOARDPENDINGAPPROVALDATA_SUCCESS";
export const GETDASHBOARDPENDINGAPPROVALDATA_FAIL =
  "GETDASHBOARDPENDINGAPPROVALDATA_FAIL";

export const VALIDATEENCRYPTEDSTRINGFOROTPEMAILLINK_INIT =
  "VALIDATEENCRYPTEDSTRINGFOROTPEMAILLINK_INIT";
export const VALIDATEENCRYPTEDSTRINGFOROTPEMAILLINK_SUCCESS =
  "VALIDATEENCRYPTEDSTRINGFOROTPEMAILLINK_SUCCESS";
export const VALIDATEENCRYPTEDSTRINGFOROTPEMAILLINK_FAIL =
  "VALIDATEENCRYPTEDSTRINGFOROTPEMAILLINK_FAIL";

export const GET_ADVANCEMEETINGAGENDAFORVIEW_INIT =
  "GET_ADVANCEMEETINGAGENDAFORVIEW_INIT";
export const GET_ADVANCEMEETINGAGENDAFORVIEW_SUCCESS =
  "GET_ADVANCEMEETINGAGENDAFORVIEW_SUCCESS";
export const GET_ADVANCEMEETINGAGENDAFORVIEW_FAIL =
  "GET_ADVANCEMEETINGAGENDAFORVIEW_FAIL";

export const GET_STATSFORPUBLISHINGMINUTESBYWORKFLOWID_INIT =
  "GET_STATSFORPUBLISHINGMINUTESBYWORKFLOWID_INIT";
export const GET_STATSFORPUBLISHINGMINUTESBYWORKFLOWID_SUCCESS =
  "GET_STATSFORPUBLISHINGMINUTESBYWORKFLOWID_SUCCESS";
export const GET_STATSFORPUBLISHINGMINUTESBYWORKFLOWID_FAIL =
  "GET_STATSFORPUBLISHINGMINUTESBYWORKFLOWID_FAIL";

export const GET_MINUTES_PUBLISHED_STATUS_INIT =
  "GET_MINUTES_PUBLISHED_STATUS_INIT";
export const GET_MINUTES_PUBLISHED_STATUS_SUCCESS =
  "GET_MINUTES_PUBLISHED_STATUS_SUCCESS";
export const GET_MINUTES_PUBLISHED_STATUS_FAIL =
  "GET_MINUTES_PUBLISHED_STATUS_FAIL";

export const VALIDATEENCRYPTEDSTRINGPARTICIPANTPROPOSED_INIT =
  "VALIDATEENCRYPTEDSTRINGPARTICIPANTPROPOSED_INIT";
export const VALIDATEENCRYPTEDSTRINGPARTICIPANTPROPOSED_SUCCESS =
  "VALIDATEENCRYPTEDSTRINGPARTICIPANTPROPOSED_SUCCESS";
export const VALIDATEENCRYPTEDSTRINGPARTICIPANTPROPOSED_FAIL =
  "VALIDATEENCRYPTEDSTRINGPARTICIPANTPROPOSED_FAIL";

export const VALIDATEENCRYPTEDSTRINGRESOLUTION_INIT =
  "VALIDATEENCRYPTEDSTRINGRESOLUTION_INIT";
export const VALIDATEENCRYPTEDSTRINGRESOLUTION_SUCCESS =
  "VALIDATEENCRYPTEDSTRINGRESOLUTION_SUCCESS";
export const VALIDATEENCRYPTEDSTRINGRESOLUTION_FAIL =
  "VALIDATEENCRYPTEDSTRINGRESOLUTION_FAIL";

export const RESOLUTION_EMAIL_ROUTE = "RESOLUTION_EMAIL_ROUTE";

export const GETALLMEETINGUSERSRSVPDETAILS_INIT =
  "GETALLMEETINGUSERSRSVPDETAILS_INIT";
export const GETALLMEETINGUSERSRSVPDETAILS_SUCCESS =
  "GETALLMEETINGUSERSRSVPDETAILS_SUCCESS";
export const GETALLMEETINGUSERSRSVPDETAILS_FAIL =
  "GETALLMEETINGUSERSRSVPDETAILS_FAIL";

export const MOBILE_POP_UP_MODAL = "MOBILE_POP_UP_MODAL";

export const GETGROUPSANDCOMMITTEESFORRESOLUTION_INIT =
  "GETGROUPSANDCOMMITTEESFORRESOLUTION_INIT";
export const GETGROUPSANDCOMMITTEESFORRESOLUTION_SUCCESS =
  "GETGROUPSANDCOMMITTEESFORRESOLUTION_SUCCESS";
export const GETGROUPSANDCOMMITTEESFORRESOLUTION_FAIL =
  "GETGROUPSANDCOMMITTEESFORRESOLUTION_FAIL";

export const DOWNLOAD_CALL_RECORDING_INIT = "DOWNLOAD_CALL_RECORDING_INIT";
export const DOWNLOAD_CALL_RECORDING_SUCCESS =
  "DOWNLOAD_CALL_RECORDING_SUCCESS";

export const LEAVE_MEETING_VIDEO_INIT = "LEAVE_MEETING_VIDEO_INIT";
export const LEAVE_MEETING_VIDEO_SUCCESS = "LEAVE_MEETING_VIDEO_SUCCESS";
export const LEAVE_MEETING_VIDEO_FAIL = "LEAVE_MEETING_VIDEO_FAIL";

export const MEETING_REMINDER_NOTIFICATION = "MEETING_REMINDER_NOTIFICATION";

export const NEW_MEETING_LOADER_REDUCER = "NEW_MEETING_LOADER_REDUCER";

export const PROPOSED_MEETING_DATES_DATA = "PROPOSED_MEETING_DATES_DATA";
export const PARTICIPANT_PROPOSED_MEETING = "PARTICIPANT_PROPOSED_MEETING";
export const GET_ALL_MEETING_DETAILS_DATA = "GET_ALL_MEETING_DETAILS_DATA";
